import { DataGrid } from '@mui/x-data-grid';
import { addDoc, arrayUnion, collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import Sidebar from '../Sidebar'

const Addgallery = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [gallery, setGallery] = React.useState([]);

    const navigate = useNavigate()

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "gallery"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setGallery(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "gallery"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 500, align: 'left', editable: false },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            },
        },
    ];


    return (
        <>
            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Gallery</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>

                {
                    addMode === true ?
                        <Addind changeAddMode={changeAddMode} />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={gallery}
                                    disableSelectionOnClick={true}
                                />
                            </div>
                        </>
                }
            </Sidebar>
        </>
    )
}

const Addind = (props) => {
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [date, setDate] = React.useState('');
    const [image, setImage] = React.useState('');
    const [additionalImgs, setAdditionalImgs] = React.useState([]);
    const [imageUrl, setImageUrl] = React.useState('');
    const [additionalImgUrl, setAdditionalImgUrl] = React.useState([]);
    const [uploaded, setUploaded] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [mulImgUploaded, setMulImgUploaded] = React.useState(false);


    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const addMultipleImagesToFirebase = () => {

    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "gallery"), {
            title: title,
            date: new Date().toLocaleDateString(),
            timestamp: new Date().getTime(),
            images: additionalImgUrl,
        })
            .then((docRef) => {
                setUploaded(true);
                setUploading(false);
                setTitle('');
                setDescription('');
                setDate('');
                setImageUrl('');
                setImage('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        addMultipleImagesToFirebase();
    }

    useEffect(() => {
        if (mulImgUploaded === true) {
            uploadDoc();
        }
    }, [mulImgUploaded])


    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
                    <input required={true} value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />
                    <label htmlFor="file-input" className="block text-gray-700 text-sm font-bold">
                        Gallery images
                    </label>
                    <input type="file" accept="image/*" multiple onChange={(e) => setAdditionalImgs(e.target.files)} />
                    <button type="submit" className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>
                        {
                            uploading === true ?
                                'Uploading...'
                                :
                                'Upload'
                        }
                    </button>

                </form>
            </div>
            <Globalsnack type="success" text="Gallery published successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}

export default Addgallery