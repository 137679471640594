import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import { Tooltip } from '@mui/material'

const Sidebar = ({ children }) => {
    return (
        <>
            <div className='flex'>
                <aside className="p-5 h-screen sticky top-0 bg-opacity-20 inset-y-0 z-10 flex flex-col flex-shrink-0 w-64  overflow-x-hidden overflow-y-hidden transition-all transform bg-white border-r shadow-lg lg:z-10 lg:shadow-none">
                    <div className=''>
                        <h1 className='font-sans text-xl font-bold'>
                            <span className='text-primary'>
                                AABADDHA
                            </span>
                            {' '}
                            <span className='text-secondary'>
                                SACCOS
                            </span>
                        </h1>
                        <p className='text-xs font-sans text-black text-opacity-70'>
                            Powered by LinkBinary Admin Engine Lite Version
                        </p>
                    </div>
                    <div className='mt-10'>
                        <ul className='flex flex-col gap-7 font-sans'>
                            {/* <li className=''>
                                <NavLink
                                    to='/admin/'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Dashboard
                                </NavLink>
                            </li> */}
                            <li className=''>
                                <NavLink
                                    to='/admin/notices'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Notices
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink
                                    to='/admin/vacancy'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Vacancies
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink
                                    to='/admin/news'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    News
                                </NavLink>
                            </li>
                            {/* <li className=''>
                                <NavLink
                                    to='/admin/slider-images'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Slider Images
                                </NavLink>
                            </li> */}
                            <li className=''>
                                <NavLink
                                    to='/admin/services'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Site Control
                                </NavLink>
                            </li>

                            <li className=''>
                                <NavLink
                                    to='/admin/knowledge'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Coop Knowledge
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink
                                    to='/admin/gallery'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    Gallery
                                </NavLink>
                            </li>
                            <li className=''>
                                <NavLink
                                    to='/admin/kom'
                                    className={({ isActive }) => (isActive ? 'uppercase p-3 mr-5 rounded-lg bg-black bg-opacity-5' : 'uppercase p-3 mr-5 rounded-lg hover:bg-black hover:bg-opacity-5')}
                                >
                                    KOM Submissions
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </aside>
                <div className='flex flex-col w-full'>
                    <Navbar />
                    <main className='p-5 flex-1'>
                        {children}
                    </main>
                </div>
            </div>
        </>
    )
}

const Navbar = () => {
    const { logout } = useAuth();
    const handleLogout = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            logout();
        }
    }
    return (
        <>
            <nav className='flex items-center justify-end top-0 sticky bg-white backdrop-blur-sm z-50 border-b w-full h-16 p-5'>
                <div className='flex items-center'>
                    <Link to='/' className='text-black text-opacity-75'>
                        <button
                            className="ml-auto bg-primary text-white p-2 text-sm rounded font-semibold hover:bg-primary-d"
                        >
                            VIEW SITE
                        </button>
                    </Link>
                    <Tooltip title='Logout'>
                        <button onClick={handleLogout}>
                            <i className="text-2xl ml-5 text-red-700 bi bi-box-arrow-right"></i>
                        </button>
                    </Tooltip>
                </div>
            </nav>
        </>
    )
}

export default Sidebar