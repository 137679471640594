import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../../firebase';
import Pagecontainer from '../../../Pagecontainer';
import SavingImage from '../../../../assets/illustrations/savings.svg';
import useDocumentTitle from '../../../../functions/useDocumentTitle';
import Breadcrumb from '../../Breadcrumb';

const Kramik = () => {
    useDocumentTitle('Sadharan - Aabaddha SACCOS Ltd.');
    const [savings, setSavings] = useState([]);

    const { skid } = useParams();

    useEffect(() => {
        try {
            const q = query(doc(db, 'savings', skid));
            const unsubscribe = onSnapshot(q, (doc) => {
                setSavings(doc.data());
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, [skid]);

    return (
        <>
            <Breadcrumb root='Savings' current={savings.title} />
            <Pagecontainer>
                <div className='flex items-center flex-col my-10'>
                    <img src={SavingImage} alt='savings' className='w-1/6 my-5' />
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        {savings.title}
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-8' />

                    <p className='font-vesper text-black text-opacity-80 text-center'>
                        {savings.description}
                    </p>
                </div>


                <div className="overflow-x-auto relative border">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="border-b text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Product name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Type
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Collection Method
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Interest Rate
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                    {savings.title}
                                </th>
                                <td className="py-4 px-6">
                                    Deposit
                                </td>
                                <td className="py-4 px-6">
                                    Saving
                                </td>
                                <td className="py-4 px-6">
                                    {savings.collectionMethod !== '' ? savings.collectionMethod : 'N/A'}
                                </td>
                                <td className="py-4 px-6">
                                    {savings.interestrate !== '' ? savings.interestrate + '%' : 'N/A'}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>


            </Pagecontainer>
        </>
    )
}

export default Kramik