import { collection, onSnapshot, query } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { db } from '../../firebase'

const Welcomenote = () => {


    const [finData, setFinData] = React.useState({})

    const [year, setYear] = React.useState('')
    const [kulKosh, setKulKosh] = React.useState('')
    const [sharePunji, setSharePunji] = React.useState('')
    const [bachatNikshep, setBachatNikshep] = React.useState('')
    const [bahyaKarja, setBahyaKarja] = React.useState('')
    const [bank, setBank] = React.useState('')
    const [rin, setRin] = React.useState('')
    const [isthir, setIsthir] = React.useState('')
    const [sadasya, setSadasya] = React.useState('')



    useEffect(() => {
        try {
            const q = query(collection(db, "siteData"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setFinData(querySnapshot.docs.map(doc => doc.data().finData));
                setYear(querySnapshot.docs.map(doc => doc.data().year));
                setKulKosh(querySnapshot.docs.map(doc => doc.data().kulKosh));
                setSharePunji(querySnapshot.docs.map(doc => doc.data().sharePunji));
                setBachatNikshep(querySnapshot.docs.map(doc => doc.data().bachatNikshep));
                setBahyaKarja(querySnapshot.docs.map(doc => doc.data().bahyaKarja));
                setBank(querySnapshot.docs.map(doc => doc.data().bank));
                setRin(querySnapshot.docs.map(doc => doc.data().rin));
                setIsthir(querySnapshot.docs.map(doc => doc.data().isthir));
                setSadasya(querySnapshot.docs.map(doc => doc.data().sadasya));

            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    return (
        <>
            <section className="bg-white px-5 md:px-28 py-5">
                <div className='flex md:flex-row flex-col w-full justify-between gap-5'>
                    <div className='md:w-full'>
                        <div className='border p-5'>
                            <h3 className='text-primary font-sans text-lg'>
                                Welcome to <span className='font-bold'>Aabaddha</span> SACCOS!
                            </h3>
                            <div className='w-[30px] bg-primary h-[4px] my-2' />
                            <p className='text-gray-500 font-sans text-sm text-justify'>
                                Welcome to the Aabaddha SACCOS, an organization committed to improving the economic, social, and cultural well-being of our members through a range of services including savings, credit, financial security, and remittance. Founded in 2069 BS, our vision is to become a premier business cooperative that meets the financial needs of our community. This website has been designed to provide our members with timely and accurate information about our activities and services, using the latest technology. We hope you find it useful and informative.
                                <br /><br />
                                As a member-owned and democratically controlled organization, Aabaddha SACCOS values the contributions and participation of all our members. We are dedicated to providing our members with high-quality financial products and services that meet their diverse needs, while also working to promote the principles of cooperation and mutual support within our community. We believe that by working together, we can create a stronger and more sustainable future for all of our members. We encourage you to explore the various resources and opportunities available on this website, and to get involved in the life of the co-op. Thank you for choosing the Aabaddha SACCOS as your financial partner.
                            </p>
                        </div>
                    </div>
                    {/* <div className='flex-1 border md:w-[42%] p-5'>
                        <h3 className='text-primary font-sans text-lg'>
                            Statistics upto date <span className='font-bold'>{year}</span>
                        </h3>
                        <div className='w-[30px] bg-primary h-[4px] my-2' />
                        <div className='grid grid-cols-2 md:grid-cols-4'>
                            <ul className='flex flex-col gap-5'>
                                <li className='text-gray-500 font-sumana text-sm'>
                                    <span className='font-karma'>शेयर पुंजी</span>
                                    <br />
                                    <span className='text-gray-500 font-vesper font-bold text-lg'>
                                        {sharePunji}
                                    </span>
                                </li>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>कुल कोष</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {kulKosh}
                                    </span>
                                </li>
                            </ul>
                            <ul className='flex flex-col gap-5'>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>बचत/निक्षेप</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {bachatNikshep}
                                    </span>
                                </li>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>बाह्य कर्जा</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {bahyaKarja}
                                    </span>
                                </li>
                            </ul>
                            <ul className='flex flex-col gap-5'>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>बैंक</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {bank}
                                    </span>
                                </li>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>ऋण लगानी</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {rin}

                                    </span>
                                </li>
                            </ul>
                            <ul className='flex flex-col gap-5'>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'> स्थिर सम्पति</span>
                                    <br />
                                    <span className='text-gray-500 font-karma font-bold text-lg'>
                                        {isthir}
                                    </span>
                                </li>
                                <li className='text-gray-500 font-karma text-sm'>
                                    <span className='font-karma'>सदस्य संख्या</span>
                                    <br />
                                    <span className='text-primary font-karma font-bold text-lg'>
                                        {sadasya}
                                    </span>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default Welcomenote