import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import Breadcrumb from './components/Breadcrumb'
import Pagecontainer from './Pagecontainer'
import AboutImage from '../assets/logo-n.png'
import Presidentmsg from './components/Presidentmsg'
import Organizationalstruct from './components/Organizationalstruct'
import Objectives from './components/Objectives'
import CooperativeProfile from './components/CooperativeProfile'

const About = () => {
    useDocumentTitle('About - Aabaddha SACCOS Ltd.')

    return (
        <>
            <Breadcrumb root='Home' current='About' />
            <div className='flex flex-col items-center px-5 my-10 md:px-28'>
                <img src={AboutImage} alt='savings' className='w-[70%] md:w-1/4 my-5' />
                <h3 className='mt-10 text-xl font-medium text-center uppercase text-primary font-vesper md:text-left md:text-3xl'>
                    आबद्ध सहकारीमा तपाइलाइ हार्दिक स्वागत छ!
                </h3>
                <div className='w-[30px] bg-primary h-[4px] my-8' />
                <p className='text-base font-medium text-center text-black font-vesper text-opacity-70'>
                    ‘वित्तीय आवश्यकताको समाधान सहितको व्यवसायिक सहकारी’ बनाउने परिकल्पना लिएर २०६९ सालदेखि निरन्तर रुपमा बचत, ऋण, तथा विप्रेषण लगायतका सेवाहरुमार्फत सदस्यहरुको आर्थिक, सामाजिक र साँस्कृतिक स्तर उकास्न प्रयत्न गरिरहेको यस आबद्ध बचत तथा ऋण सहकारी संस्थाको वेवसाईटमा यहाँहरुलाई हार्दिक स्वागत गर्दछौं।
                    संस्थाको सेवा सुविधाका साथै आर्थिक तथा अन्य गतिविधिहरुको सूचना तथा जानकारीहरु सदस्यमाझ प्रविधिको प्रयोगमार्फत छिटो, सरल र सहजरुपमा उपलब्ध गराउने उद्धेश्यका साथ विकास गरिएको यस वेवसाईटमा यहाँहरुबाट रचनात्मक सल्लाह तथा सुझावहरु प्राप्त हुने अपेक्षा गर्दै संस्थागत विकासमा ऐक्यवद्धता प्रकट गरिदिनुहुन सम्पूर्ण सदस्य तथा शुभचिन्तकहरुमा हार्दिक अनुरोध गर्दछौं। धन्यवाद।
                </p>


            </div>

            <div className="astrodivider">
                <div className="astrodividermask" />
                <span>
                    <img src={require('../assets/logo.png')} alt='president' className='' />
                </span>
            </div>

            {/* <Objectives /> */}

            <CooperativeProfile />

            <Presidentmsg />

            <Organizationalstruct />


        </>
    )
}

export default About