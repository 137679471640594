import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import Marquee from 'react-fast-marquee';
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import './styles/slidetext.css'

const NoticesBar = () => {
    const [notices, setNotices] = useState([]);
    const [date, setDate] = useState([]);
    const [notId, setNotId] = useState([]);

    useEffect(() => {
        try {
            const q = query(collection(db, "notices"), orderBy("timestamp", "desc"), limit(5));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setNotices(querySnapshot.docs.map(doc => doc.data().title));
                setDate(querySnapshot.docs.map(doc => doc.data().date));
                setNotId(querySnapshot.docs.map(doc => doc.id));
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    return (
        <>
            <div className='bg-primary text-white px-5 md:px-28 py-[2px] overflow-hidden'>
                <div className='flex justify-start'>
                    <div className='bg-white text-primary px-2 font-sans'>
                        NOTICES
                    </div>
                    <Marquee gradient={false}>
                        {
                            notices.map((notice, index) => {
                                return (
                                    <div key={index} className='overflow-hidden'>
                                        <Link to={`/notices/${notId[index]}`} className='text-white font-vesper mr-10 hover:underline'>
                                            {notice}{' '}
                                        </Link>
                                    </div>
                                )
                            })
                        }
                    </Marquee>
                </div>
            </div>
        </>
    )
}

export default NoticesBar