import React from 'react'
import Breadcrumb from './components/Breadcrumb'
import Pagecontainer from './Pagecontainer'
import { useEffect } from 'react';
import { collection, onSnapshot, query } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LoanPage = () => {
    const [loan, setLoan] = React.useState([]);
    useEffect(() => {
        try {
            const q = query(collection(db, 'loan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setLoan(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);
    return (
        <>
            <Helmet>
                <title>Loan - Aabaddha SACCOS Ltd.</title>
                <meta name='description' content='Loan - Aabaddha SACCOS Ltd.' />
            </Helmet>
            <Breadcrumb root='Services' current='Loans' />
            <Pagecontainer>

                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        Loans
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>


                <div className='flex flex-col'>
                    <div className='flex flex-col'>
                        {/* <p className='font-vesper text-lg font-medium text-red-700 mt-2'>
                            साधारण / दैनिक बचत
                        </p> */}
                        <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-2'>

                            {
                                loan.
                                    map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-col border p-5'
                                                // set bg image
                                                style={{
                                                    backgroundImage: `url(${require(`../assets/stocks/loan.png`)})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat'
                                                }}
                                            >
                                                <Link
                                                    to={`/services/loan/${item.id}`}
                                                >
                                                    <span className='font-sans font-bold text-sm capitalize hover:underline'>
                                                        {item.title}
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    )
                            }

                        </div>
                    </div>
                </div>
            </Pagecontainer>
        </>
    )
}

export default LoanPage