import React from 'react'
import useDocumentTitle from '../../../functions/useDocumentTitle'
import Pagecontainer from '../../Pagecontainer'
import Breadcrumb from '../Breadcrumb'
import GoogleMapReact from 'google-map-react'

const AnyReactComponent = ({ text }) => (
    <div style={{
        color: 'white',
        background: 'grey',
        padding: '15px 10px',
        display: 'inline-flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '100%',
        transform: 'translate(-50%, -50%)'
    }}>
        {text}
    </div>
);

const Arjundhara = () => {
    useDocumentTitle('Arjundhara Branch - Aabaddha SACCOS Ltd.')
    const defaultProps = {
        center: {
            lat: 26.67618261821338,
            lng: 88.00805304228292
        },
        zoom: 11
    };
    return (
        <>
            <Breadcrumb root='Branches' current='Arjundhara' />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    {/* <img className='w-1/6 mb-14' src={ContactImage} alt='Contact' /> */}
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase mb-5'>
                        Arjundhara Branch
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                    <p className='font-vesper text-base font-medium text-black text-opacity-70 text-center my-5'>
                        आबद्ध बचत तथा ऋण सहकारी संस्था लि, अर्जुनधारा साखा जानकारी
                    </p>
                </div>

                <div className='border p-5'>
                    N/A
                </div>


                {/* <div style={{ height: '50vh', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: "" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                    >
                        <AnyReactComponent
                            lat={59.955413}
                            lng={30.337844}
                            text={'Kreyser Avrora'}
                        />
                    </GoogleMapReact>
                </div> */}
            </Pagecontainer>
        </>
    )
}

export default Arjundhara