import { DataGrid } from '@mui/x-data-grid'
import { collection, onSnapshot, orderBy, query } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { db } from '../../../firebase'
import useDocumentTitle from '../../../functions/useDocumentTitle'
import Pagecontainer from '../../Pagecontainer'
import Breadcrumb from '../Breadcrumb'

const Actsandpolicies = () => {
    useDocumentTitle('Acts & Policies - Aabaddha COOP')

    const [articles, setArticles] = useState([])

    useEffect(() => {
        try {
            const q = query(collection(db, "acts"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setArticles(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    const columns = [
        {
            field: 'title', headerName: 'Articles', align: 'left', editable: false, headerClassName: 'text-white font-sans text-base', flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.row.link} target='_none' className='text-primary-d underline font-sans'>
                        {params.value}
                    </a>
                )
            }
        },
    ]

    return (
        <>
            <Breadcrumb root='Knowledge Base' current='Acts & Policies' />
            <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                <h3 className='text-primary text-center font-sans text-2xl font-bold uppercase'>
                    Acts and Policies
                    <p className='font-sans text-base font-medium text-red-700 mt-2'>
                        {/* Data upto {year} */}
                    </p>
                </h3>
                <div className='w-[30px] bg-primary h-[4px] my-2' />
            </div>

            <Pagecontainer>
                <div className='h-[500px]'>
                    <DataGrid
                        columns={columns}
                        rows={articles}
                        disableSelectionOnClick={true}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack className='text-center' height="100%" alignItems="center" justifyContent="center">
                                    <p className='text-sm text-gray-600 mt-10'>No articles found.</p>
                                </Stack>
                            ),

                        }}
                        sx={{
                            boxShadow: 0,
                            border: 1,
                            borderColor: '#00000020',
                            '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                                bgcolor: '#008037',
                            },
                            '& .css-f3jnds-MuiDataGrid-columnHeaders': {
                                bgcolor: '#008037',
                            },
                            '& .css-i4bv87-MuiSvgIcon-root': {
                                color: '#008037',
                            },
                            '& .css-1nitb7c': {
                                bgcolor: '#008037',
                            },
                            '& .css-1xvznzl .MuiDataGrid-iconSeparator': {
                                display: 'none',
                            },
                        }}
                    />
                </div>

            </Pagecontainer>
        </>
    )
}

export default Actsandpolicies