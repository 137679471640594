import { Stack } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import useDocumentTitle from '../functions/useDocumentTitle'
import Pagecontainer from './Pagecontainer'

const Downloads = () => {
    useDocumentTitle('Downloads - Aabaddha SACCOS Ltd.');

    const [downloadables, setDownloadables] = useState([
        {
            id: 4,
            title: 'बि. स. २०८१ भित्तेपात्रो',
            file: require('../assets/docs/calendar-081.pdf')
        },
        {
            id: 3,
            title: 'बार्षिक प्रगति प्रतिवेदन २०७९/२०८०',
            file: require('../assets/docs/aabaddha-yearly-progress-report-079-080.pdf')
        },
        {
            id: 2,
            title: 'बि. स. २०८० भित्तेपात्रो',
            file: require('../assets/docs/calendar-080.pdf')
        },
        {
            id: 1,
            title: 'बार्षिक प्रगति प्रतिवेदन २०७८/२०७९',
            file: require('../assets/docs/aabaddha-yearly-progress-report-078-079.pdf')
        },
    ]);

    const columns = [
        {
            field: 'title',
            headerName: 'Items',
            align: 'left',
            editable: false,
            headerClassName: 'text-white text-base',
            flex: 1,
            renderCell: (params) => (
                <a href={params.row.file} target='_blank' className='underline text-primary-d'>
                    {params.value}
                </a>
            ),
        },
        {
            field: 'actions',
            headerName: 'Actions',
            type: 'string',
            width: 200,
            align: 'left',
            headerAlign: 'left',
            editable: false,
            sortable: false,
            headerClassName: 'text-white',
            flex: 1,
            renderCell: (params) => (
                <div className='flex gap-1'>
                    <i className='cursor-pointer bi bi-download text-primary-d' />
                    <a href={params.row.file} className='underline text-primary-d' download={params.row.title}>
                        Download
                    </a>
                </div>
            ),
        },
    ];

    const handleSortModelChange = (newModel) => {
        const sortedDownloadables = [...downloadables].sort((a, b) => {
            const field = newModel[0]?.field;
            const sortOrder = newModel[0]?.sort;

            if (field === 'id') {
                if (sortOrder === 'asc') {
                    return a[field] - b[field];
                } else if (sortOrder === 'desc') {
                    return b[field] - a[field];
                }
            }

            return 0;
        });

        setDownloadables(sortedDownloadables);
    };

    return (
        <>
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <h3 className='font-sans text-3xl font-bold uppercase text-primary'>
                        Downloads
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>


                <div className='h-[500px]'>
                    <DataGrid
                        columns={columns}
                        rows={downloadables}
                        disableSelectionOnClick={true}
                        sortModel={[
                            {
                                field: 'id',
                                sort: 'desc',
                            },
                        ]}
                        onSortModelChange={handleSortModelChange}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack className='text-center' height="100%" alignItems="center" justifyContent="center">
                                    <p className='mt-10 text-sm text-gray-600'>No files found.</p>
                                </Stack>
                            ),

                        }}
                        sx={{
                            boxShadow: 0,
                            border: 1,
                            borderColor: '#00000020',
                            '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                                bgcolor: '#008037',
                            },
                            '& .css-f3jnds-MuiDataGrid-columnHeaders': {
                                bgcolor: '#008037',
                            },
                            '& .css-i4bv87-MuiSvgIcon-root': {
                                color: '#008037',
                            },
                            '& .css-1nitb7c': {
                                bgcolor: '#008037',
                            },
                            '& .css-1xvznzl .MuiDataGrid-iconSeparator': {
                                display: 'none',
                            },
                        }}
                    />
                </div>


            </Pagecontainer>

        </>
    )
}

export default Downloads