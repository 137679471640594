import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

const CoopKnowledge = () => {
    return (
        <>


            <Sidebar>
                <ul className='flex flex-col gap-5'>
                    <li className='rounded border p-5'>
                        <Link to='/admin/knowledge/acts-policies' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Acts & Policies</span>
                        </Link>
                    </li>
                    <li className='rounded border p-5'>
                        <Link to='/admin/knowledge/articles' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Articles & Knowledge</span>
                        </Link>
                    </li>
                </ul>
            </Sidebar>


        </>
    )
}

export default CoopKnowledge