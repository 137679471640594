import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import Hero from './components/Hero'
import Managermsg from './components/Managermsg'
import News from './components/News'
import NoticesBar from './components/NoticesBar'
import Primaryservices from './components/Primaryservices'
import SomeNumbers from './components/SomeNumbers'
import Welcomenote from './components/Welcomenote'
import Pagecontainer from './Pagecontainer'

const Home = () => {
    useDocumentTitle('Home - Aabaddha SACCOS Ltd.')
    return (
        <>
            <NoticesBar />
            <Hero />
            {/* <Pagecontainer> */}
            <Welcomenote />
            <SomeNumbers />
            <Managermsg />
            <Primaryservices />
            <News title={true} />
            {/* </Pagecontainer> */}
        </>
    )
}

export default Home