import React from 'react'
import Pagecontainer from '../../../Pagecontainer'
import Breadcrumb from '../../Breadcrumb'
import SavingImage from '../../../../assets/illustrations/savings.svg';


const Aabadhik = () => {
    const saving = [
        {
            title: 'Periodic 3,6,9,18 months',
            type: 'Periodic',
            category: 'Saving',
            collectionMethod: '',
            interestRate: '',
        },
        {
            title: 'Periodic 1,2,3 years',
            type: 'Periodic',
            category: 'Saving',
            collectionMethod: '',
            interestRate: '',
        },
    ]
    return (
        <>
            <Breadcrumb root='Savings' current='Aabadhik' />
            <Pagecontainer>
                <div className='flex items-center flex-col my-10'>
                    <img src={SavingImage} alt='savings' className='w-1/6 my-5' />
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        Aabadhik Bachat
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-8' />

                    <p className='font-vesper text-black text-opacity-80 text-center'>
                        {/* {savings.description} */}
                    </p>
                </div>


                <div className="overflow-x-auto relative border">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="border-b text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Product name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Type
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Collection Method
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Interest Rate
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                saving.map((savings, index) => (
                                    <tr className="bg-white">
                                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                            {savings.title}
                                        </th>
                                        <td className="py-4 px-6">
                                            {savings.type}
                                        </td>
                                        <td className="py-4 px-6">
                                            {savings.category}
                                        </td>
                                        <td className="py-4 px-6">
                                            {savings.collectionMethod !== '' ? savings.collectionMethod : 'N/A'}
                                        </td>
                                        <td className="py-4 px-6">
                                            {savings.interestRate !== '' ? savings.interestRate + '%' : 'N/A'}
                                        </td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>


            </Pagecontainer>
        </>
    )
}

export default Aabadhik