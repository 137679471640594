import React, { useState } from 'react'
import Pagecontainer from '../Pagecontainer'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { IconButton } from '@mui/material';
import useDocumentTitle from '../../functions/useDocumentTitle';
import { Divider } from './Footer';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import { Gallery, Item } from "react-photoswipe-gallery";

import Org from '../../assets/org.png'

import "photoswipe/dist/photoswipe.css";

const Organizationalstruct = () => {
    useDocumentTitle('Organizational Structure - Aabaddha SACCOS Ltd.')

    return (
        <>
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>

                    <h3 className='font-sans text-2xl font-bold text-center uppercase text-primary'>
                        Organizational Structure
                        <p className='mt-2 text-base font-medium text-red-700 font-vesper'>
                            संगठनात्मक संरचना
                        </p>
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>

                {/* <TabularDataWithImage /> */}

                {/* <img src={require('../../assets/org.png')} alt='organizational-structure' className='w-full border' /> */}

                <Gallery>
                    <Item
                        original={Org}
                        thumbnail={Org}
                        width={Org.width}
                        height={Org.height}
                    >
                        {({ ref, open }) => (
                            <img
                                ref={ref}
                                onClick={open}
                                src={Org}
                                className='ring-4 ring-primary'
                            />
                        )}
                    </Item>
                </Gallery>

                <h3 className='my-5 font-sans text-xl text-center text-primary'>
                    Data in-depth
                </h3>
                <div>
                    <Sanchalaksamiti />
                </div>

                <div>
                    <LekhaSamiti />
                </div>

                <div>
                    <Sallahkarsamiti />
                </div>

                <div>
                    <Sewakendrasamiti />
                </div>

                <div>
                    <Shikshyasamiti />
                </div>

                <div>
                    <Swasthyasamiti />
                </div>

                <div>
                    <Shakhasallahkarsamiti />
                </div>


            </Pagecontainer>
        </>
    )
}

const Sanchalaksamiti = () => {
    const [open, setOpen] = React.useState(false);

    const samiti = [
        {
            name: 'नन्दमान भट्टराई (नरेस)',
            designation: 'अध्यक्ष',
            address: 'विर्तामोड–५, झापा',
            phone: '९८५२६७३९४९',
        },
        {
            name: 'राजन वाग्ले',
            designation: 'उपाध्यक्ष',
            address: 'विर्तामोड–६, झापा',
            phone: '९८५२६७४३४७',
        },
        {
            name: 'खगेन्द्र कुवंर',
            designation: 'सचिव',
            address: 'विर्तामोड–५, झापा',
            phone: '९८५२६७७८०४',
        },
        {
            name: 'शान्ता विष्ट',
            designation: 'कोषाध्यक्ष',
            address: 'विर्तामोड–५, झापा',
            phone: '९८१७०१८३३३',
        },
        {
            name: 'हृदय कुमारी संग्रौला',
            designation: 'सदस्य',
            address: 'विर्तामोड–५, झापा',
            phone: '९८०४९१५५३३',
        },
        {
            name: 'चिरन्जीवि सिग्देल',
            designation: 'सदस्य',
            address: 'विर्तामोड–४, झापा',
            phone: '९८४२७५०६५३',
        },
        {
            name: 'रुपमाया राई',
            designation: 'सदस्य',
            address: 'विर्तामोड–५, झापा',
            phone: '९८४२६४०६८२',
        },
        {
            name: 'टेक बहादुर तामाङ्ग',
            designation: 'सदस्य',
            address: 'अर्जुनधारा-९, झापा',
            phone: '९८४२६३३३२७',
        },
        {
            name: 'नगेन्द्र न्यौपाने खत्री',
            designation: 'सदस्य',
            address: 'अर्जुनधारा-११, झापा',
            phone: '९८४२६७०३४०',
        },
        {
            name: 'गौरी प्रसाद भन्डारी ',
            designation: 'सदस्य',
            address: 'विर्तामोड–५, झापा',
            phone: '९८५२२६७७०७१',
        },
        {
            name: 'खड्ग बहादुर बिष्ट',
            designation: 'सदस्य',
            address: 'अर्जुनधारा-९, झापा',
            phone: '९८५२६२४७३८',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>संचालक समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}

                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4 underline">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const LekhaSamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'सिर्जना खतिवडा',
            designation: 'संयोजक',
            address: 'विर्तामोड–५, झापा',
            phone: '९८६३८९१९५९',
        },
        {
            name: 'शहन लाल राजवंशी',
            designation: 'सदस्य',
            address: 'विर्तामोड–४, झापा',
            phone: '९८०१४००८१३',
        },
        {
            name: 'गोपाल चौहान',
            designation: 'सदस्य',
            address: 'विर्तामोड–५, झापा',
            phone: '९८६२६७९१३१',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>लेखा समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const Sallahkarsamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'अच्युत दाहाल',
            designation: 'संयोजक',
            address: '',
            phone: '९८५२६७३६८७',
        },
        {
            name: 'निर्मल भट्टराई',
            designation: 'सदस्य',
            address: '',
            phone: '९८५२६७१०४९',
        },
        {
            name: ' बेदंता ठकुरी',
            designation: 'सदस्य',
            address: '',
            phone: '९८५२६४२१८५',
        },
        {
            name: 'दशरथ पराजुलि',
            designation: 'सदस्य',
            address: '',
            phone: '९८५२६८२३७४',
        },
        {
            name: 'नारायणप्रसाद चिमरिया',
            designation: 'सदस्य',
            address: '',
            phone: '९८५२६७४४०१',
        },
        {
            name: 'दशरथ हुमागाई',
            designation: 'सदस्य',
            address: '',
            phone: '९८४२६२३९०४',
        },
        {
            name: 'राजेन्द्र मैनाली',
            designation: 'सदस्य',
            address: '',
            phone: '९८०४९१११२२',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>सल्लाहकार समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const Sewakendrasamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'गौरी प्रसाद भन्डारी ',
            designation: 'संयोजक',
            address: 'विर्तामोड–५, झापा',
            phone: '९८५२२६७७०७१',
        },
        {
            name: 'सन्तोष चिमरिया',
            designation: 'सदस्य',
            address: '',
            phone: '९८४२७७४४२४',
        },
        {
            name: 'खेम दाहाल',
            designation: 'सदस्य',
            address: '',
            phone: '९८०१४५८२६२',
        },
        {
            name: 'दिपक बुढाथोकी',
            designation: 'सदस्य',
            address: '',
            phone: '९८१६०१३५२४',
        },
        {
            name: 'देवेन्द्र मैनाली',
            designation: 'सदस्य',
            address: '',
            phone: '९८०७९८०२८८',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>सेवाकेन्द्र समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const Shikshyasamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'खड्ग बहादुर विष्ट',
            designation: 'सयोंजक',
            address: '',
            phone: '',
        },
        {
            name: 'बल कृष्ण चम्लागाई',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
        {
            name: 'सर्बजित पौडेल',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>शिक्षा समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const Swasthyasamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'हृदय कुमारी सग्रैंला',
            designation: 'सयोंजक',
            address: '',
            phone: '',
        },
        {
            name: 'हिरामण शाह',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
        {
            name: 'थुम बहादुर थापा',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>स्वास्थ्य समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const Shakhasallahkarsamiti = () => {
    const [open, setOpen] = useState(false)
    const samiti = [
        {
            name: 'नरेन्द्र राई',
            designation: 'सयोंजक',
            address: '',
            phone: '',
        },
        {
            name: 'याम बहादुर इंगनाम',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
        {
            name: 'खेम राज खरेल',
            designation: 'सदस्य',
            address: '',
            phone: '',
        },
    ]

    return (
        <>
            <div className="relative overflow-x-auto border">
                <h3 className='flex items-center gap-1 p-5 text-xl cursor-pointer font-karma text-primary hover:underline' onClick={() => setOpen(!open)}>
                    <span className='mt-1'>शाखा सल्लाकार समिति</span>
                    <IconButton
                        aria-label="open" color='inherit'
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
                    </IconButton>
                </h3>
                {open === true && (
                    <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-sm text-gray-700 uppercase border-t font-karma bg-gray-50 ">
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                नाम
                            </th>
                            <th scope="col" className="py-3 px-6 w-[30%]">
                                ठेगाना
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                पद
                            </th>
                            <th scope="col" className="py-3 px-6 w-[20%]">
                                फोन न.
                            </th>
                        </thead>
                        <tbody>
                            {
                                samiti.map((item, index) => {
                                    return (
                                        <>
                                            <tr className="text-black bg-white border-t font-karma">
                                                <td scope="row" className="px-6 py-4 font-medium whitespace-nowrap ">
                                                    {item.name === '' ? '-' : item.name}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.address === '' ? '-' : item.address}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.designation === '' ? '-' : item.designation}
                                                </td>
                                                <td className="px-6 py-4">
                                                    {item.phone === '' ? '-' : item.phone}
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                )}
            </div>
        </>
    )
}

const TabularDataWithImage = () => {

    const Sanchalak = () => {
        useDocumentTitle('Our Team - Aabaddha SACCOS Ltd.')


        const team = [
            {
                name: 'राजन वाग्ले',
                position: 'उपाध्यक्ष',
                address: 'विर्तामोड–६, झापा',
            },
            {
                name: 'खगेन्द्र कुवंर',
                position: 'सचिव',
                address: 'विर्तामोड–५, झापा',
            },
            {
                name: 'शान्ता विष्ट',
                position: 'कोषाध्यक्ष',
                address: 'विर्तामोड–५, झापा',
            },
            {
                name: 'हृदय कुमारी संग्रौला',
                position: 'सदस्य',
                address: 'विर्तामोड–५, झापा',
            },
            {
                name: 'चिरन्जीवि सिग्देल',
                position: 'सदस्य',
                address: 'विर्तामोड–४, झापा',
            },
            {
                name: 'रुपमाया राई',
                position: 'सदस्य',
                address: 'विर्तामोड–५, झापा',
            },
            {
                name: 'टेक बहादुर तामाङ्ग',
                position: 'सदस्य',
                address: 'अर्जुनधारा-९, झापा',
            },
            {
                name: 'नगेन्द्र न्यौपाने खत्री',
                position: 'सदस्य',
                address: 'अर्जुनधारा-११, झापा',
            },
            {
                name: 'गौरी प्रसाद भन्डारी',
                position: 'सदस्य',
                address: 'विर्तामोड–५, झापा',
            },
            {
                name: 'खड्ग बहादुर बिष्ट',
                position: 'सदस्य',
                address: 'अर्जुनधारा-९, झापा',
            },
        ]

        const IndPerson = (props) => {
            return (
                <div className="p-5 text-center text-gray-500 border dark:text-gray-400">
                    <img className="object-cover mx-auto mb-4 rounded-full w-36 h-36" src={props.image} alt={props.name + "'s image"} />
                    <h3 className="mb-1 text-xl font-bold tracking-tight text-gray-900 font-vesper dark:text-white">
                        {props.name}
                    </h3>
                    <p className='font-karma'>{props.position}</p>

                </div>
            )
        }


        return (
            <>
                <section className="bg-white dark:bg-gray-900">
                    <div className="max-w-screen-xl px-4 py-5 mx-auto text-center lg:px-6">
                        <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                            <h3 className='text-3xl font-bold uppercase text-primary font-karma'>
                                संचालक समिति
                            </h3>

                            <div className='w-[30px] bg-primary h-[4px] my-2' />
                        </div>
                        <div className="p-5 text-center text-gray-500 dark:text-gray-400">
                            <img className="object-cover mx-auto mb-4 rounded-full w-36 h-36" src={require('../../assets/members/president.png')} alt='' />
                            <h3 className="mb-1 text-2xl font-bold tracking-tight text-gray-900 font-vesper dark:text-white">
                                नन्दमान भट्टराई (नरेस)
                            </h3>
                            <p className='font-karma'>अध्यक्ष</p>

                        </div>

                        <div className="grid gap-8 lg:gap-16 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5">
                            {team.map((member, index) => (
                                <IndPerson key={index} {...member} />
                            ))}
                        </div>
                    </div>
                </section>
            </>
        )
    }

    return (
        <>
            <Tabs>
                <TabList>
                    {/* <Tab>सल्लाहकार समिति</Tab> */}
                    <Tab>संचालक समिति</Tab>
                    {/* <Tab>लेखा समिति</Tab>
                    <Tab>सेवाकेन्द्र समिति</Tab>
                    <Tab>शिक्षा समिति</Tab>
                    <Tab>स्वास्थ्य समिति</Tab>
                    <Tab>शाखा सल्लाकार समिति</Tab> */}

                </TabList>

                <TabPanel>
                    <Sanchalak />
                </TabPanel>

                <TabPanel>
                    <h2>Any content 2</h2>
                </TabPanel>

                <TabPanel>
                    <h2>Any content 3</h2>
                </TabPanel>

                <TabPanel>
                    <h2>Any content 4</h2>
                </TabPanel>

                <TabPanel>
                    <h2>Any content 5</h2>
                </TabPanel>

                <TabPanel>
                    <h2>Any content 6</h2>
                </TabPanel>

                <TabPanel>
                    <h2>Any content 7</h2>
                </TabPanel>
            </Tabs>
        </>
    );
}

export default Organizationalstruct