import React from 'react'
import useDocumentTitle from '../../../functions/useDocumentTitle'
import Pagecontainer from '../../Pagecontainer'
import Breadcrumb from '../Breadcrumb'

const Social = () => {
    useDocumentTitle('Social Services - Aabaddha SACCOS Ltd.')
    return (
        <>
            <Breadcrumb root='Services' current='Social Services' />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    {/* <img className='w-1/6 mb-14' src={ContactImage} alt='Contact' /> */}
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase mb-5'>
                        Social Services
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                    <p className='font-vesper text-base font-medium text-black text-opacity-70 text-center my-5'>
                        आबद्ध बचत तथा ऋण सहकारी संस्था लि.द्वारा गरिने सामाजिक सेवाहरु
                    </p>
                    <img className='w-full md:w-1/3 mt-10' src={require('../../../assets/socialservices.png')} alt='Social Services' />
                </div>


                {/* <div className='grid grid-cols-4 gap-3'>

                </div> */}
            </Pagecontainer>
        </>
    )
}

export default Social