import React from 'react'
import Pagecontainer from './Pagecontainer'
import Breadcrumb from './components/Breadcrumb'
import useDocumentTitle from '../functions/useDocumentTitle'

import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Stack } from '@mui/system';

const NewsPage = () => {
    useDocumentTitle('News - Aabaddha SACCOS Ltd.')

    const [News, setNews] = useState([]);
    const [date, setDate] = useState([]);
    const [notId, setNotId] = useState([]);

    useEffect(() => {
        try {
            const q = query(collection(db, "news"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setNews(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);
    const columns = [
        {
            field: 'title', headerName: 'समाचार', align: 'left', editable: false, headerClassName: 'text-white font-vesper text-base', flex: 1,
            renderCell: (params) => {
                return (
                    <Link to={`/news/${params.id}`} className='text-primary-d underline'>
                        {params.value}
                    </Link>
                )
            }
        },
        { field: 'date', headerName: 'प्रकाशन मिति', type: 'string', align: 'left', headerAlign: 'left', editable: false, sortable: true, headerClassName: 'text-white font-vesper text-base', flex: 0 },
    ]

    return (
        <>
            <Breadcrumb root='Home' current='News' />
            <Pagecontainer>

                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        News
                    </h3>
                    <p className='font-vesper text-base font-medium text-red-700 mt-2'>
                        समाचारहरु
                    </p>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>

                <div className='h-[500px]'>
                    <DataGrid
                        columns={columns}
                        rows={News}
                        disableSelectionOnClick={true}
                        components={{
                            NoRowsOverlay: () => (
                                <Stack className='text-center' height="100%" alignItems="center" justifyContent="center">
                                    <p className='text-sm text-gray-600 mt-10'>No news found.</p>
                                </Stack>
                            ),

                        }}
                        sx={{
                            boxShadow: 0,
                            border: 1,
                            borderColor: '#00000020',
                            '& .css-1s0hp0k-MuiDataGrid-columnHeadersInner': {
                                bgcolor: '#008037',
                            },
                            '& .css-f3jnds-MuiDataGrid-columnHeaders': {
                                bgcolor: '#008037',
                            },
                            '& .css-i4bv87-MuiSvgIcon-root': {
                                color: '#008037',
                            },
                            '& .css-1nitb7c': {
                                bgcolor: '#008037',
                            },
                            '& .css-1xvznzl .MuiDataGrid-iconSeparator': {
                                display: 'none',
                            },
                        }}
                    />
                </div>
            </Pagecontainer>

        </>
    )
}

export default NewsPage