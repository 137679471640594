import { Divider } from '@mui/material';
import { doc, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import Pagecontainer from '../Pagecontainer';
import Breadcrumb from './Breadcrumb';
import News from './News';
import { Facebook } from 'react-social-sharing'
import { Mail } from '@mui/icons-material';

const NewsDetail = () => {
    const { nid } = useParams();

    const [news, setNews] = useState([]);
    const [date, setDate] = useState([]);
    const [image, setImage] = useState([]);
    const [content, setContent] = useState([]);
    const [additionalImages, setAdditionalImages] = useState([]);

    const [copied, setCopied] = useState(false);

    useEffect(() => {
        try {
            const q = doc(db, `news/${nid}`);
            const unsubscribe = onSnapshot(q, (doc) => {
                setNews(doc.data().title);
                setDate(doc.data().date);
                setImage(doc.data().image);
                setContent(doc.data().content);
                setAdditionalImages(doc.data().additionalImages);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, [nid])


    const waitTillImageLoad = (image) => {
        if (image.complete) {
            return image.naturalWidth;
        }
        return waitTillImageLoad(image);
    }

    const copyUrl = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopied(true);
    }

    useEffect(() => {
        if (copied) {
            setTimeout(() => {
                setCopied(false);
            }, 5000);
        }
    }, [copied]);

    return (
        <>
            <Helmet>
                <title>{news}</title>
                <meta name="description" content={content} />
                <meta name="keywords" content={news} />
                {/* OG tags */}
                <meta property="og:title" content={news} />
                <meta property="og:description" content={content.slice(0, 100)} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={`https://www.aabaddha.coop.np/news/${nid}`} />
                <meta property="og:type" content="article" />
                <meta property="og:site_name" content="Aabaddha" />
            </Helmet>
            <Breadcrumb root='News' current={news} />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center w-full px-5 md:px-28 mb-10'>
                    {/* <div className='w-full'> */}
                    <h3 className='w-full flex justify-center items-center pt-3 font-vesper text-white text-center text-3xl py-1 mt-10 bg-primary'>
                        {news}
                    </h3>
                    <h4 className='my-5'>
                        प्रकाशन मिति: {date}
                    </h4>
                    {/* </div> */}
                    <div className='w-full flex justify-center'>
                        <img src={image} alt="aabaddha news" className="w-[700px] border-3 border object-cover" />
                    </div>
                    <p className='text-base font-vesper my-10 text-black'>
                        {content}
                    </p>
                    {additionalImages && additionalImages.length > 0 &&
                        <p className='text-base font-karma my-5'>
                            अन्य तस्विरहरु
                        </p>
                    }
                    <div className='w-full grid grid-cols-2 md:grid-cols-4 items-center justify-center'>
                        {additionalImages && additionalImages.length > 0 && additionalImages.map((image, index) => {
                            return (
                                <div key={index} className='w-full'>
                                    <img src={image} alt="aabaddha news" className="h-48 w-96 border-3 border object-cover" />
                                </div>
                            )
                        }
                        )}
                    </div>
                    <div className='flex items-center w-full'>
                        <p>
                            Share on
                        </p>
                        <Facebook solid small link={`https://www.aabaddha.coop.np/news/${nid}`} />
                        {/* copy button */}
                        <button onClick={copyUrl} className='flex items-center justify-center bg-primary text-white px-2 py-1 rounded-md ml-2'>
                            <p className='ml-1'>
                                {
                                    copied ? 'Copied' : 'Copy Link'
                                }
                            </p>
                        </button>
                    </div>
                </div>
                <Divider />
                <h2 className='my-5 text-xl text-primary font-vesper text-center'>
                    अन्य समाचारहरु
                </h2>
                <News title={false} />

            </Pagecontainer>


        </>
    )
}

export default NewsDetail