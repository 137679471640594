import { collection, onSnapshot, query } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { db } from '../../firebase'
import Pagecontainer from '../Pagecontainer'

const SomeNumbers = () => {

    const [finData, setFinData] = React.useState({})

    const [year, setYear] = React.useState('')
    const [kulKosh, setKulKosh] = React.useState('')
    const [sharePunji, setSharePunji] = React.useState('')
    const [bachatNikshep, setBachatNikshep] = React.useState('')
    const [bahyaKarja, setBahyaKarja] = React.useState('')
    const [bank, setBank] = React.useState('')
    const [rin, setRin] = React.useState('')
    const [isthir, setIsthir] = React.useState('')
    const [sadasya, setSadasya] = React.useState('')
    const [jagedaKosh, setJagedaKosh] = React.useState('')



    useEffect(() => {
        try {
            const q = query(collection(db, "siteData"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setFinData(querySnapshot.docs.map(doc => doc.data().finData));
                setYear(querySnapshot.docs.map(doc => doc.data().year));
                setKulKosh(querySnapshot.docs.map(doc => doc.data().kulKosh));
                setSharePunji(querySnapshot.docs.map(doc => doc.data().sharePunji));
                setBachatNikshep(querySnapshot.docs.map(doc => doc.data().bachatNikshep));
                setBahyaKarja(querySnapshot.docs.map(doc => doc.data().bahyaKarja));
                setBank(querySnapshot.docs.map(doc => doc.data().bank));
                setRin(querySnapshot.docs.map(doc => doc.data().rin));
                setIsthir(querySnapshot.docs.map(doc => doc.data().isthir));
                setSadasya(querySnapshot.docs.map(doc => doc.data().sadasya));
                setJagedaKosh(querySnapshot.docs.map(doc => doc.data().jagedaKosh));
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    const IndGrid = ({ title, value }) => {
        return (
            <div className='flex flex-col items-center justify-center p-5 border'>
                <h3 className='text-lg font-thin font-vesper'>{title}</h3>
                <h1 className='text-2xl font-sans text-primary'>{value}</h1>
            </div>
        )
    }


    return (
        <>

            <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                <h3 className='text-primary text-center font-sans text-2xl font-bold uppercase'>
                    Some NUMBERS {' '}
                    <p className='font-sans text-base font-medium text-red-700 mt-2'>
                        Data upto {year}
                    </p>
                </h3>
                <div className='w-[30px] bg-primary h-[4px] my-2' />
            </div>

            <Pagecontainer>

                <div className='grid grid-cols-1 md:grid-cols-4 gap-5'>

                    <IndGrid title='कुल कोष' value={kulKosh} />
                    <IndGrid title='शेयर पुंजी' value={sharePunji} />
                    <IndGrid title='जगेडा कोष' value={jagedaKosh} />
                    <IndGrid title='बचत/निक्षेप' value={bachatNikshep} />
                    <IndGrid title='बाह्य कर्जा' value={bahyaKarja} />
                    <IndGrid title='बैंक' value={bank} />
                    <IndGrid title='ऋण लगानी' value={rin} />
                    {/* <IndGrid title='स्थिर सम्पति' value={isthir} /> */}
                    <IndGrid title='सदस्य संख्या' value={sadasya} />


                </div>

            </Pagecontainer>


        </>
    )
}

export default SomeNumbers