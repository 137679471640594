import { Divider } from '@mui/material'
import React, { useEffect } from 'react'
import Sidebar from '../Sidebar'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';

const Dashboard = () => {
    return (
        <Sidebar>
            <>
                <div className='mb-5'>
                    <h1>
                        Welcome to the control panel of Aabaddha SACCOS Ltd.
                    </h1>
                </div>
                <Divider />
                <div className='my-10'>
                    <ServerStat />
                </div>
            </>
        </Sidebar>
    )
}

const ServerStat = () => {

    // random number with min and max
    function randomNumber(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const [oneStat, setOneStat] = React.useState(90);
    const [twoStat, setTwoStat] = React.useState(90);
    const [threeStat, setThreeStat] = React.useState(90);
    const [fourStat, setFourStat] = React.useState(90);

    const MINUTE_MS = 10000;

    useEffect(() => {
        const interval = setInterval(() => {
            setOneStat(randomNumber(80, 100));
            setTwoStat(randomNumber(87, 100));
            setThreeStat(randomNumber(75, 100));
            setFourStat(randomNumber(95, 100));
        }, MINUTE_MS);

        return () => clearInterval(interval);
    }, [])

    // random numbers every minute


    const FakeDataVis = (props) => {
        return (
            <CircularProgressbar
                className='w-28 text-center'
                value={props.stat}
                text={`${props.stat}%`}
                styles={buildStyles({
                    textSize: '20px',
                    pathColor: '#008037',
                    textColor: '#008037',
                    textAlign: 'center',
                    backgroundColor: '#E53337',
                    trailColor: `#008037`,
                })}
            />
        )
    }

    return (
        <div className='w-1/2 flex flex-col gap-5 items-center justify-start border p-4 bg-white'>
            <p className='text-black text-opacity-50 dark:text-white-rgba-5'>SERVER PERFORMANCE</p>

            <div className='w-[100%] flex items-center justify-center gap-5'>
                <FakeDataVis stat={oneStat} />
                <FakeDataVis stat={twoStat} />
                <FakeDataVis stat={threeStat} />
                <FakeDataVis stat={fourStat} />
            </div>

            <div className='flex items-center'>
                <p className=' flex gap-4'>
                    Listening realtime updates
                </p>
            </div>
        </div>
    )
}

export default Dashboard