import React, { useEffect } from 'react'
import Sidebar from '../Sidebar'
import { collection, deleteDoc, doc, onSnapshot, query } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { deleteObject, ref } from 'firebase/storage';
import { Stack } from '@mui/material';

const KomAdmin = () => {
    const [komList, setKomList] = React.useState([]);

    useEffect(() => {
        try {
            const q = query(collection(db, 'kom-submissions'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setKomList(data);
            }
                , [])
        } catch (e) {
        }
    }, []);


    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "kom-submissions"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }


    const columns = [
        { field: 'reference_number', headerName: 'Reference No.', width: 200, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'name', headerName: 'Name', width: 200, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'membership_no', headerName: 'Membership No.', width: 200, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6' },
        {
            field: 'pdf_url', headerName: 'Submitted PDF', width: 200, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6',
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <a href={params.value} target='_blank' rel='noreferrer' className='hover:underline'>
                            <button className='bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full'>
                                View
                            </button>
                        </a>
                    </div>
                )
            }
        },

        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 100, align: 'left', headerAlign: 'left', editable: false, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            }
        },
    ];



    return (
        <>
            <Sidebar>

                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>KOM Submissions</h3>
                </div>

                <div className='h-[500px]'>
                    <DataGrid
                        columns={columns}
                        rows={komList}
                        disableSelectionOnClick={true}
                        components={{
                            Toolbar: GridToolbar,
                            NoRowsOverlay: () => (
                                <Stack className='text-center' height="100%" alignItems="center" justifyContent="center">
                                    <p className='text-sm text-gray-600 mt-10'>No submissions found.</p>
                                </Stack>
                            ),

                        }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            }
                        }}
                    />
                </div>

            </Sidebar>
        </>
    )
}

export default KomAdmin