import { DataGrid } from '@mui/x-data-grid'
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { db } from '../../../firebase'
import Globalsnack from '../../../Globalsnack'
import Sidebar from '../../Sidebar'

const Acts = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [acts, setActs] = React.useState([]);
    const [updated, setUpdated] = React.useState(false);

    const navigate = useNavigate()

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "acts"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setActs(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    const editData = async (e) => {
        const field = e.field;
        const value = e.value;
        try {
            await setDoc(doc(db, "acts", e.id), {
                [field]: value,
                updatedAt: new Date().toString('YYYY-MM-dd'),
            },
                { merge: true }
            );
            setUpdated(true);

        } catch (error) {
            console.log(error);
        }
    }

    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "acts"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }
    const columns = [
        { field: 'title', headerName: 'Title', width: 500, align: 'left', editable: true, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'link', headerName: 'Link', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            },
        },
    ];

    return (
        <>
            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Acts &amp; Policies</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>

                <small><i>Double click on the cell to edit.</i></small>

                {
                    addMode === true ?
                        <Addind changeAddMode={changeAddMode} />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={acts}
                                    disableSelectionOnClick={true}
                                    onCellEditCommit={(e) => {
                                        editData(e);
                                    }}
                                />
                            </div>
                        </>
                }

            </Sidebar>
            <Globalsnack type="success" text="Updated successfully." open={updated} onClose={() => setUpdated(false)} />
        </>
    )
}

const Addind = (props) => {
    const [title, setTitle] = React.useState('');
    const [link, setLink] = React.useState('');

    const [uploaded, setUploaded] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);


    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleLink = (e) => {
        setLink(e.target.value);
    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "acts"), {
            title: title,
            link: link,
            timestamp: new Date().getTime(),
        })
            .then((docRef) => {
                setUploaded(true);
                setTitle('');
                setLink('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        if (title !== '' && link !== '') {
            await uploadDoc();
        }
    }


    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
                    <input required={true} value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />
                    <input required={true} value={link} onChange={handleLink} rows={10} type="text" className=" bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Link" />
                    {/* <input required value={date} onChange={handleDate} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Date" /> */}

                    <button type="submit" className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>
                        {
                            uploading === true ?
                                'Uploading...'
                                :
                                'Upload'
                        }
                    </button>

                </form>
            </div>
            <Globalsnack type="success" text="News released successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}


export default Acts