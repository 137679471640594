import React, { useEffect } from 'react'
import Pagecontainer from './Pagecontainer'
import Breadcrumb from './components/Breadcrumb'
import { Link, NavLink } from 'react-router-dom'
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { db } from '../firebase'
import { Helmet } from 'react-helmet'

const SavingsPage = () => {
    const [sadharan, setSadharan] = React.useState([]);
    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'sadharan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setSadharan(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    const [kramik, setKramik] = React.useState([]);
    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'kramik'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setKramik(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    return (
        <>
            <Helmet>
                <title>Savings - Aabaddha SACCOS Ltd.</title>
                <meta name="description" content="Savings - Aabaddha SACCOS Ltd." />
            </Helmet>
            <Breadcrumb root='Services' current='Savings' />
            <Pagecontainer>

                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        Savings
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>


                <div className='flex flex-col'>
                    <div className='flex flex-col'>
                        <p className='font-vesper text-lg font-medium text-red-700 mt-2'>
                            साधारण / दैनिक बचत
                        </p>

                        <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-2'>

                            {
                                sadharan.
                                    map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-col border p-5'
                                                // set bg image
                                                style={{
                                                    backgroundImage: `url(${'https://cdn.pixabay.com/photo/2023/01/07/05/28/coins-7702613_960_720.jpg'})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat'
                                                }}
                                            >
                                                <Link
                                                    to={`/services/savings/sadharan/${item.id}`}
                                                >
                                                    <span className='font-sans font-medium text-sm capitalize hover:underline'>
                                                        {item.title}
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    )
                            }

                        </div>

                    </div>

                    <div className='flex flex-col mt-10'>
                        <p className='font-vesper text-lg font-medium text-red-700 mt-2'>
                            आबद्ध क्रमिक बचत
                        </p>

                        <div className='grid grid-cols-1 md:grid-cols-4 gap-4 mt-2'>

                            {
                                kramik.
                                    map((item, index) => {
                                        return (
                                            <div
                                                key={index}
                                                className='flex flex-col border p-5'
                                                // set bg image
                                                style={{
                                                    backgroundImage: `url(${require('../assets/stocks/kramik.jpg')})`,
                                                    backgroundSize: 'cover',
                                                    backgroundPosition: 'center',
                                                    backgroundRepeat: 'no-repeat',
                                                    // dimmer
                                                }}
                                            >
                                                <Link
                                                    to={`/services/savings/kramik/${item.id}`}
                                                >
                                                    <span className='text-white font-sans font-medium text-sm capitalize hover:underline'>
                                                        {item.title}
                                                    </span>
                                                </Link>
                                            </div>
                                        )
                                    }
                                    )
                            }

                        </div>

                    </div>

                    <div className='flex flex-col mt-10'>
                        <p className='font-vesper text-lg font-medium text-red-700 mt-2'>
                            आवधिक बचत
                        </p>

                        <div
                            className='flex flex-col border p-5 md:w-1/4'
                            style={{
                                backgroundImage: `url(${require('../assets/stocks/aabadhik.jpg')})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                            }}
                        >
                            <Link
                                to={`/services/savings/aabadhik`}
                            >
                                <span className='text-white font-sans font-medium text-sm capitalize hover:underline'>
                                    आवधिक बचत
                                </span>
                            </Link>
                        </div>
                    </div>
                </div>

            </Pagecontainer>

        </>
    )
}

export default SavingsPage