import { collection, onSnapshot, query, where } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import Dropdown from 'react-multilevel-dropdown'
import { Link, NavLink } from 'react-router-dom'
import { db } from '../../firebase'
import './styles/navbar.css'

const Navbar = () => {
    const [open, setOpen] = React.useState(false)
    const [openBranch, setOpenBranch] = React.useState(false)
    const [openAbout, setOpenAbout] = React.useState(false)
    const [openKnowledge, setOpenKnowledge] = useState(false)

    const [sadharan, setSadharan] = React.useState([])
    const [kramik, setKramik] = React.useState([])
    const [loan, setLoan] = React.useState([])

    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'sadharan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setSadharan(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'kramik'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setKramik(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    useEffect(() => {
        try {
            const q = query(collection(db, 'loan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setLoan(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    return (
        <>
            <nav className='hidden md:flex p-2 items-center justify-center flex-col'>
                <ul className='flex items-center justify-center gap-6 uppercase font-sans text-[13px] font-bold'>
                    <li>
                        <NavLink
                            to='/'
                            className={({ isActive }) => (isActive ? 'text-primary underline' : 'text-black hover:text-primary hover:underline')}
                        >
                            Home
                        </NavLink>
                    </li>

                    <Dropdown
                        position='right'
                        onClick={() => setOpenAbout(!openAbout)}
                        title={<>
                            About
                            {
                                openAbout ? <i className="bi bi-chevron-up ml-2"></i> : <i className="bi bi-chevron-down ml-2"></i>
                            }
                        </>}
                        className='text-black hover:text-primary hover:underline uppercase'
                    >
                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/about/introduction`}
                            >
                                Introduction & Profile
                            </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/about/presidents-message`}
                            >
                                President's Message
                            </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/about/organizational-structure`}
                            >
                                Organizational Structure
                            </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/about/team`}
                            >
                                Our Team
                            </NavLink>
                        </Dropdown.Item>

                    </Dropdown>

                    <Dropdown
                        position='right'
                        onClick={() => setOpen(!open)}
                        title={<>
                            Services
                            {
                                open ? <i className="bi bi-chevron-up ml-2"></i> : <i className="bi bi-chevron-down ml-2"></i>
                            }
                        </>}
                        className='text-black hover:text-primary hover:underline uppercase'
                    >

                        <Dropdown.Item
                            className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'
                        >
                            Savings
                            <i className="bi bi-chevron-right ml-2"></i>
                            <Dropdown.Submenu position='right'>
                                <Dropdown.Item
                                    className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'
                                >
                                    <span className='font-karma font-medium text-base'>
                                        साधारण / दैनिक बचत
                                    </span>
                                    <i className="bi bi-chevron-right ml-2"></i>

                                    <Dropdown.Submenu position='right'>
                                        {
                                            sadharan.
                                                map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'>
                                                            <NavLink
                                                                to={`services/savings/sadharan/${item.id}`}
                                                            >
                                                                <span className='font-sans font-medium text-sm capitalize'>
                                                                    {item.title}
                                                                </span>
                                                            </NavLink>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                )
                                        }
                                    </Dropdown.Submenu>
                                </Dropdown.Item>

                                <Dropdown.Item
                                    className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'
                                >
                                    <span className='font-karma font-medium text-base'>
                                        आबद्ध क्रमिक बचत
                                    </span>
                                    <i className="bi bi-chevron-right ml-2"></i>

                                    <Dropdown.Submenu position='right'>
                                        {
                                            kramik.
                                                map((item, index) => {
                                                    return (
                                                        <Dropdown.Item key={index} className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'>
                                                            <NavLink
                                                                to={`services/savings/kramik/${item.id}`}
                                                            >
                                                                <span className='font-karma font-medium text-sm capitalize'>
                                                                    {item.title}
                                                                </span>
                                                            </NavLink>
                                                        </Dropdown.Item>
                                                    )
                                                }
                                                )
                                        }

                                    </Dropdown.Submenu>

                                </Dropdown.Item>

                                <Dropdown.Item
                                    className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'
                                >
                                    <NavLink
                                        to={`services/savings/aabadhik`}
                                    >
                                        <span className='font-karma font-medium text-base'>
                                            आवधिक बचत
                                        </span>
                                    </NavLink>
                                </Dropdown.Item>

                            </Dropdown.Submenu>
                        </Dropdown.Item>



                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            Loan
                            <i className="bi bi-chevron-right ml-2"></i>
                            <Dropdown.Submenu position='right'>
                                {
                                    loan.
                                        map((item, index) => {
                                            return (
                                                <Dropdown.Item key={index} className='!text-black hover:!text-primary hover:underline uppercase hover:!bg-[#f1fef7]'>
                                                    <NavLink
                                                        to={`services/loan/${item.id}`}
                                                    >
                                                        <span className='font-karma font-medium text-base capitalize'>
                                                            {item.title}
                                                        </span>
                                                    </NavLink>
                                                </Dropdown.Item>
                                            )
                                        }
                                        )
                                }
                            </Dropdown.Submenu>
                        </Dropdown.Item>

                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/services/remittance`}
                            >
                                Remittance
                            </NavLink>
                        </Dropdown.Item>

                        <Dropdown.Item className='!text-black hover:!text-primary hover:underline uppercase'>
                            <NavLink
                                to={`/services/social`}
                            >
                                Social Services
                            </NavLink>
                        </Dropdown.Item>

                    </Dropdown>



                    <li>
                        <NavLink
                            to='/contact'
                            className={({ isActive }) => (isActive ? 'text-primary underline' : 'text-black hover:text-primary hover:underline')}
                        >
                            Contact
                        </NavLink>
                    </li>
                    <li>
                        <NavLink
                            to='/notices'
                            className={({ isActive }) => (isActive ? 'text-primary underline' : 'text-black hover:text-primary hover:underline')}
                        >
                            Notices
                        </NavLink>
                    </li>

                    <Dropdown
                        position='right'
                        onClick={() => setOpenBranch(!openBranch)}
                        title={<>
                            Branches
                            {
                                openBranch === true ? <i className="bi bi-chevron-up ml-2"></i> : <i className="bi bi-chevron-down ml-2"></i>
                            }
                        </>}
                        className='text-black hover:text-primary hover:underline uppercase'
                    >
                        <Dropdown.Item
                            className='!text-black hover:!text-primary hover:underline hover:!bg-[#f1fef7]'
                        >
                            <NavLink
                                to={`/branches/arjundhara`}
                            >
                                <span className='font-sans font-medium text-sm capitalize'>
                                    Arjundhara Branch
                                </span>
                            </NavLink>
                        </Dropdown.Item>
                    </Dropdown>

                    <Dropdown
                        position='right'
                        onClick={() => setOpenKnowledge(!openKnowledge)}
                        title={<>
                            COOP Knowledge
                            {
                                openKnowledge === true ? <i className="bi bi-chevron-up ml-2"></i> : <i className="bi bi-chevron-down ml-2"></i>
                            }
                        </>}
                        className='text-black hover:text-primary hover:underline uppercase'
                    >
                        <Dropdown.Item
                            className='!text-black hover:!text-primary hover:underline hover:!bg-[#f1fef7]'
                        >
                            <NavLink
                                to={`/knowledge/acts-policies`}
                            >
                                <span className='font-sans font-medium text-sm capitalize'>
                                    Act, policy & Directives
                                </span>
                            </NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item
                            className='!text-black hover:!text-primary hover:underline hover:!bg-[#f1fef7]'
                        >
                            <NavLink
                                to={`/knowledge/articles`}
                            >
                                <span className='font-sans font-medium text-sm capitalize'>
                                    Articles & Knowledge
                                </span>
                            </NavLink>
                        </Dropdown.Item>
                    </Dropdown>

                    {/* <li>
                        <NavLink
                            to='/update-kom'
                            className={({ isActive }) => (isActive ? 'text-primary underline' : 'text-black hover:text-primary hover:underline')}
                        >
                            Update KOM
                        </NavLink>
                    </li> */}

                    <button className='bg-primary p-1 px-3 rounded hover:bg-primary-d'>
                        <Link to='/update-kom' className='text-xs font-sans text-white'>
                            <i className="bi bi-ui-radios mr-2"></i>
                            UPDATE KOM
                        </Link>
                    </button>

                    {/* <li>
                        <NavLink
                            to='/gallery'
                            className={({ isActive }) => (isActive ? 'text-primary underline' : 'text-black hover:text-primary hover:underline')}
                        >
                            Gallery
                        </NavLink>
                    </li> */}

                    {/* <button className='bg-primary p-1 px-3 rounded hover:bg-primary-d'>
                        <a className='text-xs font-sans text-white' href='tel:023-535395'>
                            <i className="bi bi-telephone-fill mr-2"></i>
                            CALL
                        </a>
                    </button> */}
                </ul>

            </nav>
        </>
    )
}

export default Navbar