import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import Breadcrumb from './components/Breadcrumb'
import Pagecontainer from './Pagecontainer'
import ContactImage from '../assets/illustrations/contact.svg'
import emailjs from 'emailjs-com'
// is deprecated
import { set, useForm } from 'react-hook-form';
import Globalsnack from '../Globalsnack'
import { Alert, Slide, Snackbar } from '@mui/material'
import './components/styles/contact.css'

function TransitionLeft(props) {
    return <Slide {...props} direction="down" />;
}

const Contact = () => {
    useDocumentTitle('Contact - Aabaddha SACCOS Ltd.')

    const [sent, setSent] = React.useState(false)
    const [eSent, setESent] = React.useState(false)
    const [sending, setSending] = React.useState(false)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm();

    const onSubmit = async (data) => {
        const { name, email, message } = data;

        const sendToDiscord = async () => {
            setSending(true)
            const data = {
                "embeds": [
                    {
                        "title": `New message received at Aabaddha's website.`,
                        "description": `New message from ${name} (${email}).\n\n${message}\n\n`,
                        "color": "32823",
                        "timestamp": new Date(),
                        "footer": {
                            "text": "LinkBinary Contact Bot"
                        }
                    }
                ]
            }
            await fetch('https://discord.com/api/webhooks/1008781019001917510/VbxZzGr-njoUVqjj86_XcEJevSIoGK8_6eFKPqn92QaHzeXJxrcic_jHfUzMDaanlGKy', {
                method: 'POST',
                body: JSON.stringify(data),
                headers: {
                    'Content-Type': 'application/json'
                },
            }).then(
                (response) => {
                    if (response.ok) {
                        reset();
                        setSent(true);
                        setSending(false)
                    } else {
                    }
                }
            ).catch(
                (error) => {
                    // console.log(error);
                }
            );
        }

        sendToDiscord();

        try {
            const templateParams = {
                name,
                email,
                message
            };
            await emailjs.send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID,
                process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
                templateParams,
                process.env.REACT_APP_EMAILJS_USER_ID
            );
            reset();
            setESent(true);
        }
        catch (e) {
            // console.log(e);
        }

        return new Promise(resolve => {
            setTimeout(() => {
                resolve();
            }, 2000);
        });

    }

    return (
        <>
            <Breadcrumb root='Home' current='Contact' />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <img className='w-1/2 md:w-1/6 mb-14' src={ContactImage} alt='Contact' />
                    <h3 className='font-sans text-3xl font-bold uppercase text-primary'>
                        Contact Us
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>

                {/* <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto mt-16 mb-10 text-gray-900 bg-gray-100 rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32"> */}


                {/* <div className="flex flex-col justify-start">
                        <div>
                            <h2 className="text-4xl font-bold leading-tight lg:text-5xl">Lets talk about everything!</h2>
                            <div className="mt-8 text-gray-700">
                                Hate forms? Send us an <a href='mailto:mail@aabaddha.coop.np' className="underline">email</a> instead.
                            </div>
                            <iframe className='w-full border rounded h-96' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.286823712716!2d87.99583009999999!3d26.6392975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5bbacb1349497%3A0x61242f467f5c5a92!2sAabaddha%20Cooperative!5e0!3m2!1sen!2snp!4v1662187860026!5m2!1sen!2snp" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className="flex flex-col gap-5 mt-8">
                            <h3 className="text-lg font-bold leading-tight lg:text-xl">
                                Contact Details
                            </h3>
                            <div className='flex gap-4'>
                                <i className="bi bi-geo-alt" />
                                <p className='font-sans text-base '>
                                    Near Newton's Academy
                                    <br />
                                    Birtamode 05 - Jhapa 57204
                                    <br />
                                    Province 1, Nepal
                                </p>
                            </div>

                            <div className='flex gap-4'>
                                <i className="bi bi-telephone" />
                                <ul className='font-sans text-base'>
                                    <li className='hover:underline'>
                                        <a href='tel:023-535395'>
                                            023-535395
                                        </a>
                                    </li>
                                    <li className='hover:underline'>
                                        <a href='tel:023-536705'>
                                            023-536705
                                        </a>
                                    </li>
                                    <li>
                                        <a className='hover:underline' href='tel:+977 023-465082'>
                                            023-465082
                                        </a>
                                        {' '}
                                        (Arjundhara Branch)
                                    </li>
                                </ul>
                            </div>

                            <div className='flex gap-4'>
                                <i className="bi bi-envelope" />
                                <ul className='font-sans text-base '>
                                    <li className='hover:underline'>
                                        <a href='mailto:mail@aabaddha.coop.np'>
                                            mail@aabaddha.coop.np
                                        </a>
                                    </li>
                                    <li className='hover:underline'>
                                        <a href='mailto:info@aabaddha.coop.np'>
                                            info@aabaddha.coop.np
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className='flex gap-4'>
                                <i className="bi bi-facebook" />
                                <ul className='font-sans text-base '>
                                    <li className='hover:underline'>
                                        <a target='_none' href='https://facebook.com/aabaddhasaccos'>
                                            Aabaddha SACCOS
                                        </a>

                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <form className="" onSubmit={handleSubmit(onSubmit)}>
                        <div>
                            <span className="text-sm font-bold text-gray-600 uppercase">Full Name</span>
                            <input
                                className="w-full p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text"
                                placeholder=""
                                {...register('name', {
                                    required: { value: true, message: 'You need to enter your name.' },
                                    maxLength: {
                                        value: 30,
                                        message: "Your name's a length of Brachiosaurus. Can't use that name!"
                                    }
                                })}
                            />
                            {errors.name && <span className='my-2 text-sm text-red-600'>{errors.name.message}</span>}
                        </div>
                        <div className="mt-8">
                            <span className="text-sm font-bold text-gray-600 uppercase">Email</span>
                            <input
                                className="w-full p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg focus:outline-none focus:shadow-outline"
                                type="text"
                                {...register('email', {
                                    required: true,
                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                })}
                            />
                            {errors.email && <span className='my-2 text-sm text-red-600'>{errors.email.message}</span>}
                        </div>
                        <div className="mt-8">
                            <span className="text-sm font-bold text-gray-600 uppercase">Message</span>
                            <textarea
                                className="w-full h-32 p-3 mt-2 text-gray-900 bg-gray-300 rounded-lg resize-none focus:outline-none focus:shadow-outline"
                                {...register('message', {
                                    required: true
                                })}
                            />
                            {errors.message && <span className='text-sm pl-[5px] text-red-600'>You need to enter a message.</span>}
                        </div>
                        <div className="mt-8">
                            <button
                                type='submit'
                                className="w-full p-3 text-sm font-bold tracking-wide text-gray-100 uppercase rounded-lg bg-primary focus:outline-none focus:shadow-outline"
                            >
                                {
                                    sending ? 'Sending...' : 'Send'
                                }
                            </button>
                        </div>
                        {
                            eSent &&
                            <p className="mt-5 text-sm text-primary">
                                Thanks for contacting us! We will reply to you as soon as possible.
                            </p>
                        }
                    </form> */}



                <div className="containerrr">
                    <div className="innerwrap">

                        <section className="clearfix section2">
                            <div className="col2 column1 first">
                                <iframe className='w-full rounded border md:h-[550px] mb-5 md:mb-0' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3566.286823712716!2d87.99583009999999!3d26.6392975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39e5bbacb1349497%3A0x61242f467f5c5a92!2sAabaddha%20Cooperative!5e0!3m2!1sen!2snp!4v1662187860026!5m2!1sen!2snp" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                            <div className="col2 column2 last">
                                <div className="sec2innercont ">
                                    <div className="sec2addr">
                                        <div className='flex gap-4 mb-2'>
                                            <i className="bi bi-geo-alt" />
                                            <p className='font-sans text-base '>
                                                Birtamode 05 - Jhapa 57204
                                                <br />
                                                Province 1, Nepal
                                            </p>
                                        </div>
                                        <div className='flex gap-4 mb-2'>
                                            <i className="bi bi-telephone" />
                                            <ul className='font-sans text-base'>
                                                <li className='hover:underline'>
                                                    <a href='tel:023-535395'>
                                                        023-535395
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div className='flex gap-4 mb-2'>
                                            <i className="bi bi-envelope" />
                                            <ul className='font-sans text-base '>
                                                <li className='hover:underline'>
                                                    <a href='mailto:info@aabaddha.coop.np'>
                                                        info@aabaddha.coop.np
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className='flex gap-4 mb-2'>
                                            <i className="bi bi-facebook" />
                                            <ul className='font-sans text-base '>
                                                <li className='hover:underline'>
                                                    <a target='_none' href='https://facebook.com/aabaddhasaccos'>
                                                        Aabaddha SACCOS
                                                    </a>

                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="sec2contactform">
                                    <h3 className="sec2frmtitle">Need to know more? Drop us a mail.</h3>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="clearfix">
                                            <input className="col2 last" type="text" placeholder="Name"
                                                {...register('name', {
                                                    required: { value: true, message: 'You need to enter your name.' },
                                                    maxLength: {
                                                        value: 30,
                                                        message: "Your name's a length of Brachiosaurus. Can't use that name!"
                                                    }
                                                })}
                                            />
                                            <input className="col2 first" type="email" placeholder="Email"
                                                {...register('email', {
                                                    required: true,
                                                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                                                })}
                                            />
                                        </div>
                                        <div className="clearfix">
                                            <textarea name="textarea" id="" cols="30" rows="5" placeholder='Your Message'
                                                {...register('message', {
                                                    required: true
                                                })}
                                            />
                                        </div>
                                        <div className="clearfix">
                                            <input type="submit"
                                                value={
                                                    sending ? 'Sending...' : 'Send'
                                                }
                                            />
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </section>

                    </div>
                </div>


                <Snackbar
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    TransitionComponent={TransitionLeft}
                    open={sent}
                    autoHideDuration={3000}
                    onClose={() => setSent(false)}
                >
                    <Alert variant='filled' severity={'success'} sx={{ width: '100%' }}>
                        Message sent successfully!
                    </Alert>
                </Snackbar>
                {/* </div > */}

            </Pagecontainer >
        </>
    )
}

export default Contact