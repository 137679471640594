import React from 'react';

const Pagecontainer = ({ children }) => {
    return (
        <>
            <div className='md:px-28 py-5 px-5'>
                {children}
            </div>
        </>
    )
}

export default Pagecontainer;