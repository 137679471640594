import { doc, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import Pagecontainer from '../Pagecontainer';
import Breadcrumb from './Breadcrumb';

const Noticedetail = () => {
    const { id } = useParams();

    const [notice, setNotice] = useState([]);
    const [date, setDate] = useState([]);
    const [image, setImage] = useState([]);

    useEffect(() => {
        try {
            const q = doc(db, `notices/${id}`);
            const unsubscribe = onSnapshot(q, (doc) => {
                setNotice(doc.data().title);
                setDate(doc.data().date);
                setImage(doc.data().image);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, [id])


    const waitTillImageLoad = (image) => {
        if (image.complete) {
            return image.naturalWidth;
        }
        return waitTillImageLoad(image);
    }

    return (
        <>

            <Breadcrumb root='Notices' current={notice} />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center w-full px-5 md:px-28 mb-10'>
                    {/* <div className='w-full'> */}
                    <h3 className='w-full flex justify-center items-center pt-3 font-vesper text-white text-center text-3xl py-1 mt-10 bg-primary'>
                        {notice}
                    </h3>
                    <h4 className='my-5'>
                        जारि मिति: {date}
                    </h4>
                    {/* </div> */}
                    <img src={image} alt="aabaddha notice" className="w-[700px] border-3 border" />
                    <a target='_blank' className='text-base font-sans text-primary' href={image} download>
                        <button className='bg-primary bg-opacity-20 p-2 px-3 rounded hover:bg-primary-d hover:bg-opacity-20 mt-10 w-full'>
                            <i className="bi bi-download mr-2"></i>
                            DOWNLOAD
                        </button>
                    </a>
                </div>
            </Pagecontainer>



        </>
    )
}

export default Noticedetail