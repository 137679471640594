import React from 'react';
import { BrowserRouter as Router, useMatch } from 'react-router-dom';
import { AuthContextProvider } from './contexts/AuthContext';
import SiteRoutes from './Routes';
import ScrollToTop from './functions/swipeToTop'
import { MobileViewContextProvider } from './contexts/MobileViewContext';
import { FormDataConsumer, FormDataProvider } from './contexts/FormDataContext';

const App = () => {

    return (
        <>
            <Router>
                <ScrollToTop />
                <MobileViewContextProvider>
                    <AuthContextProvider>
                        <FormDataProvider>
                            <SiteRoutes />
                        </FormDataProvider>
                    </AuthContextProvider>
                </MobileViewContextProvider>
            </Router>
        </>
    )
}

export default App;