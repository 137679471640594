import React from 'react'
import { Link } from 'react-router-dom'
import { useMobileView } from '../../contexts/MobileViewContext'
import Pagecontainer from '../Pagecontainer'
import './styles/wave.css'

const Primaryservices = () => {

    const { mobileView } = useMobileView()

    return (
        <>
            <div className='flex flex-col items-center justify-center mt-20 mb-10'>
                <h3 className='text-primary font-sans text-2xl font-bold uppercase'>
                    Primary Services
                </h3>
                <div className='w-[30px] bg-primary h-[4px] my-2' />
            </div>
            {
                !mobileView ? (
                    <div className='flex flex-col md:flex-row md:items-start items-center justify-center gap-20'>
                        <ServiceCard
                            title="Savings"
                            desc="Deposit money into your account and earn interest on it."
                            icon={require('../../assets/icons/savings.png')}
                        />
                        <ServiceCard
                            title="Loan"
                            desc="Get a loan from us in a fixed or variable interest rate."
                            icon={require('../../assets/icons/loan.png')}
                        />
                        <ServiceCard
                            title="Remittance"
                            desc="Send money to anyone in the world from any of our branches."
                            icon={require('../../assets/icons/remittance.png')}
                        />
                    </div>
                ) : (
                    <div className='grid grid-cols-1 px-5 gap-5'>
                        <MobileServiceCard
                            title="Savings"
                            desc="Deposit money into your account and earn interest on it."
                            icon={require('../../assets/icons/savings.png')}
                        />
                        <MobileServiceCard
                            title="Loan"
                            desc="Get a loan from us in a fixed or variable interest rate."
                            icon={require('../../assets/icons/loan.png')}
                        />
                        <MobileServiceCard
                            title="Remittance"
                            desc="Send money to anyone in the world from any of our branches."
                            icon={require('../../assets/icons/remittance.png')}
                        />
                    </div>
                )
            }
        </>
    )
}

const ServiceCard = (props) => {
    return (
        <>
            <Link to={`/services/${props.title.toLowerCase()}`}>
                <div className="wave-card border">
                    <div className='flex mt-8'>
                        <img className='w-16' src={props.icon} alt={props.title} />
                    </div>
                    <div className="wave-card-shape"></div>
                    <div className="wave-card-shape"></div>
                    <div className="wave-card-shape"></div>
                    <div className='px-5 z-20 pb-5 flex flex-col justify-center items-center gap-2'>
                        <h3 className="wave-card-title uppercase text-white text-2xl hover:underline">{props.title}</h3>
                        <p className="wave-card-subtitle text-sm text-center text-white text-opacity-70">
                            {props.desc}
                        </p>
                    </div>
                </div>
            </Link>
        </>
    )
}

const MobileServiceCard = (props) => {
    return (
        <div className='flex flex-col items-center justify-center p-5 bg-white border'>
            <img className='w-16' src={props.icon} alt={props.title} />
            <Link to={`/services/${props.title.toLowerCase()}`}>
                <h2 className='uppercase text-primary font-bold my-3 text-xlnhover:underline'>
                    {props.title}
                </h2>
            </Link>
            <p className='text-base text-center text-opacity-70'>
                {props.desc}
            </p>
        </div>
    )
}

export default Primaryservices