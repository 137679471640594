import React, { useEffect } from 'react'
import Sidebar from '../../Sidebar'
import { DataGrid } from '@mui/x-data-grid';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { db, storage } from '../../../firebase';
import Globalsnack from '../../../Globalsnack';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import "nepali-datepicker-reactjs/dist/index.css"

const Articles = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [notices, setNotices] = React.useState([]);
    const [updated, setUpdated] = React.useState(false);

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "knoArticles"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setNotices(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "knoArticles"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    const editData = async (e) => {
        const field = e.field;
        const value = e.value;
        try {
            await setDoc(doc(db, "knoArticles", e.id), {
                [field]: value,
                updatedAt: new Date().toString('YYYY-MM-dd'),
            },
                { merge: true }
            );
            setUpdated(true);

        } catch (error) {
            // console.log(error);
        }
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 500, align: 'left', editable: true, headerClassName: 'dark:text-white-rgba-6' },
        {
            field: 'link', headerName: 'Link', width: 500, align: 'left', editable: true, headerClassName: 'dark:text-white-rgba-6',
            renderCell: (data) => {
                return (
                    <>
                        {data.row.link !== '' ?
                            <a href={data.row.link} className='text-primary underline' target="_blank">{data.value}</a>
                            :
                            <span className='text-gray-500'>No Link</span>
                        }
                    </>
                )
            }
        },
        {
            field: 'image', headerName: 'File', type: 'string', width: 200, align: 'left', headerAlign: 'center', align: 'center', editable: false, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <>
                        {
                            params.row.image ?
                                <a href={params.value} target="_blank" className='underline text-primary'>
                                    View
                                </a>
                                :
                                <span className='text-gray-500'>No File</span>
                        }
                    </>
                )
            }
        },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            },
        },
    ];

    return (
        <>

            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Articles &amp; Knowledge</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>
                <p className='mb-1 text-sm font-sans'>
                    Title and date of these data can be edited. Double click on the cell to edit.
                </p>

                {
                    addMode === true ?
                        <Addind
                            changeAddMode={changeAddMode}
                        />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={notices}
                                    disableSelectionOnClick={true}
                                    onCellEditCommit={(e) => {
                                        editData(e);
                                    }}
                                />
                            </div>
                        </>
                }

                <Globalsnack type="success" text="Updated successfully." open={updated} onClose={() => setUpdated(false)} />
            </Sidebar>

        </>
    )
}

const Addind = (props) => {

    const [title, setTitle] = React.useState('');
    const [link, setLink] = React.useState('');
    const [date, setDate] = React.useState('');
    const [image, setImage] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('');
    const [uploaded, setUploaded] = React.useState(false);
    const [imgUploaded, setImgUploaded] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const [percent, setPercent] = React.useState(0);

    const [addLink, setAddLink] = React.useState(false);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleLink = (e) => {
        setLink(e.target.value);
    }

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }

    const addImageToFirebase = () => {
        if (!image) {
            alert("Please choose an image first!")
        }
        const storageRef = ref(storage, `/knowledge-articles/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setImageUrl(url);
                    setImgUploaded(true);
                });
            }
        );
    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "knoArticles"), {
            title: title,
            link: link,
            timestamp: new Date().getTime(),
            image: imageUrl,
            isLink: addLink,
        })
            .then((docRef) => {
                setUploaded(true);
                setUploading(false);
                setTitle('');
                setLink('');
                setDate('');
                setImageUrl('');
                setPercent(0);
                setImage('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        addImageToFirebase();
    }

    const handleSubmitOnLinkMode = async (e) => {
        setUploading(true);
        e.preventDefault();
        await addDoc(collection(db, "knoArticles"), {
            title: title,
            link: link,
            timestamp: new Date().getTime(),
            isLink: addLink,
        })
            .then((docRef) => {
                setUploaded(true);
                setUploading(false);
                setTitle('');
                setLink('');
                setDate('');
                setImageUrl('');
                setPercent(0);
                setImage('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    useEffect(() => {
        if (imgUploaded === true) {
            uploadDoc();
        }
    }, [imgUploaded])


    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={
                    addLink === true ?
                        handleSubmitOnLinkMode
                        :
                        handleSubmit
                } className='flex flex-col justify-center items-center gap-5'>
                    <input required value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />


                    {/* radio */}
                    <div className='w-full flex flex-col gap-5 items-start'>
                        <label className='flex items-center'>
                            <input
                                type="radio"
                                name="radio"
                                className='mr-2'
                                checked={addLink === false}
                                onChange={() => setAddLink(false)}
                            />
                            <span className='text-sm font-sans'>File</span>
                        </label>
                        <label className='flex items-center'>
                            <input
                                type="radio"
                                name="radio"
                                className='mr-2'
                                onChange={() => setAddLink(true)}
                            />
                            <span className='text-sm font-sans'>Link</span>
                        </label>
                    </div>


                    {addLink === false ?
                        <div className='w-full flex flex-col gap-5 items-start'>
                            <input type="file" accept="image/*,application/pdf" onChange={handleImage} />
                        </div>
                        :
                        <div className='w-full flex flex-col gap-5 items-start'>
                            <input required value={link} onChange={handleLink} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Link" />
                        </div>
                    }


                    <button type="submit" className='bg-primary w-1/5 text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>
                        {uploading ? 'Uploading...' : 'Upload'}
                    </button>

                </form>
            </div>
            <Globalsnack type="success" text="Notice released successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}

export default Articles