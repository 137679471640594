import { DataGrid } from '@mui/x-data-grid';
import { addDoc, arrayUnion, collection, deleteDoc, doc, onSnapshot, orderBy, query } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { db, storage } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import Sidebar from '../Sidebar'

const Addnews = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [news, setNews] = React.useState([]);

    const navigate = useNavigate()

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "news"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setNews(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "news"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 500, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6' },
        // { field: 'description', headerName: 'Description', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        { field: 'date', headerName: 'Date', type: 'string', width: 100, align: 'left', headerAlign: 'left', editable: false, sortable: true, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        {
            field: 'image', headerName: 'Cover Image', type: 'string', width: 200, align: 'left', headerAlign: 'center', align: 'center', editable: false, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.value} target="_blank" className='underline text-blue-500'>
                        View
                    </a>
                )
            }
        },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-3 rounded-full'>
                            Delete
                        </button>
                        <button
                            onClick={() => {
                                navigate(`/admin/news/edit/${params.id}`)
                            }
                            }
                            className='bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-3 rounded-full'>
                            Edit
                        </button>
                    </div>
                )
            },
        },
    ];


    return (
        <>
            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>News</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>

                {
                    addMode === true ?
                        <Addind changeAddMode={changeAddMode} />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={news}
                                    disableSelectionOnClick={true}
                                />
                            </div>
                        </>
                }
            </Sidebar>
        </>
    )
}

const Addind = (props) => {
    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [date, setDate] = React.useState('');
    const [image, setImage] = React.useState('');
    const [additionalImgs, setAdditionalImgs] = React.useState([]);
    const [imageUrl, setImageUrl] = React.useState('');
    const [additionalImgUrl, setAdditionalImgUrl] = React.useState([]);
    const [uploaded, setUploaded] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);
    const [imgUploaded, setImgUploaded] = React.useState(false);
    const [mulImgUploaded, setMulImgUploaded] = React.useState(false);

    const [percent, setPercent] = React.useState(0);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }

    const addImageToFirebase = () => {
        if (!image) {
            alert("Cover photo is needed!")
        }
        const storageRef = ref(storage, `/news/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setImageUrl(url);
                    setImgUploaded(true);
                });
            }
        );
    }

    const addMultipleImagesToFirebase = () => {
        if (!additionalImgs.length) {
            alert("Additional images are needed!")
        }
        const urls = [];
        for (let i = 0; i < additionalImgs.length; i++) {
            const storageRef = ref(storage, `/news/${additionalImgs[i].name}`)
            const uploadTask = uploadBytesResumable(storageRef, additionalImgs[i]);
            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    const percent = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    setPercent(percent);
                }
                , (err) => console.log(err),
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        urls.push(url);
                    }).catch(err => console.log(err))
                }
            );
        }
        setAdditionalImgUrl(urls);
        setMulImgUploaded(true);
    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "news"), {
            title: title,
            content: description,
            date: new Date().toLocaleDateString(),
            timestamp: new Date().getTime(),
            image: imageUrl,
            additionalImages: additionalImgUrl,
        })
            .then((docRef) => {
                setUploaded(true);
                setUploading(false);
                setTitle('');
                setDescription('');
                setDate('');
                setImageUrl('');
                setPercent(0);
                setImage('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        addImageToFirebase();
        addMultipleImagesToFirebase();
    }

    useEffect(() => {
        if (imgUploaded && mulImgUploaded === true) {
            uploadDoc();
        }
    }, [imgUploaded, mulImgUploaded])


    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
                    <input required value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />
                    <textarea value={description} onChange={handleDescription} rows={10} type="text" className="resize-none bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Description" />
                    {/* <input required value={date} onChange={handleDate} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Date" /> */}
                    <label htmlFor="file-input" className="block text-gray-700 text-sm font-bold">
                        Cover photo
                    </label>
                    <input type="file" accept="image/*" onChange={handleImage} />
                    {
                        percent > 0 &&
                        <div className="flex justify-center">
                            <div className="w-full flex items-center gap-5">
                                <progress className="bg-gray-200" value={percent} max="100" />
                                {
                                    percent === 100 ?
                                        <p className="text-center text-primary font-sans">Upload success!</p>
                                        :
                                        <p className="text-center text-primary font-sans">{percent}</p>
                                }
                            </div>
                        </div>

                    }

                    <label htmlFor="file-input" className="block text-gray-700 text-sm font-bold">
                        Additional images
                    </label>
                    <input type="file" accept="image/*" multiple onChange={(e) => setAdditionalImgs(e.target.files)} />
                    <button type="submit" className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>
                        {
                            uploading === true ?
                                'Uploading...'
                                :
                                'Upload'
                        }
                    </button>

                </form>
            </div>
            <Globalsnack type="success" text="News released successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}

export default Addnews