import { doc, onSnapshot, setDoc } from 'firebase/firestore';
import React from 'react'
import { db } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import Sidebar from '../Sidebar';

const AddFinData = () => {
    const [finData, setFinData] = React.useState({
        year: '',
        kulKosh: '',
        sharePunji: '',
        bachatNikshep: '',
        bahyaKarja: '',
        bank: '',
        rin: '',
        isthir: '',
        sadasya: '',
        jagedaKosh: '',
    });

    const [updated, setUpdated] = React.useState(false);

    const [yearEdit, setYearEdit] = React.useState(false);
    const [kulKoshEdit, setKulKoshEdit] = React.useState(false);
    const [sharePunjiEdit, setSharePunjiEdit] = React.useState(false);
    const [bachatNikshepEdit, setBachatNikshepEdit] = React.useState(false);
    const [bahyaKarjaEdit, setBahyaKarjaEdit] = React.useState(false);
    const [bankEdit, setBankEdit] = React.useState(false);
    const [rinEdit, setRinEdit] = React.useState(false);
    const [isthirEdit, setIsthirEdit] = React.useState(false);
    const [sadasyaEdit, setSadasyaEdit] = React.useState(false);
    const [jagedaKoshEdit, setJagedaKoshEdit] = React.useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFinData({
            ...finData,
            [name]: value
        })
    }

    const getData = async () => {
        const q = doc(db, 'siteData', 'finData');
        const unsubscribe = onSnapshot(q, (doc) => {
            setFinData(doc.data());
        }
            , [])
    }

    React.useEffect(() => {
        getData();
    }, [])

    return (
        <Sidebar>
            <div className='mb-5 flex items-center gap-5'>
                <h3 className='text-2xl font-bold'>Fin Data</h3>
            </div>
            <div>
                <div>
                    <div className='flex flex-col gap-5'>
                        <div className='flex flex-col gap-2'>
                            <label htmlFor="year">Data Upto</label>
                            {yearEdit === false ?
                                <h3>
                                    {finData.year === '' ? 'N/A' : finData.year}
                                    <i onClick={() => setYearEdit(!yearEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name="year"
                                        value={finData.year}
                                        placeholder='YYYY/MM/DD'
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setYearEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                year: finData.year,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setYearEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="sharePunji">शेयर पुंजी</label>
                            {sharePunjiEdit === false ?
                                <h3>
                                    {finData.sharePunji === '' ? 'N/A' : finData.sharePunji}
                                    <i onClick={() => setSharePunjiEdit(!sharePunjiEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='sharePunji'
                                        value={finData.sharePunji}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setSharePunjiEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                sharePunji: finData.sharePunji,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setSharePunjiEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="bachatNikshep">जगेडा कोष</label>
                            {jagedaKoshEdit === false ?
                                <h3>
                                    {finData.jagedaKosh === '' ? 'N/A' : finData.jagedaKosh}
                                    <i onClick={() => setJagedaKoshEdit(!jagedaKoshEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name="jagedaKosh"
                                        value={finData.jagedaKosh}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setJagedaKoshEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                jagedaKosh: finData.jagedaKosh,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setJagedaKoshEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="kulKosh">कुल कोष</label>
                            {kulKoshEdit === false ?
                                <h3>
                                    {finData.kulKosh === '' ? 'N/A' : finData.kulKosh}
                                    <i onClick={() => setKulKoshEdit(!kulKoshEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name="kulKosh"
                                        value={finData.kulKosh}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setKulKoshEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                kulKosh: finData.kulKosh,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setKulKoshEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="bachatNikshep">बचत/निक्षेप</label>
                            {bachatNikshepEdit === false ?
                                <h3>
                                    {finData.bachatNikshep === '' ? 'N/A' : finData.bachatNikshep}
                                    <i onClick={() => setBachatNikshepEdit(!bachatNikshepEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='bachatNikshep'
                                        value={finData.bachatNikshep}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setBachatNikshepEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                bachatNikshep: finData.bachatNikshep,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setBachatNikshepEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="bahyaKarja">बाह्य कर्जा</label>
                            {bahyaKarjaEdit === false ?
                                <h3>
                                    {finData.bahyaKarja === '' ? 'N/A' : finData.bahyaKarja}
                                    <i onClick={() => setBahyaKarjaEdit(!bahyaKarjaEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='bahyaKarja'
                                        value={finData.bahyaKarja}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setBahyaKarjaEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                bahyaKarja: finData.bahyaKarja,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setBahyaKarjaEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="bank">बैंक</label>
                            {bankEdit === false ?
                                <h3>
                                    {finData.bank === '' ? 'N/A' : finData.bank}
                                    <i onClick={() => setBankEdit(!bankEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='bank'
                                        value={finData.bank}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setBankEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                bank: finData.bank,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setBankEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="rin">ऋण लगानी</label>
                            {rinEdit === false ?
                                <h3>
                                    {finData.rin === '' ? 'N/A' : finData.rin}
                                    <i onClick={() => setRinEdit(!rinEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='rin'
                                        value={finData.rin}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setRinEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                rin: finData.rin,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setRinEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="isthir">स्थिर सम्पति</label>
                            {isthirEdit === false ?
                                <h3>
                                    {finData.isthir === '' ? 'N/A' : finData.isthir}
                                    <i onClick={() => setIsthirEdit(!isthirEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='isthir'
                                        value={finData.isthir}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setIsthirEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                isthir: finData.isthir,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setIsthirEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                            <label htmlFor="sadasya">सदस्य संख्या</label>
                            {sadasyaEdit === false ?
                                <h3>
                                    {finData.sadasya === '' ? 'N/A' : finData.sadasya}
                                    <i onClick={() => setSadasyaEdit(!sadasyaEdit)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                                </h3>
                                :
                                <div className='flex gap-2'>
                                    <input
                                        type="text"
                                        name='sadasya'
                                        value={finData.sadasya}
                                        placeholder=''
                                        onChange={handleChange}
                                        className='border border-gray-300 p-2 rounded-md'
                                    />
                                    <button
                                        onClick={async () => {
                                            setSadasyaEdit(false);
                                            await setDoc(doc(db, "siteData", "finData"), {
                                                sadasya: finData.sadasya,
                                            }, { merge: true });
                                            setUpdated(true);
                                        }
                                        }
                                        className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                                        Save
                                    </button>
                                    <button onClick={() => setSadasyaEdit(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                                        Cancel
                                    </button>
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
            <Globalsnack open={updated} setOpen={setUpdated} text='Updated' onClose={() => setUpdated(false)} />
        </Sidebar >
    )
}

export default AddFinData