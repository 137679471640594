import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { db } from '../../firebase';
import Pagecontainer from '../Pagecontainer'
import './styles/newscard.css'

const News = (props) => {
    const [news, setNews] = React.useState([]);
    const [newsId, setNewsId] = React.useState([]);

    useEffect(() => {
        try {
            const q = query(collection(db, "news"), orderBy("timestamp", "desc"), limit(6));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setNewsId(querySnapshot.docs.map(doc => doc.id));
                setNews(querySnapshot.docs.map(doc => doc.data()));
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);

    return (
        <>
            <Pagecontainer>
                {
                    props.title === true &&
                    <div className='flex flex-col items-center justify-center mt-20 mb-10'>
                        <h3 className='text-primary font-sans text-2xl font-bold uppercase'>
                            News &amp; Events
                        </h3>
                        <div className='w-[30px] bg-primary h-[4px] my-2' />
                    </div>
                }
                <div>
                    <section className="text-gray-600 body-font flex flex-col items-center">
                        <div className="container px-5 mx-auto max-w-7x1">
                            <div className="flex flex-wrap -m-4 content-wrapper">
                                {news.length > 0 ?
                                    <>
                                        <div className="grid md:grid-cols-3 gap-5">

                                            {
                                                news.map((news, index) => (
                                                    <div className="news-card">
                                                        <Link to={`/news/${newsId[index]}`} className="news-card__card-link"></Link>
                                                        <img src={news.image} alt="" className="news-card__image" />
                                                        <div className="news-card__text-wrapper">
                                                            <h2 className="news-card__title">
                                                                {news.title && news.title.length > 50 ? news.title.substring(0, 50) + '...' : news.title}
                                                            </h2>
                                                            <div className="news-card__post-date">{news.date}</div>
                                                            <div className="news-card__details-wrapper">
                                                                <p className="news-card__excerpt">
                                                                    {
                                                                        // trim the content to 100 characters
                                                                        news.content && news.content.length > 100 ? news.content.substring(0, 50) + '...' : news.content
                                                                    }
                                                                </p>
                                                                <Link to={`/news/${newsId[index]}`} className="news-card__read-more">Read more <i className="fas fa-long-arrow-alt-right"></i></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))

                                            }
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className='flex flex-col items-center justify-center'>
                                            <h3>
                                                No news found.
                                            </h3>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <button className='bg-primary p-1 px-3 rounded hover:bg-primary-d text-white uppercase mt-5'>
                            <Link to="/news">
                                View all
                            </Link>
                        </button>
                    </section>
                </div>
            </Pagecontainer>
        </>
    )
}

export default News