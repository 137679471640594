import React, { createContext, useEffect, useState } from 'react';

const FormDataContext = createContext();

const FormDataProvider = ({ children }) => {

    const [data, setData] = useState([]);

    return (
        <FormDataContext.Provider value={{ data, setData }}>
            {children}
        </FormDataContext.Provider>
    );
};


export { FormDataProvider, FormDataContext };