import React from 'react'
import Pagecontainer from '../../Pagecontainer'
import useDocumentTitle from '../../../functions/useDocumentTitle'
import Breadcrumb from '../Breadcrumb'

const Remittance = () => {
    useDocumentTitle('Remittance - Aabaddha SACCOS Ltd.')
    return (
        <>
            <Breadcrumb root='Services' current='Remittance' />
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    {/* <img className='w-1/6 mb-14' src={ContactImage} alt='Contact' /> */}
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        Remittance
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                    <p className='font-sans text-base font-medium text-black text-opacity-70 text-center'>
                        We provide remittance services to our members with below listed remit options.
                    </p>
                </div>

                <div className='grid grid-cols-2 md:grid-cols-4 gap-3'>
                    <RemitImage image={require('../../../assets/remits/ime.png')} />
                    <RemitImage image={require('../../../assets/remits/city.png')} />
                    <RemitImage image={require('../../../assets/remits/himal.png')} />
                    <RemitImage image={require('../../../assets/remits/western.png')} />
                    <RemitImage image={require('../../../assets/remits/samsara.png')} />
                    <RemitImage image={require('../../../assets/remits/janata.png')} />
                    <RemitImage image={require('../../../assets/remits/moneygram.png')} />
                    <RemitImage image={require('../../../assets/remits/everest.jpg')} />
                    <RemitImage image={require('../../../assets/remits/world.jpeg')} />
                    <RemitImage image={require('../../../assets/remits/ipay.png')} />
                    <RemitImage image={require('../../../assets/remits/transfast.jpg')} />
                    <RemitImage image={require('../../../assets/remits/sidd.jpg')} />
                </div>
                <p className='text-center mt-10 text-primary'>
                    ... and many more.
                </p>
            </Pagecontainer>
        </>
    )
}

const RemitImage = (props) => {
    return (
        <>
            <div className='flex items-center justify-center border h-48'>
                <img className='w-1/2' src={props.image} alt='Remittance' />
            </div>
        </>
    )
}

export default Remittance