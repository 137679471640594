import React from 'react'
import Pagecontainer from '../Pagecontainer'

const Objectives = () => {
    return (
        <>
            <Pagecontainer>
                <div className='flex flex-col items-center justify-center mb-10'>
                    <h3 className='font-sans text-2xl font-bold text-center uppercase text-primary'>
                        Vision {' '}
                        <p className='mt-2 text-base font-medium text-red-700 font-vesper'>
                            परिकल्पना
                        </p>
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-5' />

                    <p className='text-base font-medium text-center text-black font-vesper text-opacity-70'>
                        “जन्म देखि मृत्युसम्म आबदुको साथ
                        समाजको समृद्धिमा सधैं सबै माझ"
                    </p>
                </div>

                <div className='flex flex-col items-center justify-center mb-10'>
                    <h3 className='font-sans text-2xl font-bold text-center uppercase text-primary'>
                        Mission {' '}
                        <p className='mt-2 text-base font-medium text-red-700 font-vesper'>
                            ध्येय
                        </p>
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-5' />

                    <p className='text-base font-medium text-center text-black font-vesper text-opacity-70'>
                        दैनिक जनजीवनमा आधारीत आर्थिक तथा सामाजिक गतिविधिमा सहज र सरल रूपमा विश्वसनिय र प्रविधियुक्त वित्तीय पहुँच पुऱ्याउने
                    </p>
                </div>

                <div className='flex flex-col items-center justify-center mb-10'>
                    <h3 className='font-sans text-2xl font-bold text-center uppercase text-primary'>
                        Goal {' '}
                        <p className='mt-2 text-base font-medium text-red-700 font-vesper'>
                            लक्ष्य
                        </p>
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-5' />

                    <ul className='text-base font-medium text-center text-black font-vesper text-opacity-70'>
                        <li>नीति विधि, प्रविधी र दक्ष जनशक्तिको विकासलाई जोड दिँदै, हरेक टोलटोलमा संस्थाको पहुँच पुऱ्याउने,</li>
                        <li>सदस्यको दैनिक जीवनमा आधारित वित्तीय तथा गैर वित्तीय सेवामा विविधिकरण गर्दै प्रतिष्पर्धात्मक सेवा प्रवाह गर्ने,</li>
                        <li>सचेतना कार्यक्रम, सहकारी शिक्षा मार्फत बचत गर्ने बानीलाई प्रोत्साहन गर्ने, सिपमुलक तालिम मार्फत सदस्यको आयमा बृद्धि गर्ने,</li>
                        <li>सदस्यहरूको विभिन्न उमेरकालअनुसारका सेवासुविधाको विकास तथा सञ्चालन गर्ने</li>
                    </ul>
                </div>
            </Pagecontainer>
        </>
    )
}

export default Objectives