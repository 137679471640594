import React, { useEffect, useRef, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../../assets/logo-new.png';
import { useAuth } from '../../contexts/AuthContext';
import MobileNav from './MobileNav';
import Navbar from './Navbar';
import { useMobileView } from '../../contexts/MobileViewContext';

const Header = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    const handleOpen = () => {
        setIsOpen(!isOpen);
    }

    const { currentUser, logout } = useAuth();

    const handleLogout = () => {
        if (window.confirm('Are you sure you want to logout?')) {
            logout();
        }
    }

    let menuMask;

    menuMask = (
        <div onClick={() => setIsOpen(false)} className='fixed top-0 left-0 z-50 w-full h-full bg-black bg-opacity-60' />
    )

    const [show, setShow] = useState(true);

    const { mobileView } = useMobileView();
    return (
        <>

            {/* {show === true &&
                <div className='bg-[#E53337] px-2 md:px-0 gap-2 md:gap-0 py-2 top-0 z-50 flex items-center justify-center selection:bg-white selection:text-[#E53337]'>
                    <p className='text-xs text-center text-white md:text-sm'>
                        This site is currently under active development by LinkBinary and is not yet ready for public use. You might find placeholder data, missing features, and bugs. (Build ID LB-W68-08)
                    </p>
                    <i onClick={() => setShow(false)} className='right-0 mr-5 text-center text-white cursor-pointer bi bi-x-circle md:absolute' />
                </div>
            } */}
            <div className='flex items-center justify-center w-full h-8 px-5 py-1 border-b md:justify-between md:px-28'>
                <div className='flex gap-3'>
                    <a target='_blank' className='font-sans text-xs text-black text-opacity-50 hover:underline' href='https://goo.gl/maps/9Yc2F4sJk2ELxUow5'>
                        <i className="mr-2 bi bi-geo-alt-fill"></i>
                    </a>
                    <a target='_blank' className='font-sans text-xs text-black text-opacity-50 hover:underline' href='https://facebook.com/aabaddhasaccos'>
                        <i className="mr-2 bi bi-facebook"></i>
                    </a>
                    <a className='font-sans text-xs text-black text-opacity-50 hover:underline' href='mailto:info@aabaddha.coop.np'>
                        <i className="mr-2 bi bi-envelope-paper-fill"></i>
                        {!mobileView &&
                            'info@aabaddha.coop.np'
                        }
                    </a>
                    <a className='font-sans text-xs text-black text-opacity-50 hover:underline' href='tel:023-535395'>
                        <i className="mr-2 bi bi-telephone-fill"></i>
                        {!mobileView &&
                            '023-535395'
                        }
                    </a>
                    <Link to='/emi-calculator' className='font-sans text-xs text-black text-opacity-50 hover:underline'>
                        <i className="mr-2 bi bi-calculator"></i>
                        {!mobileView &&
                            'EMI Calculator'
                        }
                    </Link>
                    <Link to='/career' className='font-sans text-xs text-black text-opacity-50 hover:underline'>
                        <i className="mr-2 bi bi-briefcase-fill"></i>
                        {!mobileView &&
                            'Career'
                        }
                    </Link>
                    <Link to='/downloads' className='font-sans text-xs text-black text-opacity-50 hover:underline'>
                        <i className="mr-2 bi bi-download"></i>
                        {!mobileView &&
                            'Downloads'
                        }
                    </Link>
                    {/* <a className='font-sans text-xs text-black text-opacity-50 hover:underline' href='tel:023-536705'>
                        <i className="mr-2 bi bi-telephone-plus-fill"></i>
                        023-536705
                    </a> */}

                </div>
                <div className='hidden md:block'>
                    <ul className='flex gap-2'>
                        {
                            !currentUser &&
                            <li className='font-sans text-xs text-black text-opacity-50 underline'>
                                <Link to='/admin/login'>Login</Link>
                            </li>
                        }
                        <li className='font-sans text-xs text-black text-opacity-50 underline'>
                            <Link to='/admin'>
                                Admin Portal
                            </Link>
                        </li>
                        {
                            currentUser &&
                            <li className='font-sans text-xs text-black text-opacity-50 underline cursor-pointer'>
                                <a onClick={handleLogout}>Logout</a>
                            </li>
                        }
                    </ul>
                </div>
            </div>


            <div className={`${mobileView === true ? 'top-0 sticky border-b bg-opacity-90 backdrop-blur-sm' : ''} md:border-b  bg-white z-50`}>
                <div className='flex items-center gap-5 px-5 py-3 md:px-28 md:flex-row md:justify-between'>
                    <div className='flex items-center justify-center gap-5 md:justify-start'>
                        <Link to='/'>
                            <img src={Logo} className='md:w-96 w-96' />
                        </Link>
                    </div>

                    {
                        mobileView === true &&
                        <MobileNav />
                    }

                    <div className='items-center hidden font-sans md:flex'>
                        <img src={require('../../assets/rainbow.png')} className='absolute hidden -ml-16 md:w-32 md:block' />

                        <div>
                            <h1 className='text-right text-md'>
                                Head Office
                            </h1>
                            <p className='text-xs text-right text-black text-opacity-70'>
                                <a target='_none' href="https://www.google.com/maps/place/26%C2%B038'21.7%22N+87%C2%B059'45.1%22E/@26.639374,87.9953051,197m/data=!3m2!1e3!4b1!4m5!3m4!1s0x0:0x3410dc2c6c9c2358!8m2!3d26.6393728!4d87.9958523" className='underline'>JXQW+P8X</a>, Birtamode 05<br />
                                Jhapa 57204, Pradesh 01, Nepal
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {
                mobileView === false &&
                <div className='sticky top-0 z-50 flex-col bg-white border-b md:flex bg-opacity-80 backdrop-blur-sm'>
                    <Navbar />
                </div>
            }

        </>
    )
}
export default Header