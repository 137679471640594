import { collection, doc, onSnapshot, query } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../../firebase';
import Pagecontainer from '../../Pagecontainer';
import LoanImage from '../../../assets/illustrations/loan.svg';
import useDocumentTitle from '../../../functions/useDocumentTitle';
import Breadcrumb from '../Breadcrumb';

const Kramik = () => {
    useDocumentTitle('Sadharan - Aabaddha SACCOS Ltd.');
    const [loans, setLoans] = useState([]);

    const { lid } = useParams();

    useEffect(() => {
        try {
            const q = query(doc(db, 'loan', lid));
            const unsubscribe = onSnapshot(q, (doc) => {
                setLoans(doc.data());
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, [lid]);

    return (
        <>
            <Breadcrumb root='Loan' current={loans.title} />
            <Pagecontainer>
                <div className='flex items-center flex-col my-10'>
                    <img src={LoanImage} alt='loans' className='w-1/6 my-5' />
                    <h3 className='text-primary font-karma text-3xl font-bold uppercase'>
                        {loans.title}
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-8' />

                    <p className='font-vesper text-black text-opacity-80 text-center'>
                        {loans.description}
                    </p>
                </div>


                <div className="overflow-x-auto relative border">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead className="border-b text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                                <th scope="col" className="py-3 px-6">
                                    Product name
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Type
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Category
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Duration
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Interest Rate
                                </th>
                                <th scope="col" className="py-3 px-6">
                                    Loan Limit
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white">
                                <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                                    {loans.title}
                                </th>
                                <td className="py-4 px-6">
                                    Loan
                                </td>
                                <td className="py-4 px-6">
                                    Lending
                                </td>
                                <td className="py-4 px-6">
                                    {loans.duration !== '' ? loans.duration : 'N/A'}
                                </td>
                                <td className="py-4 px-6">
                                    {loans.interestrate !== '' ? loans.interestrate + '%' : 'N/A'}
                                </td>
                                <td className="py-4 px-6">
                                    {loans.limit !== '' ? loans.limit : 'N/A'}
                                </td>
                            </tr>

                        </tbody>
                    </table>
                </div>


            </Pagecontainer>
        </>
    )
}

export default Kramik