import React, { useEffect } from 'react';
import { Link, Navigate, Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import Attendance from './admin/components/Attendance';
import Dashboard from './admin/components/Dashboard';
import Addnotices from './admin/components/Addnotices';
import Addnews from './admin/components/Addnews';
import Login from './Login';
import PrivateRoutes from './ProtectedRoute';
import Header from './site/components/Header';
import Home from './site/Home';
import Notfound from './site/Notfound';
import Services from './site/Services';
import About from './site/About';
import Contact from './site/Contact';
import Noticedetail from './site/components/Noticedetail';
import Notices from './site/Notices';
import Gallery from './site/Gallery';
import Savings from './site/components/services/Savings';
import Loan from './site/components/services/Loan';
import Remittance from './site/components/services/Remittance';
import Footer from './site/components/Footer';

import Sadharan from './site/components/services/savings/Sadharan';
import Kramik from './site/components/services/savings/Kramik';
import Aabadhik from './site/components/services/savings/Aabadhik';
import AdminServices from './admin/components/Services';
import SavingAdmin from './admin/components/SavingAdmin';
import LoanAdmin from './admin/components/LoanAdmin';
import Arjundhara from './site/components/branches/Arjundhara';
import NewsPage from './site/NewsPage';
import Social from './site/components/services/Social';
import NewsDetail from './site/components/NewsDetail';
import AddSliderImage from './admin/components/AddSliderImage';
import Introduction from './site/components/about/Introduction';
import Presidentmsg from './site/components/Presidentmsg';
import Organizationalstruct from './site/components/Organizationalstruct';
import AddFinData from './admin/components/AddFinData';
import Editnews from './admin/components/Editnews';
import Actpolicies from './site/components/knowledge/Actpolicies';
import Articlesandknowledge from './site/components/knowledge/Articlesandknowledge';
import Ourteam from './site/Ourteam';
import CoopKnowledge from './admin/components/CoopKnowledge';
import Acts from './admin/components/CoopKnowledge/Acts';
import Articles from './admin/components/CoopKnowledge/Articles';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Button } from 'flowbite-react';
import { collection, limit, onSnapshot, orderBy, query, QuerySnapshot } from 'firebase/firestore';
import { db } from './firebase';
import Emicalc from './site/Emicalc';
import Addgallery from './admin/components/Addgallery';
import Vacancynotice from './admin/components/Vacancynotice';
import Career from './site/Career';
import Downloads from './site/Downloads';
import Updatekom from './site/Updatekom';
import KomAdmin from './admin/components/KomAdmin';
import SavingsPage from './site/SavingsPage';
import LoanPage from './site/LoanPage';
import RemittancePage from './site/RemittancePage';

const SiteRoutes = () => {
    const match = useMatch('/admin/*');
    const matchLogin = useMatch('/admin/login');

    const DynamicHeader = () => {
        if (!match) {
            return <Header />
        } else if (matchLogin) {
            return <Header />
        }
        return null;
    }

    const DynamicFooter = () => {
        if (!match) {
            return <Footer />
        } else if (matchLogin) {
            return <Footer />
        }
        return null;
    }

    const [open, setOpen] = React.useState(true);


    const LatestNoticePopup = () => {

        const [latestNotice, setLatestNotice] = React.useState({});
        const [latestNoticeId, setLatestNoticeId] = React.useState('');

        useEffect(() => {
            try {
                const q = query(collection(db, "notices"), orderBy("timestamp", "desc"));
                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    var data = [];
                    var elements = {};

                    querySnapshot.forEach((doc) => {
                        elements = {
                            id: doc.id,
                            ...doc.data()
                        }
                        data.push(elements);
                    });
                    setLatestNotice(data[0]);
                    setLatestNoticeId(data[0].id);
                }
                    , [])
            } catch (e) {
                // console.log(e)
            }
        }, []);


        const handleClose = () => {
            setOpen(false);
        }

        return (
            <>

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <>
                        {
                            latestNotice &&
                            <>
                                <DialogTitle className='text-center' id="alert-dialog-title">
                                    सूचना
                                </DialogTitle>

                                <DialogContent className='flex flex-col items-center'>

                                    <>
                                        <DialogContentText id="alert-dialog-description">
                                            {latestNotice.title}
                                        </DialogContentText>
                                        <img src={latestNotice.image} alt="" className='w-[70%]' />
                                    </>

                                </DialogContent>
                                {/* <div className='flex flex-col items-center mt-2'>
                                    <p className='text-center'>अधिक जानकारीको लागि फेसबुक पेजमा जानुहोस्</p>
                                    <iframe src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FAabaddhasahakari%2F&tabs&width=340&height=130&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=2896189824017389" width="340" height="120" style={{ border: 'none', overflow: 'hidden' }} scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                                </div> */}
                                <DialogActions className='flex justify-center w-full gap-3'>
                                    <button className='px-2 py-1 text-white rounded bg-primary' onClick={handleClose}>
                                        <Link to={`/notices/${latestNoticeId}`}>
                                            View Notice
                                        </Link>
                                    </button>
                                    <button className='px-2 py-1 text-white bg-red-500 rounded' onClick={handleClose}>CLOSE</button>
                                </DialogActions>
                            </>
                        }
                    </>
                </Dialog>

            </>
        )
    }




    return (
        <>
            <DynamicHeader />
            {
                open === true && <LatestNoticePopup />
            }
            <div className='overflow-x-hidden selection:bg-primary selection:text-white'>
                <Routes>
                    <Route exact path="/" element={<Home />} />

                    <Route path="/services/savings/sadharan/:ssid" element={<Sadharan />} />
                    <Route path="/services/savings/kramik/:skid" element={<Kramik />} />
                    <Route path="/services/savings/aabadhik" element={<Aabadhik />} />

                    <Route path="/services/loan/:lid" element={<Loan />} />
                    <Route path="/services/remittance" element={<Remittance />} />
                    <Route path="/services/social" element={<Social />} />

                    <Route path="/knowledge/acts-policies" element={<Actpolicies />} />
                    <Route path="/knowledge/articles" element={<Articlesandknowledge />} />

                    <Route path="/emi-calculator" element={<Emicalc />} />
                    <Route path="/downloads" element={<Downloads />} />

                    <Route path="/about" element={<About />} />
                    <Route path="/about/introduction" element={<Introduction />} />
                    <Route path="/about/presidents-message" element={<Presidentmsg />} />
                    <Route path="/about/organizational-structure" element={<Organizationalstruct />} />
                    <Route path="/about/team" element={<Ourteam />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/branches/arjundhara" element={<Arjundhara />} />
                    <Route path="/notices" element={<Notices />} />
                    <Route path="/notices/:id" element={<Noticedetail />} />
                    <Route path="/career" element={<Career />} />
                    <Route path="/career/:cid" element={<Noticedetail />} />
                    <Route path="/gallery" element={<Gallery />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/news/:nid" element={<NewsDetail />} />
                    <Route path="/update-kom" element={<Updatekom />} />
                    <Route path="/services/savings" element={<SavingsPage />} />
                    <Route path="/services/loan" element={<LoanPage />} />
                    <Route path="/services/remittance" element={<RemittancePage />} />

                    <Route path="/admin/login" element={<Login />} />
                    {/* <Route path="/admin" element={<PrivateRoutes><Dashboard /></PrivateRoutes>} /> */}
                    <Route path="/admin" element={<PrivateRoutes><Navigate to='/admin/notices' /></PrivateRoutes>} />
                    <Route path="/admin/attendance" element={<PrivateRoutes><Attendance /></PrivateRoutes>} />
                    <Route path="/admin/notices" element={<PrivateRoutes><Addnotices /></PrivateRoutes>} />
                    <Route path="/admin/vacancy" element={<PrivateRoutes><Vacancynotice /></PrivateRoutes>} />
                    <Route path="/admin/news" element={<PrivateRoutes><Addnews /></PrivateRoutes>} />
                    <Route path="/admin/news/edit/:neid" element={<PrivateRoutes><Editnews /></PrivateRoutes>} />
                    <Route path="/admin/slider-images" element={<PrivateRoutes><AddSliderImage /></PrivateRoutes>} />
                    <Route path="/admin/services" element={<PrivateRoutes><AdminServices /></PrivateRoutes>} />
                    <Route path="/admin/services/savings" element={<PrivateRoutes><SavingAdmin /></PrivateRoutes>} />
                    <Route path="/admin/services/loan" element={<PrivateRoutes><LoanAdmin /></PrivateRoutes>} />
                    <Route path="/admin/findata" element={<PrivateRoutes><AddFinData /></PrivateRoutes>} />
                    <Route path="/admin/knowledge" element={<PrivateRoutes><CoopKnowledge /></PrivateRoutes>} />
                    <Route path="/admin/knowledge/acts-policies" element={<PrivateRoutes><Acts /></PrivateRoutes>} />
                    <Route path="/admin/knowledge/articles" element={<PrivateRoutes><Articles /></PrivateRoutes>} />
                    <Route path="/admin/gallery" element={<PrivateRoutes><Addgallery /></PrivateRoutes>} />
                    <Route path="/admin/kom" element={<PrivateRoutes><KomAdmin /></PrivateRoutes>} />



                    <Route path="*" element={<Notfound />} />
                </Routes>
                <DynamicFooter />
            </div>

        </>
    )
}

export default SiteRoutes;