import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { HomeOutlined } from '@mui/icons-material';
import { collection, onSnapshot, query, where } from 'firebase/firestore'
import { Link } from 'react-router-dom';
import { db } from '../../firebase';

export default function MobileNav() {
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };


    const [sadharan, setSadharan] = React.useState([])
    const [kramik, setKramik] = React.useState([])
    const [loan, setLoan] = React.useState([])

    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'sadharan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setSadharan(data);
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);

    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'), where('acType', '==', 'kramik'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setKramik(data);
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);


    useEffect(() => {
        try {
            const q = query(collection(db, 'loan'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setLoan(data);
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);


    const [openSavings, setOpenSavings] = React.useState(false);
    const [expandSavings, setExpandSavings] = React.useState(false);
    const [openSadharan, setOpenShadharan] = React.useState(false);
    const [openKramik, setOpenKramik] = React.useState(false);
    const [openLoan, setOpenLoan] = React.useState(false);
    const [openBranch, setOpenBranch] = React.useState(false);
    const [openAbout, setOpenAbout] = React.useState(false);
    const [openCoop, setOpenCoop] = React.useState(false);

    const list = (anchor) => (
        <>
            <div className='pt-5 bg-white w-[350px] h-full flex flex-col items-center'>
                <div className='w-full'>
                    <i
                        className="bi bi-x-circle ml-2 text-2xl text-primary"
                        onClick={toggleDrawer(anchor, false)}
                    ></i>
                </div>
                <div className='w-full'>
                    <ul className='mt-5 border-b border-black border-opacity-10'>
                        <MenuItem title='Home' to='/' />

                        <li onClick={() => setOpenAbout(!openAbout)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                            <span className='ml-2'>
                                About
                            </span>
                            <i className='bi bi-chevron-down mr-4' />
                        </li>
                        {
                            openAbout &&
                            <>
                                <ul className='pl-5 flex flex-col '>
                                    <MenuItem title='Introduction & Profile' to='/about/introduction' />
                                    <MenuItem title="President's Message" to='/about/presidents-message' />
                                    <MenuItem title="Organizational Structure" to='/about/organizational-structure' />
                                    <MenuItem title="Our Team" to='/about/team' />
                                </ul>
                            </>
                        }


                        <li onClick={() => setOpenSavings(!openSavings)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                            <span className='ml-2'>
                                Services
                            </span>
                            <i className='bi bi-chevron-down mr-4' />
                        </li>
                        {
                            openSavings &&
                            <>
                                <ul className='pl-5 flex flex-col'>
                                    <li onClick={() => setExpandSavings(!expandSavings)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                                        <span className='ml-2'>
                                            Savings
                                        </span>
                                        <i className='bi bi-chevron-down mr-4' />
                                    </li>

                                    {
                                        openSavings && expandSavings &&
                                        <>
                                            <ul className='pl-10 flex flex-col'>
                                                <li onClick={() => setOpenShadharan(!openSadharan)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                                                    <span className='ml-2'>
                                                        <span className='font-karma font-medium text-base'>
                                                            साधारण / दैनिक बचत
                                                        </span>
                                                    </span>
                                                    <i className='bi bi-chevron-down mr-4' />
                                                </li>
                                                {
                                                    openSadharan &&
                                                    <>
                                                        <ul className='pl-5 flex flex-col border-t border-black border-opacity-10'>
                                                            {
                                                                sadharan.map((item, index) => {
                                                                    return (
                                                                        <MenuItem title={item.title} to={`/services/savings/sadharan/${item.id}`} />
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </ul>
                                                    </>
                                                }
                                                <li onClick={() => setOpenKramik(!openKramik)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                                                    <span className='ml-2'>
                                                        <span className='font-karma font-medium text-base'>
                                                            क्रमिक बचत
                                                        </span>
                                                    </span>
                                                    <i className='bi bi-chevron-down mr-4' />
                                                </li>
                                                {
                                                    openKramik &&
                                                    <>
                                                        <ul className='pl-5 flex flex-col border-t border-black border-opacity-10'>
                                                            {
                                                                kramik.map((item, index) => {
                                                                    return (
                                                                        <MenuItem title={item.title} to={`/services/savings/kramik/${item.id}`} />
                                                                    )
                                                                }
                                                                )
                                                            }
                                                        </ul>
                                                    </>
                                                }
                                                <li className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg'>
                                                    <Link to='services/savings/aabadhik'>
                                                        <span className='font-karma font-medium text-base ml-2'>
                                                            आवधिक बचत
                                                        </span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </>

                                    }
                                    <li onClick={() => setOpenLoan(!openLoan)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                                        <span className='ml-2'>
                                            Loan
                                        </span>
                                        <i className='bi bi-chevron-down mr-4' />
                                    </li>

                                    {
                                        openLoan &&
                                        <>
                                            <ul className='pl-14 flex flex-col'>
                                                {
                                                    loan.map((item, index) => {
                                                        return (
                                                            <MenuItem title={item.title} to={`/services/loan/${item.id}`} />
                                                        )
                                                    }
                                                    )
                                                }
                                            </ul>
                                        </>
                                    }

                                    <MenuItem title='Remittance' to='/services/remittance' />
                                    <MenuItem title='Social Services' to='/services/social' />

                                </ul>
                            </>
                        }



                        <MenuItem title='Contact' to='/contact' />
                        <MenuItem title='Notices' to='/notices' />

                        <li onClick={() => setOpenBranch(!openBranch)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                            <span className='ml-2'>
                                Branches
                            </span>
                            <i className='bi bi-chevron-down mr-4' />
                        </li>
                        {
                            openBranch &&
                            <>
                                <ul className='pl-5 flex flex-col '>
                                    <MenuItem title='Arjundhara' to='/branches/arjundhara' />
                                </ul>
                            </>
                        }

                        <li onClick={() => setOpenCoop(!openCoop)} className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg flex justify-between'>
                            <span className='ml-2'>
                                COOP Knowledge
                            </span>
                            <i className='bi bi-chevron-down mr-4' />
                        </li>
                        {
                            openCoop &&
                            <>
                                <ul className='pl-5 flex flex-col '>
                                    <MenuItem title='Act, Policy & Directives' to='/knowledge/act-policies' />
                                    <MenuItem title='Articles & Knowledge' to='/knowledge/articles' />
                                </ul>
                            </>
                        }
                        {/* <MenuItem title='Gallery' to='/gallery' /> */}


                    </ul>
                </div>

                <button className='bg-primary p-2 px-3 rounded hover:bg-primary-d mt-10 w-[90%]'>
                    <Link to='/update-kom' className='text-xs font-sans text-white'>
                        <i className="bi bi-ui-radios mr-2"></i>
                        UPDATE KOM
                    </Link>
                </button>
            </div>
        </>
    );

    const MenuItem = (props) => {
        return (
            <li className='border-t border-black border-opacity-10 py-2 text-primary uppercase text-lg'>
                <Link to={props.to}>
                    <span className='ml-2'>
                        {props.title}
                    </span>
                </Link>
            </li>
        )
    }

    return (
        <div>
            {['right'].map((anchor) => (
                <React.Fragment key={anchor}>
                    <Button onClick={toggleDrawer(anchor, true)}>
                        <i className='bi bi-filter-right text-primary text-4xl' />
                    </Button>
                    <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                    >
                        {list(anchor)}
                    </Drawer>
                </React.Fragment>
            ))}
        </div>
    );
}
