import { Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { FormDataContext } from '../../contexts/FormDataContext';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';


const devanagariDigits = {
    0: '\u0966',
    1: '\u0967',
    2: '\u0968',
    3: '\u0969',
    4: '\u096A',
    5: '\u096B',
    6: '\u096C',
    7: '\u096D',
    8: '\u096E',
    9: '\u096F',
    10: '\u0967\u0966',
};

const demoRels = {
    0: 'हजुरबुबा',
    1: 'हजुरआमा',
    2: 'बुबा',
    3: 'आमा',
    4: 'पति/पत्नी',
}


const Komform = () => {

    const navigate = useNavigate();

    const { register, handleSubmit, watch, formState: { errors }, reset } = useForm();
    const { data, setData } = useContext(FormDataContext);

    // submission

    const [citizenship_front_previewUrl, setCitizenship_front_previewUrl] = useState(null);
    const [citizenship_back_previewUrl, setCitizenship_back_previewUrl] = useState(null);
    const [other_doc_previewUrl, setOther_doc_previewUrl] = useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);

    const [isUploading, setIsUploading] = useState(false);

    // useEffect(() => {
    //     setIsUploading(data.uploading_state)
    // }, [data])



    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setImagePreviewUrl(event.target.result);
        };
        reader.readAsDataURL(file);
    };

    // document submission handlers

    const handleCitizenship_front_change = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setCitizenship_front_previewUrl(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    const handleCitizenship_back_change = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setCitizenship_back_previewUrl(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    const handleOther_doc_change = (e) => {
        const file = e.target.files[0];

        if (!file) return;

        const reader = new FileReader();
        reader.onload = (event) => {
            setOther_doc_previewUrl(event.target.result);
        };
        reader.readAsDataURL(file);
    }

    // copy address
    const [isChecked, setIsChecked] = useState(false);



    function isDevanagari(str) {
        var devanagariRegex = /[\u0900-\u097F]/;
        return devanagariRegex.test(str);
    }

    function convertDevanagariToEnglish(str) {
        // Map of devanagari numbers and their corresponding english numbers
        var devanagariToEnglishMap = {
            "०": "0", "१": "1", "२": "2", "३": "3", "४": "4", "५": "5", "६": "6", "७": "7", "८": "8", "९": "9"
        };
        var english = "";
        for (var i = 0; i < str.length; i++) {
            if (devanagariToEnglishMap[str[i]]) {
                english += devanagariToEnglishMap[str[i]];
            } else {
                english += str[i];
            }
        }
        return english;
    }


    function generateReferenceNumber(membershipNumber) {
        // check if membership number is in devanagari and convert to english
        var membershipNumberEnglish = membershipNumber;
        if (isDevanagari(membershipNumber)) {
            membershipNumberEnglish = convertDevanagariToEnglish(membershipNumber);
        }

        var currentDate = new Date();
        var month = currentDate.getMonth() + 1; // getMonth returns 0-based index
        // day too
        var day = currentDate.getDate();
        var year = currentDate.getFullYear();
        year = year.toString().substr(2);
        // var prefix = "" + year + (month < 10 ? "0" + month : month); // add leading zero for single digit months
        var prefix = "" + year + (month < 10 ? "0" + month : month) + (day < 10 ? "0" + day : day); // add leading zero for single digit months
        membershipNumberEnglish = membershipNumberEnglish.trim();
        var referenceNumber = prefix + membershipNumberEnglish;
        return referenceNumber;
    }




    const onSubmit = data => {
        setIsUploading(true);

        data.reference_number = generateReferenceNumber(data.membership_no);

        data.user_image = imagePreviewUrl;
        data.citizenship_image_front = citizenship_front_previewUrl;
        data.citizenship_image_back = citizenship_back_previewUrl;
        data.other_doc_image = other_doc_previewUrl;
        data.uploadIt = true;
        setData(data);
        reset();
        setCitizenship_back_previewUrl(null);
        setCitizenship_front_previewUrl(null);
        setOther_doc_previewUrl(null);
        setImagePreviewUrl(null);
        setIsChecked(false);
        setIsUploading(false);
    };

    // TODO: on submission, check if the customer id (membership_no) already exists in the database, if yes then update the data, if no then insert the data. Same for PDF and image upload.

    // TODO: During submission, generate a unique ID

    // while sending to server, send along the name of customer and their membership number on the same array as that comtains the link to this pdf

    return (
        <>


            <div className='w-full flex items-center justify-center'>

                <div className="container border mx-auto w-[100%] py-5">

                    <form onSubmit={handleSubmit(onSubmit)}>

                        {/* HEADER SECTION */}
                        <div className='w-full flex flex-col md:flex-row items-center justify-between my-5 mb-10'>
                            <div className='md:w-[20%] w-[30%]'>
                                <img src={require('../../assets/logo.png')} className='md:m-5 mb-5 md:mb-0 md:w-[60%]' />
                            </div>
                            <div className='flex flex-col items-center gap-3 md:w-[60%]'>
                                <h2 className='font-bold text-xl md:text-3xl font-karma text-center'>
                                    आबद्ध बचत तथा ऋण सहकारी संस्था लि.
                                </h2>
                                <p className='text-center font-bold text-sm font-karma'>
                                    बिर्तामोड न.पा. - ५, झापा
                                    <br />
                                    स्था: २०६९
                                </p>
                                <p className='text-center font-bold text-lg font-karma'>
                                    सदस्य परिचय फारम
                                    <br />
                                    <span className='text-sm font-sans'>
                                        Know Our Member (KOM)
                                    </span>
                                </p>
                            </div>
                            <div className='w-[50%] md:w-[20%] mt-5 md:mt-0 flex flex-col items-center md:items-end'>
                                <label
                                    className='md:mr-5 w-[60%] bg-[#E4E6E7] text-primary text-center text-sm font-semibold'
                                >
                                    <img src={imagePreviewUrl === null ? require('../../assets/illustrations/avatar.png') : imagePreviewUrl} className={`w-42 h-42 object-cover cursor-pointer ${errors.photo && 'border-2 border-red-500'}`} />
                                    <input type='file'
                                        {...register('image', { required: true })}
                                        name='image'
                                        onChange={handleImageChange}
                                        className='hidden'
                                    />
                                    <p className='text-xs font-bold text-primary cursor-pointer p-1 uppercase underline'>
                                        {imagePreviewUrl === null ? 'Upload Photo' : 'Change Photo'}
                                        <span className='text-red-500'>*</span>
                                    </p>
                                </label>
                                {errors.image && <span className='text-red-500 text-sm font-sans mr-5'>Image is required.</span>}
                            </div>
                        </div>

                        <hr />

                        {/* Early Section */}
                        <div className='px-5 my-10 flex md:flex-row flex-col md:items-center justify-between'>
                            <div className='flex items-center gap-3 md:w-1/2'>
                                <label htmlFor='membership_no'>
                                    सदस्यता नं.
                                    <span className='text-red-500'>*</span>
                                </label>
                                <input name='membership_no' type='text' className='border border-gray-300 p-1 mt-1 w-[50%]' {...register("membership_no", { required: true })} />
                                {errors.membership_no && <span className='text-red-500 text-sm font-sans '>Membership Number is required.</span>}
                            </div>

                            <div className='flex items-center md:justify-end gap-3'>
                                <label htmlFor='service_center'>
                                    सेवा केन्द्र
                                </label>
                                <select name='service_center' className='border border-gray-300 p-1 mt-2 md:mt-1 ml-[19px] md:ml-0' {...register("service_center", { required: false })}>
                                    <option value='बिर्तामोड'>बिर्तामोड</option>
                                    <option value='अर्जुनधारा'>अर्जुनधारा</option>
                                </select>
                            </div>
                        </div>

                        {/* Personal Details */}
                        <div className='px-5 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                क. व्यक्तिगत विवरण
                            </h3>


                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="name_ne" className="block text-sm text-gray-700">१. नाम, थर (नेपालीमा)</label>
                                    <input type="text" name="name_ne" className="p-1 mt-[5px] block w-full sm:text-sm border-b border-black/30" {...register("name_ne", { required: false })} />
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="name_en" className="block text-sm text-gray-700 font-sans">Name (ENGLISH)<span className='text-red-500'>*</span></label>
                                    <input type="text" name="name_en" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("name_en", { required: true })} />
                                    {errors.name_en && <span className='text-red-500 text-sm font-sans '>Name (IN ENGLISH) is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="birth_bs" className="block text-sm font-medium text-gray-700">२. जन्म मिति <strong>(बि.स.)<span className='text-red-500'>*</span></strong></label>
                                    <input type="text" name="birth_bs" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("birth_bs", { required: true })} />
                                    {errors.birth_bs && <span className='text-red-500 text-sm font-sans '>Birth Date (BS) is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="birth_ad" className="block text-sm font-medium text-gray-700">जन्म मिति <strong>(ई.स.)</strong></label>
                                    <input type="text" name="birth_ad" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("birth_ad", { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="citizenship_no" className="block text-sm font-medium text-gray-700">३. नागरिकता प्रमाण-पत्र न.<span className='text-red-500'>*</span></label>
                                    <input type="text" name="citizenship_no" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("citizenship_no", { required: true })} />
                                    {errors.citizenship_no && <span className='text-red-500 text-sm font-sans '>Citizenship Number is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="citizenship_issue" className="block text-sm font-medium text-gray-700">नागरिकता जारी गर्ने जिल्ला र मिति<span className='text-red-500'>*</span></label>
                                    <input type="text" name="citizenship_issue" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("citizenship_issue", { required: true })} />
                                    {errors.citizenship_no && <span className='text-red-500 text-sm font-sans '>Citizenship issued district & date is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="auth_address" className="block text-sm font-medium text-gray-700">४. नागरिकता अनुसारको ठेगाना<span className='text-red-500'>*</span></label>
                                    <input type="text" name="auth_address" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("auth_address", { required: true })} />
                                    {errors.auth_address && <span className='text-red-500 text-sm font-sans '>Citizenship Address is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="gender" className="block text-sm font-medium text-gray-700">५. लिङ्ग</label>
                                    <select defaultValue="" name="gender" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register("gender", { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='m'>पुरुष</option>
                                        <option value='f'>महिला</option>
                                        <option value='o'>अन्य</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="mobile_no" className="block text-sm font-medium text-gray-700">६. मोबाइल न.<span className='text-red-500'>*</span></label>
                                    <input type="text" name="mobile_no" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("mobile_no", { required: true })} />
                                    {errors.mobile_no && <span className='text-red-500 text-sm font-sans '>Mobile Number is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="phone_no" className="block text-sm font-medium text-gray-700">फोन न.</label>
                                    <input type="text" name="phone_no" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("phone_no", { required: false })} />
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="email_ad" className="block text-sm font-medium text-gray-700">इमेल</label>
                                    <input type="email" name="email_ad" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("email_ad", { required: false })} />
                                </div>

                            </div>

                        </div>

                        {/* Family Detail */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                ख. पारिवारिक विवरण
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="father_name_ne" className="block text-sm font-medium text-gray-700">७. बुबाको नाम<span className='text-red-500'>*</span></label>
                                    <input type="text" name="father_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("father_name", { required: true })} />
                                    {errors.father_name && <span className='text-red-500 text-sm font-sans '>Father's Name is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="mother_name" className="block text-sm font-medium text-gray-700 font-sans">आमाको नाम<span className='text-red-500'>*</span></label>
                                    <input type="text" name="mother_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("mother_name", { required: true })} />
                                    {errors.mother_name && <span className='text-red-500 text-sm font-sans '>Mother's Name is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="grandpa_name" className="block text-sm font-medium text-gray-700 font-sans">८. हजुरबुबाको नाम<span className='text-red-500'>*</span></label>
                                    <input type="text" name="grandpa_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("grandpa_name", { required: true })} />
                                    {errors.grandpa_name && <span className='text-red-500 text-sm font-sans '>Grandfather's Name is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="marital" className="block text-sm font-medium text-gray-700">९. वैवाहिक स्थिति<span className='text-red-500'>*</span></label>
                                    <select defaultValue="" name="marital" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register("marital", { required: true })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='married'>विवाहित</option>
                                        <option value='unmarried'>अविवाहित</option>
                                        <option value='widowed'>एकल</option>
                                    </select>
                                    {errors.marital && <span className='text-red-500 text-sm font-sans '>Marital Status is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6 mt-10">
                                    <label htmlFor="spouse_name" className="block text-sm font-medium text-gray-700 font-sans">१०. पति वा पत्नीको नाम र थर</label>
                                    <input type="text" name="spouse_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register("spouse_name", { required: watch('marital') === 'married' ? true : false })} />
                                    {errors.spouse_name && <span className='text-red-500 text-sm font-sans'>Spouse's Name is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="family_type" className="block text-sm font-medium text-gray-700">११. परिवारको किसिम</label>
                                    <select name="family_type" defaultValue="" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register("family_type", { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='संयुक्त र एकै भातभान्छा'>संयुक्त र एकै भातभान्छा</option>
                                        <option value='संयुक्त तर अलग भातभान्छा'>संयुक्त तर अलग भातभान्छा</option>
                                        <option value='छुटै भिन्न या एकसरुवा (न्युक्लियर)'>छुटै भिन्न या एकसरुवा (न्युक्लियर)</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6 relative overflow-x-auto">
                                    <p className='md:hidden mb-2 italic text-black/40 text-sm font-sans'>
                                        Swipe left to see more
                                    </p>
                                    <table className="table-fixed w-full">
                                        <thead className='bg-primary text-white border border-black/30 text-sm'>
                                            <tr>
                                                <th scope='col' className="px-4 py-2 w-[50px] md:w-[5%]">क्र.सं.</th>
                                                <th scope='col' className="px-4 py-2 w-[200px] md:w-[35%]">एकाघर परिवारको नाम</th>
                                                <th scope='col' className="px-4 py-2 w-[100px] md:w-[10%]">नाता</th>
                                                <th scope='col' className="px-4 py-2 w-[50px] md:w-[5%]">उमेर</th>
                                                <th scope='col' className="px-4 py-2 w-[100px] md:-[10%]">पेशा</th>
                                                <th scope='col' className="px-4 py-2 w-[500px] md:w-[35%]">अन्य सहकारीमा आबद्ध भएको भए सहकारीको नाम र ठेगाना</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Array.from({ length: 10 }).map((_, rowIndex) => (
                                                <tr key={rowIndex}>
                                                    {Array.from({ length: 6 }).map((_, colIndex) => (
                                                        <td key={colIndex} className="border border-black/30 px-4 py-2">
                                                            {
                                                                colIndex === 2 && demoRels[rowIndex] ? (
                                                                    <div>{demoRels[rowIndex]}</div>
                                                                ) :
                                                                    colIndex === 0 ? (
                                                                        <div>{devanagariDigits[rowIndex + 1]}</div>
                                                                    ) :
                                                                        (
                                                                            <input
                                                                                type="text"
                                                                                name={`column-${colIndex}-row-${rowIndex}`}
                                                                                className="block w-full sm:text-sm"
                                                                                {...register(`family_members[${rowIndex}].${colIndex === 1 ? 'name' : colIndex === 2 ? 'relation' : colIndex === 3 ? 'age' : colIndex === 4 ? 'occupation' : colIndex === 5 ? 'coop' : ''}`, { required: false })}
                                                                            />
                                                                        )}
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </div>



                        </div>

                        {/* Occupation Detail */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                ग. पेसाको विवरण
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="occupation" className="block text-sm font-medium text-gray-700">१२. आफ्नो मुख्य पेसा<span className='text-red-500'>*</span></label>
                                    <select defaultValue="" name="occupation" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('occupation', { required: true })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='खेतीपाती'>खेतीपाती</option>
                                        <option value='व्यवसाय'>व्यवसाय</option>
                                        <option value='नोकरी'>नोकरी</option>
                                        <option value='बैदेशिक रोजगारी'>बैदेशिक रोजगारी</option>
                                        <option value='अन्य'>अन्य</option>
                                    </select>
                                    {errors.occupation && <span className='text-red-500 text-sm font-sans'>Occupation is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="pan_number" className="block text-sm font-medium text-gray-700">१३. स्थायी लेखा नम्बर </label>
                                    <input type="text" name="pan_number" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('pan_number', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="spouse_occupation" className="block text-sm font-medium text-gray-700">१४. पति वा पत्नीको मुख्य पेसा</label>
                                    <select defaultValue="" name="spouse_occupation" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('spouse_occupation', { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='खेतीपाती'>खेतीपाती</option>
                                        <option value='व्यवसाय'>व्यवसाय</option>
                                        <option value='नोकरी'>नोकरी</option>
                                        <option value='बैदेशिक रोजगारी'>बैदेशिक रोजगारी</option>
                                        <option value='अन्य'>अन्य</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6  flex gap-5 items-center">
                                    <label htmlFor="fame_occ" className="block text-sm font-medium text-gray-700">
                                        १५. आफै वा परिवारको कुनै सदस्य उच्च पदीय राजनीतिक, प्रशासनिक वा सांगठित भूमिकामा रहे, नरहेको<span className='text-red-500'>*</span>
                                    </label>
                                    <select defaultValue="" name="fame_occ" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('fame_occ', { required: true })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value="yes">रहेको</option>
                                        <option value="no">नरहेको</option>
                                    </select>
                                    {errors.fame_occ && <span className='text-red-500 text-sm font-sans'>This is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700'>१६. उच्च पदीय राजनीतिक, प्रशासनिक वा सांगठित भूमिकामा रहेको सदस्यको,</p>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="fame_occ_name" className="block text-sm font-medium text-gray-700">नाम</label>
                                    <input type="text" name="fame_occ_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('fame_occ_name', {
                                        required: watch('fame_occ') === true ? true : false
                                    })} />
                                    {errors.fame_occ_name && <span className='text-red-500 text-sm font-sans'>This is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="fame_occ_rel" className="block text-sm font-medium text-gray-700">नाता</label>
                                    <input type="text" name="fame_occ_rel" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('fame_occ_rel', {
                                        required: watch('fame_occ') === true ? true : false
                                    })} />
                                    {errors.fame_occ_rel && <span className='text-red-500 text-sm font-sans'>This is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="fame_occ_role" className="block text-sm font-medium text-gray-700">पद वा सार्वजनिक भूमिका</label>
                                    <input type="text" name="fame_occ_role" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('fame_occ_role', {
                                        required: watch('fame_occ') === true ? true : false
                                    })} />
                                    {errors.fame_occ_role && <span className='text-red-500 text-sm font-sans'>This is required.</span>}
                                </div>


                            </div>
                        </div>

                        {/* Household Detail */}
                        <div className='px-5 mt-20 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                घ. रहनसहनको विवरण
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700'>१७. स्थायी ठेगाना (स्थायीबसोबास अनुसार)</p>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="per_address_province" className="block text-sm font-medium text-gray-700">प्रदेश<span className='text-red-500'>*</span></label>
                                    <input type="text" name="per_address_province" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('per_address_province', { required: true })} />
                                    {errors.per_address_province && <span className='text-red-500 text-sm font-sans'>Province is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="per_address_district" className="block text-sm font-medium text-gray-700">जिल्ला<span className='text-red-500'>*</span></label>
                                    <input type="text" name="per_address_district" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('per_address_district', { required: true })} />
                                    {errors.per_address_district && <span className='text-red-500 text-sm font-sans'>District is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="per_address_gov" className="block text-sm font-medium text-gray-700">स्थानीय तह<span className='text-red-500'>*</span></label>
                                    <input type="text" name="per_address_gov" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('per_address_gov', { required: true })} />
                                    {errors.per_address_gov && <span className='text-red-500 text-sm font-sans'>Metro/Municipality/Rural Municipality is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="per_address_ward" className="block text-sm font-medium text-gray-700">वार्ड न.<span className='text-red-500'>*</span></label>
                                    <input type="text" name="per_address_ward" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('per_address_ward', { required: true })} />
                                    {errors.per_address_ward && <span className='text-red-500 text-sm font-sans'>Ward is required.</span>}
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="per_address_tole" className="block text-sm font-medium text-gray-700">टोल</label>
                                    <input type="text" name="per_address_tole" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('per_address_tole', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    {/* checkbox */}
                                    <div className='flex items-center gap-5'>
                                        <p className='text-sm font-medium text-gray-700'>१८. पत्राचार ठेगाना (हाल बसोबास अनुसार)</p>
                                        <div className='flex items-center justify-center'>
                                            <input
                                                type="checkbox"
                                                className='mr-2'
                                                name="copy_permanent_address"
                                                checked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                            />
                                            <label htmlFor="same_mailing_address" className="block text-sm font-medium text-gray-700">स्थायी ठेगानामै रहेको छ</label>
                                        </div>
                                    </div>
                                </div>
                                {!isChecked &&
                                    <>
                                        <div className="col-span-12 sm:col-span-2">
                                            <label htmlFor="temp_address_province" className="block text-sm font-medium text-gray-700">प्रदेश<span className='text-red-500'>*</span></label>
                                            <input
                                                defaultValue={isChecked ? watch('per_address_province') : ''}
                                                type="text" name="temp_address_province" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                                {...register('temp_address_province', {
                                                    required: true,
                                                    defaultValue: isChecked ? watch('per_address_province') : ''
                                                })}
                                            />
                                            {errors.temp_address_province && <span className='text-red-500 text-sm font-sans'>Province is required.</span>}
                                        </div>
                                        <div className="col-span-12 sm:col-span-2">
                                            <label htmlFor="temp_address_district" className="block text-sm font-medium text-gray-700">जिल्ला<span className='text-red-500'>*</span></label>
                                            <input
                                                defaultValue={isChecked ? watch('per_address_district') : ''}
                                                type="text" name="temp_address_district" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                                {...register('temp_address_district', { required: true })}
                                            />
                                            {errors.temp_address_district && <span className='text-red-500 text-sm font-sans'>District is required.</span>}
                                        </div>
                                        <div className="col-span-12 sm:col-span-2">
                                            <label htmlFor="temp_address_gov" className="block text-sm font-medium text-gray-700">स्थानीय तह<span className='text-red-500'>*</span></label>
                                            <input
                                                defaultValue={isChecked ? watch('per_address_gov') : ''}
                                                type="text" name="temp_address_gov" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                                {...register('temp_address_gov', { required: true })}
                                            />
                                            {errors.temp_address_gov && <span className='text-red-500 text-sm font-sans'>Metro/Municipality/Rural Municipality is required.</span>}
                                        </div>
                                        <div className="col-span-12 sm:col-span-2">
                                            <label htmlFor="temp_address_ward" className="block text-sm font-medium text-gray-700">वार्ड न.<span className='text-red-500'>*</span></label>
                                            <input
                                                defaultValue={isChecked ? watch('per_address_ward') : ''}
                                                type="text" name="temp_address_ward" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                                {...register('temp_address_ward', { required: true })}
                                            />
                                            {errors.temp_address_ward && <span className='text-red-500 text-sm font-sans'>Ward is required.</span>}
                                        </div>
                                        <div className="col-span-12 sm:col-span-2">
                                            <label htmlFor="temp_address_tole" className="block text-sm font-medium text-gray-700">टोल</label>
                                            <input
                                                defaultValue={isChecked ? watch('per_address_tole') : ''}
                                                type="text" name="temp_address_tole" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                                {...register('temp_address_tole', { required: false })}
                                            />
                                        </div>
                                    </>
                                }

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="work_addr" className="block text-sm font-medium text-gray-700">१९. संस्थाको कार्यक्षेत्रको बसोबास</label>
                                    <select defaultValue="" name="work_addr" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('work_addr', { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='स्थायी'>स्थायी</option>
                                        <option value='अस्थायी'>अस्थायी</option>
                                    </select>
                                    {errors.work_addr && <span className='text-red-500 text-sm font-sans'>Work address is required.</span>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="spending_frame" className="block text-sm font-medium text-gray-700">२०. संस्थाको कार्यक्षेत्रमा बर्षमा बिताउने अवधि</label>
                                    <input type="text" name="spending_frame" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('spending_frame', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="passport_no" className="block text-sm font-medium text-gray-700">२१. राहदानी नम्बर भएमा</label>
                                    <input type="text" name="passport_no" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('passport_no', { required: false })} />
                                </div>

                            </div>
                        </div>

                        {/* Coop Membership */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                ङ. सहकारी सदस्यता
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="membership_intent" className="block text-sm font-medium text-gray-700">२२. संस्थाको सदस्य बन्नुको उद्देस्य</label>
                                    <input type="text" name="membership_intent" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('membership_intent', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="other_coop" className="block text-sm font-medium text-gray-700">२३. आफै अर्को सहकारी संस्थाको पनि सदस्य भएको नभएको</label>
                                    <select defaultValue="" name="other_coop" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('other_coop', { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='भएको'>भएको</option>
                                        <option value='नभएको'>नभएको</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6 relative overflow-x-auto">
                                    <label htmlFor="other_coop_det" className="block text-sm font-medium text-gray-700">२४. आफै अर्को सहकारी संस्थाको सदस्य भए विवरण</label>
                                    <table className="table-auto w-full mt-2">
                                        <thead className='bg-primary text-white border border-black/30 text-sm'>
                                            <tr>
                                                <th className="px-4 py-2 w-[5%]">क्र.सं.</th>
                                                <th className="px-4 py-2 w-[65%]">संस्थाको नाम, ठेगाना</th>
                                                <th className="px-4 py-2 text-xs md:text-sm w-[30%]">सदस्यता न.</th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {
                                                Array.from({ length: 5 }).map((_, rowIndex) => (
                                                    <tr key={rowIndex}>
                                                        {
                                                            Array.from({ length: 3 }).map((_, colIndex) => (
                                                                <td key={colIndex} className='border border-black/30'>
                                                                    <input type="text" name="other_coop_det" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register(`other_coop_det[${rowIndex}][${colIndex}]`, { required: false })} />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        {/* Income Details */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                च. आयस्रोतको विवरण
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="income_tot" className="block text-sm font-medium text-gray-700">२५. बार्षिक पारिवारिक (आफ्नो, पति वा पत्नीको र एकै भातभान्छाका सदस्यहरुको) आम्दानि (जग्गाको खुद आयस्ता, नोकरीको पारिश्रमिक, व्यवसायको मुनाफा)</label>
                                    <select defaultValue="" name="income_tot" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('income_tot', { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='४ लाखसम्म'>४ लाखसम्म</option>
                                        <option value='४ लाखदेखि १० लाखसम्म'>४ लाखदेखि १० लाखसम्म</option>
                                        <option value='१० लाखदेखि २५ लाखसम्म'>१० लाखदेखि २५ लाखसम्म</option>
                                        <option value='२५ लाखदेखि ५० लाखसम्म'>२५ लाखदेखि ५० लाखसम्म</option>
                                        <option value='५० लाखबढी'>५० लाखबढी</option>
                                    </select>
                                </div>

                                <div className="col-span-12 sm:col-span-6 flex gap-5 items-center">
                                    <label htmlFor="income_tot_det" className="block text-sm font-medium text-gray-700">२६. रु ४ लाख भन्दा बढी बार्षिक पारिवारिक आम्दानि भए पछिल्लो आर्थिक बर्षको आय र स्रोतको विवरण</label>
                                    <select defaultValue="" name="income_tot_det" className="p-1 mt-1 block sm:text-sm border border-black/30" {...register('income_tot_det', { required: false })}>
                                        <option value='' disabled>छान्नुहोस्</option>
                                        <option value='खेतीपाती'>खेतीपाती</option>
                                        <option value='व्यवसाय'>व्यवसाय</option>
                                        <option value='स्वदेशी रोजगारी'>स्वदेशी रोजगारी</option>
                                        <option value='बैदेशिक रोजगारी'>बैदेशिक रोजगारी</option>
                                        <option value='अन्य'>अन्य</option>
                                    </select>
                                </div>

                            </div>
                        </div>


                        {/* Transaction Details */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                छ. वित्तीय कारोबारको विवरण
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700'>२७. प्रारम्भमा संस्थामा जम्मा गर्ने वा हालसम्म संस्थामा जम्मा गरिसकेको रकमको विवरण</p>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="kramik_init" className="block text-sm font-medium text-gray-700">क्रमिक बचत</label>
                                    <input type="text" name="kramik_init" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('kramik_init', { required: false })} />
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="aabadhik_init" className="block text-sm font-medium text-gray-700">आवधिक बचत</label>
                                    <input type="text" name="aabadhik_init" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('aabadhik_init', { required: false })} />
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="aabaddha_init" className="block text-sm font-medium text-gray-700">आबद्ध बचत</label>
                                    <input type="text" name="aabaddha_init" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('aabaddha_init', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700'>२८. संस्थाको खातामा अनुमानित राअनधरनको रकम कलम</p>
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="transaction_ct" className="block text-sm font-medium text-gray-700">बर्षमा कारोबार गर्ने पटक</label>
                                    <input type="text" name="transaction_ct" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('transaction_ct', { required: false })} />
                                </div>
                                <div className="col-span-12 sm:col-span-2">
                                    <label htmlFor="deposit_intent" className="block text-sm font-medium text-gray-700">बार्षिक रुपमा जम्मा गर्ने अनुमानित रकम</label>
                                    <input placeholder="रु. " type="text" name="deposit_intent" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('deposit_intent', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="loan_intent" className="block text-sm font-medium text-gray-700">२९. संस्थासंग अनुमानित ऋण धनको रकम कलम</label>
                                    <input placeholder="रु. " type="text" name="loan_intent" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('loan_intent', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="other_det" className="block text-sm font-medium text-gray-700">३०. अभिलेख राख्नयोग्य थप विवरण वा द्रस्टब्य</label>
                                    <input type="text" name="other_det" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('other_det', { required: false })} />
                                </div>

                            </div>
                        </div>

                        {/* Self Declaration */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                ज. स्व-घोषणा
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700'>
                                        १. मैले पेश गरेको विवरणमा भविस्यमा कुनै परिवर्तन आएमा त्यस्तो परिवर्तन भएको मितिले ३५ दिनभित्र संस्थामा पेस गर्नेछु।
                                    </p>
                                    <p className='text-sm font-medium text-gray-700'>
                                        २. मैले पेस गरेको मेरो सम्पूर्ण विवरण ठीक दुरुस्त छ। झुटा ठहरे कानुन बमोजिम सहुला, बुझाउला।
                                    </p>
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <div className="flex items-center">
                                        <input name='self_dec' id="checkbox" type="checkbox" className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" {...register('self_dec', { required: true })} />
                                        <label htmlFor="self_dec" className="ml-2 block text-sm text-gray-900 mt-1">
                                            स्व-घोषणा गर्दै छु<span className='text-red-500'>*</span>
                                        </label>
                                    </div>
                                    {errors.self_dec && <p className='text-red-500 text-sm font-sans'>Self declaration is required.</p>}
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <label htmlFor="self_dec_name" className="block text-sm font-medium text-gray-700">नाम</label>
                                    {/* <input placeholder="" type="text" name="self_dec_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30"
                                        defaultValue={watch('name_en')}
                                        onChange={(e) => setValue('self_dec_name', e.target.value)}
                                    /> */}
                                    <p>
                                        {watch('name_en')}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {/* Docs */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                झ. संलग्न कागजातहरु
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <div className="flex flex-col justify-center">
                                        <div className="flex items-center">
                                            <input type='checkbox' name="cit_check" className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" {...register('cit_check', { required: false })} />
                                            <label htmlFor="cit_check" className="ml-2 block text-sm text-gray-900 mt-1">
                                                नेपाली नागरिकताको प्रमाण-पत्रको प्रतिलिपि
                                            </label>
                                        </div>
                                        <div className="flex items-center">
                                            <input name="other_id_check" type="checkbox" className="focus:ring-primary h-4 w-4 text-primary border-gray-300 rounded" {...register('other_id_check', { required: false })} />
                                            <label htmlFor="other_id_check" className="ml-2 block text-sm text-gray-900 mt-1">
                                                अन्य (रास्ट्रीय परिचय पत्रको प्रतिलिपि, लाइसेन्सको प्रतिलिपि, मतदाता परिचयपत्रको प्रतिलिपि, आदि)
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={`${watch('cit_check') ? '' : 'hidden'} grid grid-cols-6 gap-6 w-full font-karma mt-5`}>
                                <div className="col-span-12 sm:col-span-6">
                                    <div className="flex flex-col justify-center gap-2">
                                        <div className="flex items-center gap-5">
                                            <label htmlFor="citizenship_front">
                                                <span className="text-sm text-gray-700"> नागरिकता प्रमाण-पत्र अपलोड गर्नुहोस् <strong>(अगाडीतर्फको तस्विर)</strong></span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="citizenship_front"
                                                id="citizenship_front"
                                                {...register('citizenship_front', { required: false })}
                                                onChange={handleCitizenship_front_change}
                                            />
                                        </div>
                                        {errors.citizenship_front && <p className='text-red-500 text-sm font-sans'>Citizenship certificate is required.</p>}
                                        <div className="flex items-center gap-5">
                                            <label htmlFor="citizenship_back">
                                                <span className="text-sm text-gray-700"> नागरिकता प्रमाण-पत्र अपलोड गर्नुहोस् <strong>(पछाडीतर्फको तस्विर)</strong></span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                name="citizenship_back"
                                                id="citizenship_back"
                                                {...register('citizenship_back', { required: false })}
                                                onChange={handleCitizenship_back_change}
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* <div className="col-span-12 sm:col-span-6">
                                    <div className='flex gap-5 items-center'>
                                        {citizenship_front_previewUrl && (
                                            <img src={citizenship_front_previewUrl} alt="Preview" className="my-2 h-auto w-1/4" />
                                        )}
                                        {citizenship_back_previewUrl && (
                                            <img src={citizenship_back_previewUrl} alt="Preview" className="my-2 h-auto w-1/4" />
                                        )}
                                    </div>
                                </div> */}
                            </div>

                            <div className={`${watch('other_id_check') ? '' : 'hidden'} grid grid-cols-6 gap-6 w-full font-karma mt-5`}>
                                <div className="col-span-12 sm:col-span-6">
                                    <div className="flex flex-col justify-center gap-2">
                                        <div className="flex items-center gap-5">
                                            <label htmlFor="other_id">
                                                <span className="text-sm text-gray-700"> अन्य कागजात अपलोड गर्नुहोस् (दुवैतर्फ एकै तस्बिर या Collage मा)<strong></strong></span>
                                            </label>
                                            <input
                                                type="file"
                                                accept="image/*, application/pdf"
                                                name="other_id"
                                                id="other_id"
                                                {...register('other_id', { required: false })}
                                                onChange={handleOther_doc_change}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Reccomendation */}
                        <div className='px-5 mt-10 flex flex-col items-start justify-center'>
                            <h3 className='font-karma underline text-lg font-bold text-primary mb-2'>
                                ञ. सिफारिस (यदि आवश्यक भए)
                            </h3>

                            <div className="grid grid-cols-6 gap-6 w-full font-karma">
                                <div className="col-span-12 sm:col-span-6">
                                    <p className="text-base leading-relaxed">
                                        यसमा उल्लेख भएका श्री
                                        <strong className='text-primary'> {watch('name_en')} </strong>
                                        लाइ म राम्ररी चिन्दछु। निजले मेरो रोहबरमा सहिछाप गरेका हुन्। मैले जानेबुझेसम्म निजले पेस गरेको विवरण ठीक भएकोले निजको निवेदनअनुसार  सहकारी संस्थाको सदस्यता प्रदान गर्न सिफारिस गर्दछु।
                                    </p>
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700 underline'>
                                        सिफारिसकर्ता सदस्यको
                                    </p>
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="recc_member_name" className="block text-sm font-medium text-gray-700">नाम</label>
                                    <input placeholder="" type="text" name="recc_member_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('recc_member_name', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="recc_membership_id" className="block text-sm font-medium text-gray-700">सदस्यता नम्बर</label>
                                    <input placeholder="" type="text" name="recc_membership_id" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('recc_membership_id', { required: false })} />
                                </div>

                                <div className="col-span-12 sm:col-span-6">
                                    <p className='text-sm font-medium text-gray-700 underline'>
                                        बजार प्रतिनिधिको
                                    </p>
                                </div>

                                <div className="col-span-12 sm:col-span-3">
                                    <label htmlFor="recc_name" className="block text-sm font-medium text-gray-700">नाम</label>
                                    <input placeholder="" type="text" name="recc_name" className="p-1 mt-1 block w-full sm:text-sm border-b border-black/30" {...register('recc_name', { required: false })} />
                                </div>

                            </div>
                        </div>


                        <div className='px-5 mt-10 flex flex-col items-center justify-center'>
                            <button
                                type="submit"
                                className="inline-flex items-center px-12 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary uppercase"
                            >
                                {
                                    isUploading ? (
                                        <>
                                            SUBMITTING
                                            <CircularProgress size={20} color="inherit" sx={{
                                                marginLeft: 1,
                                            }} />
                                        </>
                                    ) : (
                                        <p>
                                            SUBMIT
                                        </p>
                                    )
                                }
                            </button>
                        </div>

                    </form>

                </div>

            </div>



        </>
    )
}

export default Komform