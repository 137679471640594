import { Alert, Slide, Snackbar } from '@mui/material'
import React from 'react'

function TransitionLeft(props) {
    return <Slide {...props} direction="down" />;
}

const Globalsnack = (props) => {
    return (
        <>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                TransitionComponent={TransitionLeft}
                open={props.open}
                autoHideDuration={3000}
                onClose={props.onClose}
            >
                <Alert variant='filled' severity={props.type} sx={{ width: '100%' }}>
                    {props.text}
                </Alert>
            </Snackbar>
        </>
    )
}

export default Globalsnack