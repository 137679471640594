import React, { createContext } from 'react'

const MobileContext = createContext();

export const MobileViewContextProvider = ({ children }) => {
    const [mobileView, setMobileView] = React.useState(false)
    const handleResize = () => {
        if (window.innerWidth < 768) {
            setMobileView(true)
        } else {
            setMobileView(false)
        }
    }
    React.useEffect(() => {
        handleResize()
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }
        , [])

    return (
        <MobileContext.Provider value={{
            mobileView,
        }}>
            {children}
        </MobileContext.Provider>
    )
}

export const useMobileView = () => {
    return React.useContext(MobileContext)
}