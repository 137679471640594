import { collection, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import Sidebar from '../Sidebar';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import "nepali-datepicker-reactjs/dist/index.css"

const Editnews = () => {
    const { neid } = useParams();

    const [news, setNews] = React.useState({
        title: '',
        content: '',
        image: '',
        date: '',
        additionalImages: []
    });

    const [editTitlemode, setEditTitlemode] = React.useState(false);
    const [editContentmode, setEditContentmode] = React.useState(false);
    const [editImagemode, setEditImagemode] = React.useState(false);
    const [editAdditionalImagesMode, setEditAdditionalImagesMode] = React.useState(false);
    const [editDatemode, setEditDatemode] = React.useState(false);

    const [updated, setUpdated] = React.useState(false);


    useEffect(() => {
        try {
            const q = query(doc(db, 'news', neid));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setNews({
                    title: querySnapshot.data().title,
                    content: querySnapshot.data().content,
                    image: querySnapshot.data().image,
                    date: querySnapshot.data().date,
                    additionalImages: querySnapshot.data().additionalImages
                });
            });
            return unsubscribe;
        } catch (error) {
            // console.log(error);
        }
    }, [])

    const handleChange = (e) => {
        setNews({
            ...news,
            [e.target.name]: e.target.value,
        });
    }

    return (
        <>
            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Edit News</h3>
                </div>

                {editTitlemode === false ?
                    <h3 className='text-2xl text-primary my-5 font-bold font-karma'>
                        {news.title === '' ? 'N/A' : news.title}
                        <i onClick={() => setEditTitlemode(!editTitlemode)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                    </h3>
                    :
                    <div className='flex gap-2'>
                        <input
                            type="text"
                            name='title'
                            value={news.title}
                            placeholder=''
                            onChange={handleChange}
                            className='border border-gray-300 p-2 rounded-md'
                        />
                        <button
                            onClick={async () => {
                                setEditTitlemode(false);
                                await setDoc(doc(db, "news", neid), {
                                    title: news.title,
                                }, { merge: true });
                                setUpdated(true);
                            }
                            }
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                            Save
                        </button>
                        <button onClick={() => setEditTitlemode(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                            Cancel
                        </button>
                    </div>
                }
                {editContentmode === false ?
                    <p>
                        {news.content === '' ? 'N/A' : news.content}
                        <i onClick={() => setEditContentmode(!editContentmode)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                    </p>
                    :
                    <div className='flex flex-col justify-start items-start gap-2'>
                        <textarea
                            type="text"
                            name='content'
                            rows={10}
                            value={news.content}
                            placeholder=''
                            onChange={handleChange}
                            className='w-full border border-gray-300 p-2 rounded-md'
                        />
                        <button
                            onClick={async () => {
                                setEditContentmode(false);
                                await setDoc(doc(db, "news", neid), {
                                    content: news.content,
                                }, { merge: true });
                                setUpdated(true);
                            }
                            }
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                            Save
                        </button>
                        <button onClick={() => setEditContentmode(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                            Cancel
                        </button>
                    </div>
                }
                {editDatemode === false ?
                    <p className='text-base my-5 font-sans'>
                        {news.date === '' ? 'N/A' : news.date}
                        <i onClick={() => setEditDatemode(!editDatemode)} className="bi bi-pencil ml-2 cursor-pointer"></i>
                    </p>
                    :
                    <div className='flex gap-2 my-5 items-center'>
                        <small>
                            Click on the date to edit:
                        </small>
                        <NepaliDatePicker inputClassName="form-control"
                            value={''}
                            onChange={handleChange}
                            options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                                format: "YYYY-MM-DD",
                            }}
                        />
                        <button
                            onClick={async () => {
                                setEditDatemode(false);
                                await setDoc(doc(db, "news", neid), {
                                    date: news.date,
                                }, { merge: true });
                                setUpdated(true);
                            }
                            }
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'>
                            Save
                        </button>
                        <button onClick={() => setEditDatemode(false)} className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded'>
                            Cancel
                        </button>
                    </div>
                }

                <small>
                    <i>
                        Because of the simpler architecture of this version of LinkBinary Admin Engine, you can't edit images. If you want to change the image, delete the news and add a new one.
                    </i>
                </small>

            </Sidebar>
            <Globalsnack open={updated} setOpen={setUpdated} text='Updated' onClose={() => setUpdated(false)} />
        </>
    )
}

export default Editnews