import React, { useEffect } from 'react'
import Sidebar from '../Sidebar'
import { DataGrid } from '@mui/x-data-grid';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import "nepali-datepicker-reactjs/dist/index.css"

const AddSliderImage = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [sliders, setSliders] = React.useState([]);

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "sliders"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setSliders(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "sliders"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    return (
        <>

            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Slider Images</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>

                {
                    addMode === true ?
                        <Addind
                            changeAddMode={changeAddMode}
                        />
                        :
                        <>

                            <div className='flex flex-col gap-5'>
                                {
                                    sliders.map((slider, index) => {
                                        return (
                                            <div className='flex items-center justify-between border-b border-opacity-25 p-5' key={index}>
                                                <div className='flex items-center'>
                                                    <img src={slider.imageUrl} alt='slider' className='w-24 h-24 rounded' />
                                                    <div className='ml-5'>
                                                        <h3 className='text-xl font-bold'>{slider.title}</h3>
                                                        <p className='text-sm'>{slider.description}</p>
                                                    </div>
                                                </div>
                                                <div className='flex items-center'>
                                                    <button
                                                        onClick={() => deleteDocument(slider.id)}
                                                        className='bg-red-600 text-white font-bold py-1 px-4 rounded-full hover:bg-red-700'>
                                                        Delete
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                    )
                                }
                            </div>
                        </>
                }


            </Sidebar>

        </>
    )
}

const Addind = ({ changeAddMode }) => {
    const [image, setImage] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('');
    const [caption, setCaption] = React.useState('');

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    }

    const handleCaptionChange = (e) => {
        setCaption(e.target.value);
    }

    const handleImageUpload = () => {
        if (!image) {
            alert("Please choose an image first!")
        }
        const storageRef = ref(storage, `/sliders/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setImageUrl(url);
                });
            }
        );
    }

    const handleSubmit = async () => {
        try {
            const docRef = (collection(db, "sliders"));
            await addDoc(docRef, {
                imageUrl: imageUrl,
                caption: caption,
                timestamp: new Date().getTime()
            });
            changeAddMode();
        } catch (e) {
            // console.log(e)
        }
    }
    return (
        <div className='flex flex-col gap-5'>
            <div className='flex flex-col gap-5'>
                <label htmlFor='image' className='text-xl font-bold'>Image</label>
                <input type='file' id='image' onChange={handleImageChange} className='border rounded-lg p-2' />

                <label htmlFor='image' className='text-xl font-bold'>Caption</label>
                <input type='text' id='image' onChange={handleCaptionChange} className='border rounded-lg p-2' />

                <button onClick={handleImageUpload} className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>Upload</button>
            </div>
            {imageUrl !== '' &&
                <>
                    <div className='flex flex-col gap-5'>
                        <img src={imageUrl} alt='image' className='w-full' />
                        {caption !== '' &&
                            <div className='text-xl font-bold'>{caption}</div>
                        }
                    </div>
                    <div className='flex flex-col gap-5'>
                        <button onClick={handleSubmit} className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>Submit</button>
                    </div>
                </>
            }
        </div>
    )
}
export default AddSliderImage