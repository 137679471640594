import React, { useEffect } from 'react'
import './styles/rainbow.css'
import './styles/slider.css'
import { Button, Paper } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import { Link } from 'react-router-dom'
import one from '../../assets/sliders/1.jpg'
import two from '../../assets/sliders/2.jpg'
import { useMobileView } from '../../contexts/MobileViewContext'
import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import { db } from '../../firebase'

function Item(props) {
    return (
        <Paper>
            <h2>{props.item.name}</h2>
            <p>{props.item.description}</p>

            <Button className="CheckButton">
                Check it out!
            </Button>
        </Paper>
    )
}


const Hero = () => {

    const [items, setItems] = React.useState([])

    useEffect(() => {
        try {
            const q = query(collection(db, "sliders"), orderBy("timestamp", "desc"), limit(5));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setItems(querySnapshot.docs.map(doc => doc.data()));
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);

    const { mobileView } = useMobileView();

    return (
        <>

            <section className="flex flex-col-reverse items-center justify-between px-5 py-16 bg-green-100 border-y md:flex-row md:px-28">
                <div className='flex flex-col items-center justify-center gap-5 py-5 md:w-1/2 md:items-start'>
                    <h1 className="max-w-2xl text-2xl font-extrabold leading-3 tracking-tight md:text-4xl xl:text-5xl text-primary font-vesper">
                        {/* तपाइको ख्याल गर्ने सहकारी,<br />
                        आबद्द सहकारी */}
                        {/* वित्तीय आवश्यकताको समाधान <br />सहितको व्यवसायिक सहकारी */}
                        जन्मदेखि मृत्युसम्म आबदुको साथ <br />
                        समाजको समृद्धिमा सधैं सबै माझ
                    </h1>
                    <p className='text-center text-black font-sumana text-opacity-70 md:text-left'>
                        आबद्ध बचत तथा ऋण सहकारी संस्था लि.{' '}
                        {
                            mobileView ? <br /> : null
                        }
                        <span className='font-sans'>
                            (Aabaddha SACCOS Ltd.)
                        </span>
                    </p>
                    <div className='flex items-center justify-start gap-5'>
                        <Link to='/about'>
                            <button className='px-5 py-2 font-sans text-white rounded-full bg-primary hover:bg-primary-d'>
                                CONTACT
                            </button>
                        </Link>
                        <Link to='/contact' className='flex items-center gap-2'>
                            <p className='font-sans text-primary hover:underline'>
                                Know More
                            </p>
                            <i className="bi bi-arrow-right-circle text-primary"></i>
                        </Link>
                    </div>
                </div>

                {/* <div className="rounded-xl w-1/2 h-64 mx-auto bg-gradient-to-r p-[4px] from-[#6EE7B7] via-[#3B82F6] to-[#9333EA]"> */}
                {/* <div className="flex flex-col justify-between h-full p-4 bg-green-100 rounded-lg"> */}

                <Carousel
                    className='w-full rounded-lg md:w-1/2'
                    height={356}
                    autoPlay={true}
                    animationSpeed={1000}
                    interval={3000}
                    indicators={true}
                    animation="fade"
                    cycleNavigation={true}
                    swipe={true}
                    stopAutoPlayOnHover={true}
                    navButtonsAlwaysVisible={true}
                    indicatorsAlwayVisible={true}
                    indicatorIconButtonProps={{
                        style: {
                            opacity: 0
                        }
                    }}
                    fullHeightHover={true}
                >
                    {items.map((image, index) => (
                        <>
                            <img
                                key={index}
                                src={image.imageUrl}
                                alt={image.caption}
                                className="object-cover w-full h-full"
                            />
                            <p className="font-sans text-sm text-center text-white rounded-b-lg bg-primary">
                                {image.caption}
                            </p>
                        </>

                    ))}
                </Carousel>

                {/* </div> */}
                {/* </div> */}

                {/* <div className='flex w-1/2'>
                    <div className='w-1/2'>
                        <img className='w-auto h-full' src={require('../../assets/holding.png')} alt="hero" />
                    </div>

                    <div className='flex flex-col justify-center w-1/3 py-5 pl-20'>
                        <h3>
                            “At its core, banking is not simply about profit, but about personal relationships.” <br />
                            <span className='italic text-black text-opacity-60'>
                                ― Felix Rohatyn
                            </span>
                        </h3> */}
                {/* <figure className="rainbow bands"></figure> */}
                {/* </div>
                </div> */}
            </section>
        </>
    )
}

export default Hero