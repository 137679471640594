import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'flowbite';


const root = ReactDOM.createRoot(document.getElementById('linky'));
root.render(
  // <React.StrictMode>
  // <div className='selection:bg-primary selection:text-white overflow-x-hidden'>
  <App />
  // </div>
  // </React.StrictMode>
);
