import { Button } from '@mui/material'
import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import Pagecontainer from './Pagecontainer'

const Emicalc = () => {
    useDocumentTitle('EMI Calculator - Aabaddha SACCOS Ltd.')

    const [principal, setPrincipal] = React.useState(0)
    const [interest, setInterest] = React.useState(0)
    const [tenure, setTenure] = React.useState(0)

    const [error, setError] = React.useState('')

    const [emi, setEmi] = React.useState(0)

    const handlePrincipal = (e) => {
        setPrincipal(e.target.value)
    }

    const handleInterest = (e) => {
        setInterest(e.target.value)
    }

    const handleTenure = (e) => {
        setTenure(e.target.value)
    }

    const calculateEmi = () => {
        if (principal === '' || interest === '' || tenure === '' || principal === 0 || interest === 0 || tenure === 0) {
            setError('Please fill all the fields!')
        } else {
            setError('')
            const interestPerMonth = interest / 1200
            const emi = principal * interestPerMonth * Math.pow(1 + interestPerMonth, tenure) / (Math.pow(1 + interestPerMonth, tenure) - 1)
            setEmi(emi)
        }
    }

    const reset = () => {
        setPrincipal(0)
        setInterest(0)
        setTenure(0)
        setEmi(0)
        setError('')
    }


    return (
        <div>


            <Pagecontainer>

                <div className='flex flex-col items-center justify-center mt-10 mb-10'>
                    <h3 className='text-primary font-sans text-3xl font-bold uppercase'>
                        EMI Calculator
                    </h3>
                    <div className='w-[30px] bg-primary h-[4px] my-2' />
                </div>

                <div className='w-full flex flex-col items-center justify-center'>
                    <div className='md:w-1/2 flex flex-col items-start justify-start'>
                        <div className='w-full flex flex-col items-start justify-center gap-3 mb-5'>
                            <input type='number' className='w-full border-2 text-sans p-1 border-primary' placeholder='Principle' onChange={handlePrincipal} />
                            <input type='number' className='w-full border-2 text-sans p-1 border-primary' placeholder='Interest' onChange={handleInterest} />
                            <input type='number' className='w-full border-2 text-sans p-1 border-primary' placeholder='Tenure' onChange={handleTenure} />
                        </div>
                        {
                            error ? <div className='text-red-500 text-center text-sans text-sm mb-5'>{error}</div> : null
                        }
                        <div className='flex gap-3'>
                            <button onClick={calculateEmi} className='bg-primary px-2 text-white hover:bg-primary-d py-1'>
                                Calculate
                            </button>
                            <button onClick={reset} className='bg-red-500 px-2 text-red-600 hover:bg-red-600 bg-opacity-20 hover:bg-opacity-20 py-1'>
                                Reset
                            </button>
                        </div>
                    </div>

                    {emi > 0 &&
                        <div className='border md:w-1/2 mt-10'>
                            <table>
                                <table className="w-full text-sm text-left text-gray-500">
                                    <tbody>
                                        <tr className="bg-white">
                                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                                                Principle
                                            </th>
                                            <td className="py-4 px-6">
                                                {principal}
                                            </td>
                                        </tr>
                                        <tr className="bg-white">
                                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                Interest Rate
                                            </th>
                                            <td className="py-4 px-6">
                                                {interest}%
                                            </td>
                                        </tr>
                                        <tr className="bg-white">
                                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                Tenure
                                            </th>
                                            <td className="py-4 px-6">
                                                {tenure} months
                                            </td>
                                        </tr>
                                        <tr className="bg-white">
                                            <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                                                EMI
                                            </th>
                                            <td className="py-4 px-6 text-lg text-primary">
                                                NPR. {
                                                    emi.toFixed(2)
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </table>
                        </div>
                    }
                </div>


            </Pagecontainer >


        </div >
    )
}

export default Emicalc