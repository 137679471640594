import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import Breadcrumb from './components/Breadcrumb'
import Pagecontainer from './Pagecontainer'

const Gallery = () => {
    useDocumentTitle('Gallery - Aabaddha SACCOS Ltd.')

    return (
        <>
            <Breadcrumb root='Home' current='Gallery' />
            <Pagecontainer>
                Gallery
            </Pagecontainer>
        </>
    )
}

export default Gallery