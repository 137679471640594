import React from 'react'
import Pagecontainer from '../Pagecontainer'

const Breadcrumb = (props) => {
    return (
        <>
            <div className='flex items-center w-[100%] border-b h-7'>
                <Pagecontainer>
                    <div>
                        <p className='text-black text-opacity-60 text-sm font-sans'>
                            {props.root}
                            <i className="text-xs mx-2 bi bi-chevron-double-right"></i>
                            {props.current}
                        </p>
                    </div>
                </Pagecontainer>
            </div>
        </>
    )
}

export default Breadcrumb