import React from 'react'
import useDocumentTitle from '../functions/useDocumentTitle'
import notfound from '../assets/illustrations/404.svg'
import { Link } from 'react-router-dom'

const Notfound = () => {
    useDocumentTitle('404 - Aabaddha SACCOS Ltd.')

    return (
        <div className='flex flex-col items-center justify-center px-28 p-10 gap-10'>
            <img src={notfound} className='w-1/3' />
            <h1 className='text-3xl font-bold text-primary uppercase'>
                Error 404 - Page not found
            </h1>
            <Link to='/'>
                <button className='bg-primary rounded p-2 text-white font-sans hover:bg-primary-d'>
                    RETURN HOME
                </button>
            </Link>


        </div>
    )
}

export default Notfound