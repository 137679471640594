import React from 'react'
import Pagecontainer from '../Pagecontainer'
import Logo from '../../assets/logo-n.png'


const CooperativeProfile = () => {
    return (
        <>
            <Pagecontainer>
                <div className='flex justify-center flex-col items-center mb-10'>
                    <div className='flex flex-col items-center justify-center mb-10'>
                        <h3 className='text-primary text-center font-sans text-2xl font-bold uppercase'>
                            Cooperative Profile {' '}
                            <p className='font-vesper text-base font-medium text-red-700 mt-2'>
                                सहकारीको प्रोफाइल
                            </p>
                        </h3>
                        <div className='w-[30px] bg-primary h-[4px] my-2' />
                    </div>
                    <div className='md:w-[80%] flex border p-5 justify-between items-center bg-primary bg-opacity-5'>
                        <ul className='w-[20%] md:w-[40%] flex flex-col gap-3'>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    संस्थाको नाम
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    दर्ता न.
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    स्थापना
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    ठेगाना
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    टेलिफोन
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    इमेल
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    अध्यक्ष
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    प्रबन्धक
                                </span>
                            </li>


                        </ul>
                        <img src={Logo} alt='logo' className='hidden md:block md:w-[20%] opacity-30' />
                        <ul className='w-[75%] md:w-[40%] flex flex-col gap-3 text-right'>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    श्री आबद्ध बचत तथा ऋण सहकारी संस्था लि.
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    ३/१/७ (६४२) ०६९/७०
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    बि.स. २०६९-०४-०२
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    बिर्तामोड नगरपालिका - ५, झापा
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    ०२३-५४५३९५ / ०२३-५४६७०५
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-sans text-sm font-medium text-primary'>
                                    mail@aabaddha.coop.np
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    नन्दमान भट्टराई (नरेस)
                                </span>
                            </li>
                            <li className='text-gray-500 font-karma text-sm'>
                                <span className='font-vesper text-base font-medium text-primary'>
                                    पर्बत राज चिमरिया
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </Pagecontainer>
        </>
    )
}

export default CooperativeProfile