import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../Sidebar'

const AdminServices = () => {
    return (
        <>
            <Sidebar>
                <ul className='flex flex-col gap-5'>
                    <li className='rounded border p-5'>
                        <Link to='/admin/services/savings' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Savings</span>
                        </Link>
                    </li>
                    <li className='rounded border p-5'>
                        <Link to='/admin/services/loan' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Loan</span>
                        </Link>
                    </li>
                    <li className='rounded border p-5'>
                        <Link to='/admin/slider-images' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Slider Images</span>
                        </Link>
                    </li>
                    <li className='rounded border p-5'>
                        <Link to='/admin/findata' className='hover:underline text-xl'>
                            <i className="fas fa-money-bill-wave"></i>
                            <span>Fin Data</span>
                        </Link>
                    </li>
                </ul>
            </Sidebar>
        </>
    )
}

export default AdminServices