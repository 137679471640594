import { DataGrid } from '@mui/x-data-grid';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useEffect } from 'react'
import { db, storage } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import Sidebar from '../Sidebar'

const SavingAdmin = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [savings, setSavings] = React.useState([]);
    const [updated, setUpdated] = React.useState(false);

    useEffect(() => {
        try {
            const q = query(collection(db, 'savings'));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setSavings(data);
                console.log(data);
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);

    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "savings"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    const editData = async (e) => {
        const field = e.field;
        const value = e.value;
        try {
            await setDoc(doc(db, "savings", e.id), {
                [field]: value,
                updatedAt: new Date().toString('YYYY-MM-dd'),
            },
                { merge: true }
            );
            setUpdated(true);

        } catch (error) {
            console.log(error);
        }
    }



    const columns = [
        { field: 'title', headerName: 'Title', width: 200, align: 'left', editable: true, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'acType', headerName: 'Type', width: 100, align: 'left', editable: false, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'description', headerName: 'Description', type: 'string', width: 500, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6' },
        { field: 'interestrate', headerName: 'Interest Rate', type: 'number', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        { field: 'collectionMethod', headerName: 'Collection Method', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 100, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            }
        },
    ];


    return (
        <>

            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Savings</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add New' : 'Cancel'}
                    </button>
                </div>

                {
                    addMode === true ?
                        <Addind />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={savings}
                                    disableSelectionOnClick={true}
                                    onCellEditCommit={(e) => {
                                        editData(e);
                                    }}
                                />
                            </div>
                        </>
                }


            </Sidebar>
            <Globalsnack type="success" text="Updated successfully." open={updated} onClose={() => setUpdated(false)} />

        </>
    )
}

const Addind = () => {

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [interestrate, setInterestrate] = React.useState('');
    const [collectionMethod, setCollectionMethod] = React.useState('');
    const [acType, setAcType] = React.useState('');

    const [date, setDate] = React.useState('');
    const [image, setImage] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('');
    const [uploaded, setUploaded] = React.useState(false);

    const [percent, setPercent] = React.useState(0);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleInterestrate = (e) => {
        setInterestrate(e.target.value);
    }

    const handleCollectionMethod = (e) => {
        setCollectionMethod(e.target.value);
    }

    const handleAcType = (e) => {
        setAcType(e.target.value);
    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "savings"), {
            title: title,
            description: description,
            interestrate: interestrate,
            collectionMethod: collectionMethod,
            acType: acType.toLowerCase(),
        })
            .then((docRef) => {
                console.log("Write successful!");
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
        setUploaded(true);
        setTitle('');
        setDescription('');
        setInterestrate('');
        setCollectionMethod('');

    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        uploadDoc();
        // addImageToFirebase();
        // if (percent === 100) {
        //     await uploadDoc();
        // }
    }

    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={handleSubmit} className='flex flex-col gap-5'>
                    <input required value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />
                    <select required value={acType} onChange={handleAcType} className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name">
                        <option value="">Select Account Type</option>
                        <option value="sadharan">Sadharan</option>
                        <option value="kramik">Kramik</option>
                    </select>

                    <textarea value={description} onChange={handleDescription} rows={10} type="text" className="resize-none bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Description" />
                    <input value={interestrate} onChange={handleInterestrate} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Interest Rate" />
                    <input value={collectionMethod} onChange={handleCollectionMethod} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Collection Method" />

                    <button type="submit" className='bg-primary text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>Add</button>

                </form>
            </div>
            <Globalsnack type="success" text="Saving Type released successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}

export default SavingAdmin