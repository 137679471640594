import React, { useContext, useEffect, useState } from 'react'
import Pagecontainer from './Pagecontainer'
import Komform from './components/Komform'
import { FormDataContext } from '../contexts/FormDataContext'
import { BlobProvider, Document, Font, Image, PDFViewer, Page, StyleSheet, Text, View, pdf, renderToStream, usePDF } from "@react-pdf/renderer";
import karmaMid from '../assets/fonts/Karma-Medium.ttf'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { db, storage } from '../firebase';
import { addDoc, collection } from 'firebase/firestore';
import Globalsnack from '../Globalsnack';
import { Helmet } from 'react-helmet';

const styles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
        padding: 20,
    },
    section: {
        margin: 5,
        padding: 5,
    },
    topFlex: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    logo: {
        width: 80,
        height: 80,
    },
});

Font.register({ family: 'karma', src: karmaMid, fontStyle: 'normal', fontWeight: 'normal' });

const Updatekom = () => {
    // const blob = pdf(DocSample).toBlob();
    // console.log(blob);
    const { data, setData } = useContext(FormDataContext)

    const [uploadState, setUploadState] = useState(false)
    const [uploadComplete, setUploadComplete] = useState(false)

    useEffect(() => {
        setUploadState(data.uploadIt);
    }, [data])

    const uploadToServer = async (blob) => {
        data.uploading_state = true;
        setData(data);
        const storageRef = ref(storage, `/kom-submissions/${data.membership_no ? data.membership_no : '_blank'}.pdf`);
        const uploadTask = uploadBytesResumable(storageRef, blob);
        uploadTask.on('state_changed', (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
                case 'paused':
                    console.log('Upload is paused');
                    break;
                case 'running':
                    console.log('Upload is running');
                    break;
            }
        }
            , (error) => {
                console.log(error)
            }
            , () => {
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    console.log('File available at', downloadURL);
                    addDoc(collection(db, "kom-submissions"), {
                        membership_no: data.membership_no,
                        name: data.name_en,
                        pdf_url: downloadURL,
                        reference_number: data.reference_number,
                        timestamp: new Date().getTime(),
                    }).then(() => {
                        console.log("Document successfully written!");
                        data.uploading_state = false;
                        setData(data);
                        setUploadState(false);
                        setUploadComplete(true);
                    })
                }
                );
            }
        );
    }


    return (
        <>
            <Helmet>
                <title>Know Our Member (KOM) Form - Aabaddha SACCOS</title>
                <meta name="description" content="Know Our Member (KOM) Form" />
            </Helmet>
            <Pagecontainer>
                <div className="container">
                    <h2 className="text-2xl my-5 font-bold text-primary">
                        Know Our Member (KOM) Form
                        <p className='font-vesper text-base font-medium text-red-700 mt-2'>
                            सदस्य परिचय फारम
                        </p>
                    </h2>
                    {
                        !uploadComplete ?
                            <>
                                <p className="text-sm text-gray-500">
                                    फारम भर्ने विधि
                                </p>
                                <ul className='mb-5'>
                                    <li className="text-sm text-gray-500">
                                        रातो तारा <span className='text-red-500'>*</span> भएका सबै अंसहरुमा अनिवार्य रुपमा जानकारी भर्नुहोस्।
                                    </li>
                                    <li className="text-sm text-gray-500">
                                        आफ्नो Passport size photo अपलोड गर्नुहोस्।
                                    </li>
                                    <li className="text-sm text-gray-500">
                                        आफ्नो नागरिकताको परिचयपत्र अपलोड गर्नुहोस्।
                                    </li>
                                    <li className="text-sm text-gray-500">
                                        अन्त्यमा, Submit बटन थिच्नुहोस्।
                                    </li>
                                </ul>
                            </>
                            :

                            <div className="container mb-5">
                                <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                    <strong className="font-bold">तपाईंको फारम सफलतापूर्वक भरिएको छ!</strong>
                                    {' '}
                                    <span className="block sm:inline">
                                        तपाइको रेफरेन्स नम्बर <span className='font-bold'>{data.reference_number}</span> हो। आफ्नो रेफरेन्स नम्बर सुरक्षित राख्नुहोस् र आबद्ध सहकारीमा सम्पर्क गर्नुहोस्।
                                    </span>
                                    <button className="md:absolute md:top-0 md:bottom-0 md:right-0 md:px-4 py-3 underline md:no-underline" onClick={() => setUploadComplete(false)}>
                                        Submit Again
                                    </button>
                                </div>
                            </div>
                    }
                </div>


                {
                    uploadComplete ?
                        <div className="container">
                            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative" role="alert">
                                <strong className="font-bold">Success!</strong>
                                {' '}
                                <span className="block sm:inline">
                                    Your reference number is <span className='font-bold'>{data.reference_number}</span>. Please keep this number safe and contact Aabaddha SACCOS for further details.
                                </span>
                                <button className="md:absolute md:top-0 md:bottom-0 md:right-0 md:px-4 py-3 underline md:no-underline" onClick={() => setUploadComplete(false)}>
                                    Submit Again
                                </button>
                            </div>
                        </div>
                        :
                        <Komform />
                }


                <BlobProvider document={
                    <Document>
                        <Page Page size="A4" style={styles.page} >
                            {/* HEADER START */}
                            <View style={styles.topFlex}>
                                <Image src={require("../assets/logo.png")} style={styles.logo} />
                                <View style={
                                    {
                                        display: "flex",
                                        flexDirection: "column",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }
                                }>
                                    <Text style={{ fontSize: 20, fontWeight: "bold", fontFamily: "karma" }}>आबद्ध बचत तथा ऋण सहकारी संस्था लि. </Text>
                                    <Text style={{ fontSize: 12, fontWeight: "bold", fontFamily: "karma" }}>
                                        विर्तामोड नगरपालिका - ५, झापा
                                    </Text>

                                    <View style={{ width: "10%", height: 1, backgroundColor: "#00000030", marginTop: 5 }} />

                                    <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        सदस्य परिचय
                                    </Text>
                                    <Text style={{ fontSize: 14, fontWeight: "bold", textTransform: "uppercase" }}>
                                        Know Our Member (KOM)
                                    </Text>

                                    <View style={{ marginTop: 15 }}>
                                        <Text style={{ fontSize: 12, fontWeight: "bold", fontFamily: "karma", color: '#008037' }}>
                                            सदस्यता नं. {data.membership_no}, {data.service_center} सेवाकेन्द्र
                                        </Text>
                                    </View>
                                </View>
                                {
                                    data.user_image ?
                                        <Image src={data.user_image} style={styles.logo} />
                                        :
                                        <Image src={require("../assets/illustrations/avatar.png")} style={styles.logo} />
                                }
                            </View>
                            {/* HEADER END */}


                            {/* BODY START */}

                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030", marginTop: 15 }} />
                            {/* PERSONAL DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    व्यक्तिगत विवरण
                                </Text>
                                <View style={{ width: '1', height: 200, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        नाम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        जन्म मिति
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        नागरिकता
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ठेगाना (नागरिकता)
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        लिङ्ग
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        फोन नं.
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        व्यक्तिगत ईमेल
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.name_ne + " " + data.name_en}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.birth_bs + " " + data.birth_ad}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.citizenship_no + " " + data.citizenship_issue}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.auth_address === "" ? 'N/A' : data.auth_address}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.gender === 'm' ? 'पुरुष' : data.gender === 'f' ? 'महिला' : data.gender === 'o' ? 'अन्य' : 'N/A'}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.mobile_no + " (M) + " + data.phone_no}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.email_ad === "" ? 'N/A' : data.email_ad}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* FAMILY DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    पारिवारिक विवरण
                                </Text>
                                <View style={{ width: '1', height: 420, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        वुवाको नाम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        आमाको नाम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        हजुरबुबाको नाम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        वैवाहिक स्थिति
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        पति/पत्नीको नाम, थर
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        परिवारको किसिम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        १. हजुरबुबा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        २. हजुरआमा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ३. बुबा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ४. आमा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ५. पति/पत्नी
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ६. {data?.family_members ? data.family_members[5].relation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ७. {data?.family_members ? data.family_members[6].relation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ८. {data?.family_members ? data.family_members[7].relation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        ९. {data?.family_members ? data.family_members[8].relation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        १०. {data?.family_members ? data.family_members[9].relation : ''}
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.father_name === "" ? 'N/A' : data.father_name}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.mother_name === "" ? 'N/A' : data.mother_name}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.grandpa_name === "" ? 'N/A' : data.grandpa_name}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.marital === 'married' ? 'विवाहित' : data.marital === 'unmarried' ? 'अविवाहित' : data.marital === "widowed" ? 'एकल' : 'N/A'}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.marital === 'married' ? data.spouse_name : '-'}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.family_type === '' ? 'N/A' : data.family_type}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[0].name + ', ' + data.family_members[0].age + ', ' + data.family_members[0].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[1].name + ', ' + data.family_members[1].age + ', ' + data.family_members[1].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[2].name + ', ' + data.family_members[2].age + ', ' + data.family_members[2].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[3].name + ', ' + data.family_members[3].age + ', ' + data.family_members[3].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[4].name + ', ' + data.family_members[4].age + ', ' + data.family_members[4].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[5].name + ', ' + data.family_members[5].age + ', ' + data.family_members[5].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[6].name + ', ' + data.family_members[6].age + ', ' + data.family_members[6].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[7].name + ', ' + data.family_members[7].age + ', ' + data.family_members[7].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[8].name + ', ' + data.family_members[8].age + ', ' + data.family_members[8].occupation : ''}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data?.family_members ? data.family_members[9].name + ', ' + data.family_members[9].age + ', ' + data.family_members[9].occupation : ''}
                                    </Text>

                                </View>
                            </View>
                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />

                            <View break style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* OCCUPATION DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    पेसाको विवरण
                                </Text>
                                <View style={{ width: '1', height: 180, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        आफ्नो मुख्य पेसा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        स्थायी लेखा नं
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        पति वा पत्नीको मुख्य पेसा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        आफै वा परिवारको कुनै सदस्य उच्च पदीय राजनीतिक, प्रशासनिक वा सांगठित भूमिकामा रहे, नरहेको
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.occupation === '' ? '-' : data.occupation}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.pan_number === '' ? '-' : data.pan_number}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.spouse_occupation === '' ? '-' : data.spouse_occupation}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.fame_occ === "yes" ? 'रहेको, ' + data.fame_occ_name + ', ' + data.fame_occ_rel + ', ' + data.fame_occ_role : 'नरहेको'}
                                    </Text>

                                </View>
                            </View>


                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* ADDRESS DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    रहनसहनको विवरण
                                </Text>
                                <View style={{ width: '1', height: 150, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        स्थायी ठेगाना
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        पत्राचार ठेगाना
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        कार्यक्षेत्रको बसोबास
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        कार्यक्षेत्रमा बिताउने अवधि
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        राहदानी नम्बर
                                    </Text>
                                </View>
                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.per_address_province === '' ? '-' : `${data.per_address_province}, ${data.per_address_district}, ${data.per_address_gov}, ${data.per_address_ward}, ${data.per_address_tole}`}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.temp_address_province === '' ? 'स्थायी अनुसार छ' : `${data.temp_address_province}, ${data.temp_address_district}, ${data.temp_address_gov}, ${data.temp_address_ward}, ${data.temp_address_tole}`}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.work_addr === '' ? '-' : data.work_addr}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.spending_frame === "" ? '-' : data.spending_frame}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.passport_no === "" ? '-' : data.passport_no}
                                    </Text>
                                </View>
                            </View>



                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* COOP DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    सहकारी सदस्यता
                                </Text>
                                <View style={{ width: '1', height: 150, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        सदस्य बन्नुको उद्देस्य
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        अन्य सहकारीको सदस्य
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.membership_intent === '' ? '-' : data.membership_intent}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.other_coop === '' ? '-' : data.other_coop}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        १. {data?.other_coop_det ? data.other_coop_det[0][1] + ', ' + data.other_coop_det[0][2] : '-'}
                                        {'\n'}
                                        २. {data?.other_coop_det ? data.other_coop_det[1][1] + ', ' + data.other_coop_det[1][2] : '-'}
                                        {'\n'}
                                        ३. {data?.other_coop_det ? data.other_coop_det[2][1] + ', ' + data.other_coop_det[2][2] : '-'}
                                        {'\n'}
                                        ४. {data?.other_coop_det ? data.other_coop_det[3][1] + ', ' + data.other_coop_det[3][2] : '-'}
                                        {'\n'}
                                        ५. {data?.other_coop_det ? data.other_coop_det[4][1] + ', ' + data.other_coop_det[4][2] : '-'}
                                    </Text>
                                </View>
                            </View>



                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* INCOME DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    आयस्रोतको विवरण
                                </Text>
                                <View style={{ width: '1', height: 60, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        वार्षिक पारिवारिक
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        स्रोत (४ लाख +)
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.income_tot === '' ? '-' : data.income_tot}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.income_tot_det === '' ? '-' : data.income_tot_det}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* TXN DETAILS */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    वित्तीय कारोबारको विवरण
                                </Text>
                                <View style={{ width: '1', height: 115, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        प्रारम्भिक/हालको जम्मा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        अनुमानित राअनधरन कलम
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        अनुमानित ऋण
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        थप विवरण
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.kramik_init === '' ? 'N/A' : 'क्रमिक - ' + data.kramik_init}
                                        {', '}
                                        {data.aabadhik_init === '' ? 'N/A' : 'आवधिक - ' + data.aabadhik_init}
                                        {', '}
                                        {data.aabaddha_init === '' ? 'N/A' : 'आबद्ध - ' + data.aabaddha_init}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        Txn Count - {data.transaction_ct === '' ? 'N/A' : data.transaction_ct}
                                        {', '}
                                        Dep. Amount - {data.deposit_intent === '' ? 'N/A' : data.deposit_intent}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.loan_intent === '' ? '-' : data.loan_intent}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.other_det === '' ? '-' : data.other_det}
                                    </Text>
                                </View>
                            </View>


                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* SELF DEC */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    स्व-घोसणा
                                </Text>
                                <View style={{ width: '1', height: 60, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        स्व-घोसणा
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        नाम
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        छ
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.name_en === '' ? '-' : data.name_en}
                                    </Text>
                                </View>
                            </View>


                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />
                            {/* RECCOMENDATION */}
                            <View style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    सिफारिस
                                </Text>
                                <View style={{ width: '1', height: 60, backgroundColor: "#00000030", marginLeft: 30 }} />
                                <View style={{ marginLeft: 30, width: 100 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        सिफारिसकर्ता सदस्य
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        बजार प्रतिनिधि
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 30 }}>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.recc_member_name === '' ? '-' : data.recc_member_name}
                                        {', '}
                                        {data.recc_membership_id === '' ? '-' : data.recc_membership_id}
                                    </Text>
                                    <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                        {data.recc_name === '' ? '-' : data.recc_name}
                                    </Text>
                                </View>
                            </View>
                            <View style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />

                            {/* DOCS */}
                            <View break style={{ width: "100%", height: 1, backgroundColor: "#00000030" }} />

                            <View style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "start",
                            }}>
                                <Text style={{ fontSize: 14, fontWeight: "bold", fontFamily: "karma", marginTop: 10, width: 100 }}>
                                    संलग्न कागजात
                                </Text>

                                <View style={{ display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", marginTop: 10 }}>

                                    {
                                        data.citizenship_image_front && data.citizenship_image_front !== '' ?
                                            <Image src={data.citizenship_image_front} style={{ width: 300, height: 'auto', marginRight: 10 }} />
                                            :
                                            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                                Front - N/A
                                            </Text>
                                    }

                                    {
                                        data.citizenship_image_back && data.citizenship_image_back !== '' ?
                                            <Image src={data.citizenship_image_back} style={{ width: 300, height: 'auto', marginRight: 10 }} />
                                            :
                                            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                                Back - N/A
                                            </Text>
                                    }

                                    {
                                        data.other_doc_image && data.other_doc_image !== '' ?
                                            <Image src={data.other_doc_image} style={{ width: 300, height: 'auto', marginRight: 10 }} />
                                            :
                                            <Text style={{ fontSize: 10, fontWeight: "bold", fontFamily: "karma", marginTop: 10 }}>
                                                Other - N/A
                                            </Text>
                                    }

                                </View>

                            </View>

                        </Page >
                    </Document>
                }>
                    {
                        ({ blob, url, loading, error }) => {
                            if (loading) {
                                return (
                                    <div className='flex justify-center items-center w-full'>
                                        <p className='text-gray-500 mt-5'>
                                            Loading...
                                        </p>
                                    </div>
                                )
                            }
                            if (error) {
                                return (
                                    <div className='flex justify-center items-center w-full'>
                                        <p className='text-red-500 mt-5'>
                                            Something went wrong.
                                        </p>
                                    </div>
                                )
                            }
                            if (url && uploadState) {
                                uploadToServer(blob)
                            }
                        }
                    }
                </BlobProvider>
            </Pagecontainer>
        </>
    )
}

export default Updatekom