import React, { useEffect } from 'react'
import Sidebar from '../Sidebar'
import { DataGrid } from '@mui/x-data-grid';
import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, setDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import Globalsnack from '../../Globalsnack';
import { getDownloadURL, ref, uploadBytesResumable, uploadString } from 'firebase/storage';
import { NepaliDatePicker } from 'nepali-datepicker-reactjs';
import "nepali-datepicker-reactjs/dist/index.css"

const Addnotices = () => {
    const [addMode, setAddMode] = React.useState(false);
    const [notices, setNotices] = React.useState([]);
    const [updated, setUpdated] = React.useState(false);

    const changeAddMode = () => {
        setAddMode(false);
    }

    useEffect(() => {
        try {
            const q = query(collection(db, "notices"), orderBy("timestamp", "desc"));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                var data = [];
                var elements = {};

                querySnapshot.forEach((doc) => {
                    elements = {
                        id: doc.id,
                        ...doc.data()
                    }
                    data.push(elements);
                });
                setNotices(data);
            }
                , [])
        } catch (e) {
            // console.log(e)
        }
    }, []);


    const deleteDocument = async (id) => {
        try {
            const docRef = doc(collection(db, "notices"), id);
            await deleteDoc(docRef);
        } catch (e) {
        }
    }

    const editData = async (e) => {
        const field = e.field;
        const value = e.value;
        try {
            await setDoc(doc(db, "notices", e.id), {
                [field]: value,
                updatedAt: new Date().toString('YYYY-MM-dd'),
            },
                { merge: true }
            );
            setUpdated(true);

        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        { field: 'title', headerName: 'Title', width: 500, align: 'left', editable: true, headerClassName: 'dark:text-white-rgba-6' },
        // { field: 'description', headerName: 'Description', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        { field: 'date', headerName: 'Date', type: 'string', width: 100, align: 'left', headerAlign: 'left', editable: true, sortable: true, headerClassName: 'dark:text-white-rgba-6', flex: 1 },
        {
            field: 'image', headerName: 'Image', type: 'string', width: 200, align: 'left', headerAlign: 'center', align: 'center', editable: false, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <a href={params.value} target="_blank" className='underline text-blue-500'>
                        View
                    </a>
                )
            }
        },
        {
            field: 'actions', headerName: 'Actions', type: 'string', width: 200, align: 'left', headerAlign: 'left', editable: true, sortable: false, headerClassName: 'dark:text-white-rgba-6', flex: 1,
            renderCell: (params) => {
                return (
                    <div className='flex gap-1'>
                        <button
                            onClick={() => {
                                deleteDocument(params.id)
                            }
                            }
                            className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded-full'>
                            Delete
                        </button>
                    </div>
                )
            },
        },
    ];

    return (
        <>

            <Sidebar>
                <div className='mb-5 flex items-center gap-5'>
                    <h3 className='text-2xl font-bold'>Notices</h3>
                    <button
                        onClick={() => setAddMode(!addMode)}
                        className={`${addMode === false ? 'bg-primary' : 'bg-red-600'} text-white font-bold py-1 px-4 rounded-full ${addMode === false ? 'hover:bg-primary-dark' : 'hover:bg-red-700'}`}>
                        {addMode === false ? 'Add' : 'Cancel'}
                    </button>
                </div>
                <p className='mb-1 text-sm font-sans'>
                    Title and date of the notice can be edited. Double click on the cell to edit.
                </p>

                {
                    addMode === true ?
                        <Addind
                            changeAddMode={changeAddMode}
                        />
                        :
                        <>
                            <div className='h-[500px]'>
                                <DataGrid
                                    columns={columns}
                                    rows={notices}
                                    disableSelectionOnClick={true}
                                    onCellEditCommit={(e) => {
                                        editData(e);
                                    }}
                                />
                            </div>
                        </>
                }

                <Globalsnack type="success" text="Updated successfully." open={updated} onClose={() => setUpdated(false)} />
            </Sidebar>

        </>
    )
}

const Addind = (props) => {

    const [title, setTitle] = React.useState('');
    const [description, setDescription] = React.useState('');
    const [date, setDate] = React.useState('');
    const [image, setImage] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('');
    const [uploaded, setUploaded] = React.useState(false);
    const [imgUploaded, setImgUploaded] = React.useState(false);
    const [uploading, setUploading] = React.useState(false);

    const [percent, setPercent] = React.useState(0);

    const handleTitle = (e) => {
        setTitle(e.target.value);
    }

    const handleDescription = (e) => {
        setDescription(e.target.value);
    }

    const handleImage = (e) => {
        setImage(e.target.files[0]);
    }

    const addImageToFirebase = () => {
        if (!image) {
            alert("Please choose an image first!")
        }
        const storageRef = ref(storage, `/notices/${image.name}`)
        const uploadTask = uploadBytesResumable(storageRef, image);
        uploadTask.on(
            "state_changed",
            (snapshot) => {
                const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                );
                setPercent(percent);
            },
            (err) => console.log(err),
            () => {
                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                    setImageUrl(url);
                    setImgUploaded(true);
                });
            }
        );
    }

    const uploadDoc = async () => {
        await addDoc(collection(db, "notices"), {
            title: title,
            content: description,
            date: date,
            timestamp: new Date().getTime(),
            image: imageUrl,
        })
            .then((docRef) => {
                setUploaded(true);
                setUploading(false);
                setTitle('');
                setDescription('');
                setDate('');
                setImageUrl('');
                setPercent(0);
                setImage('');
                props.changeAddMode();
            }
            ).catch((error) => {
                console.error("Error adding document: ", error);
            }
            )
    }

    const handleSubmit = async (e) => {
        setUploading(true);
        e.preventDefault();
        addImageToFirebase();
    }

    useEffect(() => {
        if (imgUploaded === true) {
            uploadDoc();
        }
    }, [imgUploaded])


    return (
        <>
            <div className='border rounded w-full p-5'>
                <form onSubmit={handleSubmit} className='flex flex-col justify-center items-center gap-5'>
                    <input required value={title} onChange={handleTitle} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Title" />
                    <textarea value={description} onChange={handleDescription} rows={10} type="text" className="resize-none bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Description" />
                    {/* <input required value={date} onChange={handleDate} type="text" className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-first-name" placeholder="Date" /> */}
                    <div className='w-full flex flex-col gap-5 items-start'>
                        <label htmlFor="file" className='flex items-center'>
                            <p className='text-gray-600'>
                                Choose Release Date
                            </p>
                        </label>
                        <NepaliDatePicker inputClassName="form-control"
                            value={date}
                            onChange={(dated) => setDate(dated)}
                            options={{
                                calenderLocale: "ne",
                                valueLocale: "en",
                                format: "YYYY-MM-DD",
                            }}
                            className={`border - 2 rounded p - 2 ${date ? 'border-primary' : 'border-gray-200'}`}
                        />
                    </div>
                    <div className='w-full flex flex-col gap-5 items-start'>
                        <input type="file" accept="image/*" onChange={handleImage} />
                    </div>
                    {/* {
                        percent < 100 &&
                        // <div className="flex justify-center">
                        <div className="w-full flex items-center gap-5">
                            <progress className="bg-gray-200" value={percent} max="100" />
                            {
                                percent === 100 &&
                                <p className="text-center text-primary font-sans">Upload success!</p>
                            }
                        </div>

                    } */}
                    <button type="submit" className='bg-primary w-1/5 text-white font-bold py-1 px-4 rounded-full hover:bg-primary-dark'>
                        {uploading ? 'Uploading...' : 'Upload'}
                    </button>

                </form>
            </div>
            <Globalsnack type="success" text="Notice released successfully." open={uploaded} onClose={() => setUploaded(false)} />
        </>
    )
}

export default Addnotices