import { collection, limit, onSnapshot, orderBy, query } from 'firebase/firestore'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { db } from '../../firebase'
import Pagecontainer from '../Pagecontainer'
import { useMobileView } from '../../contexts/MobileViewContext'

const Footer = () => {
    const getYear = () => {
        const date = new Date()
        return date.getFullYear()
    }

    const { mobileView } = useMobileView()

    return (
        <>
            <footer className='bottom-0 left-0 mt-10 bg-primary'>
                <div className='border-t'>
                    <Pagecontainer>
                        <div className='flex flex-col items-center justify-start gap-20 md:flex-row md:items-start'>
                            <div className='md:w-[27.5%] flex flex-col gap-5'>
                                <h1 className='font-sans text-xl text-center text-white md:text-left'>
                                    Aabaddha SACCOS Ltd.<br />
                                    <span className='text-base font-vesper'>
                                        आबद्ध बचत तथा ऋण सहकारी संस्था लि.
                                    </span>
                                </h1>
                                {
                                    !mobileView &&
                                    <Divider />
                                }
                                <div className='flex gap-4'>
                                    <i className="text-white bi bi-geo-alt" />
                                    <p className='font-sans text-xs text-white'>
                                        Near Newton's Academy
                                        <br />
                                        Birtamode 05 - Jhapa 57204
                                        <br />
                                        Province 1, Nepal
                                    </p>
                                </div>

                                <div className='flex gap-4'>
                                    <i className="text-white bi bi-telephone" />
                                    <ul className='font-sans text-xs text-white'>
                                        <li className='hover:underline'>
                                            <a href='tel:023-535395'>
                                                023-535395
                                            </a>
                                        </li>
                                        <li className='hover:underline'>
                                            <a href='tel:023-536705'>
                                                023-536705
                                            </a>
                                        </li>
                                        <li>
                                            <a className='hover:underline' href='tel:+977 023-465082'>
                                                023-465082
                                            </a>
                                            {' '}
                                            (Arjundhara Branch)
                                        </li>
                                    </ul>
                                </div>

                                <div className='flex gap-4'>
                                    <i className="text-white bi bi-envelope" />
                                    <ul className='font-sans text-xs text-white'>
                                        <li className='hover:underline'>
                                            <a href='mailto:mail@aabaddha.coop.np'>
                                                mail@aabaddha.coop.np
                                            </a>
                                        </li>
                                        <li className='hover:underline'>
                                            <a href='mailto:info@aabaddha.coop.np'>
                                                info@aabaddha.coop.np
                                            </a>
                                        </li>
                                    </ul>
                                </div>

                            </div>

                            {!mobileView &&
                                <div className='md:w-[15%]'>
                                    <div className='flex flex-col gap-5'>
                                        <h1 className='font-sans text-xl text-white'>
                                            Quick Links<br />
                                            <span className='text-base font-vesper'>
                                                शीघ्रगामी लिंकहरु
                                            </span>
                                        </h1>
                                        <Divider />

                                        <ul className='flex flex-col gap-2 font-sans text-sm uppercase'>
                                            <li>
                                                <Link to='/' className='text-white hover:underline'>
                                                    Home
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/about' className='text-white hover:underline'>
                                                    About
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/services' className='text-white hover:underline'>
                                                    Services
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/notices' className='text-white hover:underline'>
                                                    Notices
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to='/contact' className='text-white hover:underline'>
                                                    Contact
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            }

                            {!mobileView &&

                                <div className='md:w-[15%]'>
                                    <div className='flex flex-col gap-5'>
                                        <h1 className='font-sans text-xl text-white'>
                                            Useful Links<br />
                                            <span className='text-base font-vesper'>
                                                उपयोगी लिंकहरु
                                            </span>
                                        </h1>
                                        <Divider />

                                        <ul className='flex flex-col gap-2 font-sans text-sm'>
                                            <li>
                                                <a target="_none" href='http://www.deoc.gov.np/index.php' className='text-white hover:underline'>
                                                    सहकारी विभाग
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='https://www.nrb.org.np/' className='text-white hover:underline'>
                                                    नेपाल राष्ट्र बैंक लि.
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='http://www.mof.gov.np/' className='text-white hover:underline'>
                                                    अर्थ मन्त्रालय
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='http://www.ncdb.org.np/' className='text-white hover:underline'>
                                                    राष्ट्रिय सहकारी विकास बाेर्ड
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='http://www.ncfnepal.com.np/' className='text-white hover:underline'>
                                                    राष्ट्रिय सहकारी महासंघ लि.
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='https://easynepalityping.com/' className='text-white hover:underline'>
                                                    Nepali Unicode Typing
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='https://pcrcto.p1.gov.np/' className='text-white hover:underline'>
                                                    प्रदेश सहकारी रजिष्टार तथा प्रशिक्षण कार्यालय
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='https://ncbl.coop/' className='text-white hover:underline'>
                                                    राष्ट्रिय सहकारी बैंक
                                                </a>
                                            </li>
                                            <li>
                                                <a target="_none" href='https://www.nefscun.org.np/' className='text-white hover:underline'>
                                                    नेफ्स्कुन
                                                </a>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            }

                            {!mobileView &&

                                <div className='w-[15%]'>
                                    <RecentNotices />
                                </div>
                            }
                            {!mobileView &&

                                <div className='md:w-[27.5%]'>
                                    <div className='flex flex-col gap-5'>
                                        <h1 className='font-sans text-xl text-white'>
                                            In Short<br />
                                            <span className='text-base font-vesper'>
                                                छोटकरिमा
                                            </span>
                                        </h1>
                                        <Divider />
                                        <p className='font-sans text-sm text-justify text-white'>
                                            संस्थाको सेवा सुविधाका साथै आर्थिक तथा अन्य गतिविधिहरुको सूचना तथा जानकारीहरु सदस्यमाझ प्रविधिको प्रयोगमार्फत छिटो, सरल र सहजरुपमा उपलब्ध गराउने उद्धेश्यका साथ विकास गरिएको यस वेवसाईटमा यहाँहरुबाट रचनात्मक सल्लाह तथा सुझावहरु प्राप्त हुने अपेक्षा गर्दै संस्थागत विकासमा ऐक्यवद्धता प्रकट गरिदिनुहुन सम्पूर्ण सदस्य तथा शुभचिन्तकहरुमा हार्दिक अनुरोध गर्दछौं। धन्यवाद।
                                        </p>
                                    </div>
                                </div>
                            }

                        </div>
                    </Pagecontainer>
                </div>

                <div className='border-t border-white border-opacity-10 bg-primary-d'>
                    <Pagecontainer>
                        <div className='flex flex-col items-center justify-between gap-5 md:flex-row'>
                            <div className='md:w-[33.33%] flex justify-start'>
                                <p className='font-sans text-xs text-white'>
                                    &copy; {'2012 - ' + getYear()} Aabaddha SACCOS Ltd. All Rights Reserved.
                                </p>
                            </div>
                            {!mobileView &&
                                <ul className='md:w-[33.33%] flex items-center justify-center gap-10 uppercase'>
                                    <Link to='/news' className='text-white text-opacity-50 hover:underline'>
                                        <li className='font-sans text-xs'>
                                            News
                                        </li>
                                    </Link>
                                    <a target='_none' href='https://facebook.com/aabaddhasaccos' className='text-white text-opacity-50 hover:underline'>
                                        <li className='font-sans text-xs'>
                                            Facebook
                                        </li>
                                    </a>
                                    <a href='mailto:mail@abaddha.coop.np' className='text-white text-opacity-50 hover:underline'>
                                        <li className='font-sans text-xs'>
                                            Mail
                                        </li>
                                    </a>
                                    <a target='_blank' className='text-white text-opacity-50 hover:underline' href='https://goo.gl/maps/9Yc2F4sJk2ELxUow5'>
                                        <li className='font-sans text-xs'>
                                            Location
                                        </li>
                                    </a>
                                </ul>
                            }
                            <div className='md:w-[33.33%] flex items-center justify-end gap-1 font-sans text-xs text-white'>
                                <span>
                                    Developed &amp; Powered by {' '}
                                    <a target="_none" href='https://linkbinary.com/' className='text-white underline'>
                                        LinkBinary
                                    </a>
                                </span>
                            </div>
                        </div>
                    </Pagecontainer>
                </div>
            </footer >


        </>
    )
}

export const Divider = () => {
    return (
        <div className='flex flex-col gap-1'>
            <div className='w-[30px] bg-white h-[1px]' />
            <div className='w-[60px] bg-white h-[0.5px]' />
        </div>
    )
}

const RecentNotices = () => {
    const [notices, setNotices] = useState([]);
    const [date, setDate] = useState([]);
    const [notId, setNotId] = useState([]);

    useEffect(() => {
        try {
            const q = query(collection(db, "notices"), orderBy("timestamp", "desc"), limit(3));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                setNotices(querySnapshot.docs.map(doc => doc.data().title));
                setDate(querySnapshot.docs.map(doc => doc.data().date));
                setNotId(querySnapshot.docs.map(doc => doc.id));
            }
                , [])
        } catch (e) {
            console.log(e)
        }
    }, []);

    return (
        <>
            <div className='flex flex-col gap-5'>
                <h1 className='font-sans text-xl text-white'>
                    Recent Notices<br />
                    <span className='text-base font-vesper'>
                        ताजा सूचनाहरू
                    </span>
                </h1>
                <Divider />
                <ul className='flex flex-col gap-2 font-sans text-sm uppercase'>
                    {notices.map((notice, index) => {
                        return (
                            <li key={index}>
                                <Link to={`/notices/${notId[index]}`} className='text-white hover:underline'>
                                    {notice}
                                </Link>
                                <br />
                                <span className='text-xs text-white text-opacity-60'>
                                    {date[index]}
                                </span>
                            </li>
                        )
                    }
                    )}
                </ul>
            </div>
        </>
    );
}


export default Footer